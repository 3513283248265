import cx from 'classnames'

import { Checkbox, Role } from 'src/components'

import styles from './retouch-gallery-item.module.scss'

import { IPhoto } from 'src/interfaces'

interface RetouchGalleryItemProps extends IPhoto {
  className?: string
  selectable?: boolean
  value?: boolean
  onChange?: () => void
  onClick?: (raw_watermarked_url?: string) => void
}

const RetouchGalleryItem = (
  props: React.PropsWithChildren<RetouchGalleryItemProps>,
) => {
  const {
    className,
    raw_watermarked_url,
    raw_watermarked_thumbnail_url,
    thumbnail_url,
    selectable,
    value,
    onChange,
    onClick,
    children,
  } = props

  const handleClick = () => {
    if (onClick) {
      onClick(raw_watermarked_url)
    }
  }

  return (
    <div
      className={cx(styles.wrapper, className)}
      style={{
        backgroundImage: `url(${
          raw_watermarked_thumbnail_url || thumbnail_url
        })`,
      }}
    >
      <div
        className={cx(
          styles.inner,
          value && styles.checked,
          onClick && styles.clickable,
        )}
        onClick={handleClick}
      >
        <Role.Customer>
          {selectable ? (
            <Checkbox
              className={styles.checkbox}
              value={value}
              onChange={onChange}
            />
          ) : null}
        </Role.Customer>

        {/* <div className={styles.badgesWrapper}>{children}</div> */}
      </div>
    </div>
  )
}

export default RetouchGalleryItem
