import { useQuery } from 'react-query'

import { api } from 'src/api'

interface useGetInventoryGroupsParams {
  enabled: boolean
}

const useGetInventoryGroups = (
  params: useGetInventoryGroupsParams = { enabled: true },
) => {
  const { enabled } = params

  return useQuery(
    [`getInventoryGroups`],
    async () => {
      const result = await api.get(`/api/v1/inventory-groups/`)
      if (result?.status === 200) {
        return result.data
      }

      return []
    },
    {
      enabled,
    },
  )
}

export default useGetInventoryGroups
