import { useField, useFormikContext } from 'formik'
import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './input.module.scss'

import InputProps from './InputProps'

const Input = (props: InputProps) => {
  const {
    className,
    required,
    name,
    label,
    type = 'text',
    placeholder,
    comment,
    icon,
    disabled,
  } = props

  const [field, meta] = useField({ name })
  const { isSubmitting } = useFormikContext()

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  return (
    <div className={cx(styles.wrapper, className)}>
      {typeof label !== 'undefined' ? (
        label ? (
          <label>
            {label} {required ? <span>*</span> : null}
          </label>
        ) : (
          <label>&nbsp;</label>
        )
      ) : null}

      <div className={styles.inner}>
        {IconComponent ? (
          <span className={styles.icon}>
            <IconComponent />
          </span>
        ) : null}

        <input
          className={cx(
            meta.touched && meta.error && styles.error,
            typeof label === 'undefined' && styles.withoutLabel,
            IconComponent && styles.withIcon,
          )}
          type={type}
          placeholder={placeholder}
          disabled={disabled || isSubmitting}
          {...field}
        />
      </div>

      {meta.touched && meta.error && (
        <div className={styles.errorComment}>{meta.error}</div>
      )}

      {!meta.error && comment && (
        <div className={styles.comment}>{comment}</div>
      )}
    </div>
  )
}

export default Input
