import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Button } from 'src/components'

import styles from './download-button.module.scss'

import ButtonProps from '../ButtonProps'

interface DownloadButtonProps extends ButtonProps {
  link?: string | null
  fileName?: string
}

const DownloadButton = (props: DownloadButtonProps) => {
  const { className, link, fileName, ...buttonProps } = props

  const { t } = useTranslation()

  return (
    <Button
      {...buttonProps}
      className={cx(styles.button, className)}
      toOutside={link || undefined}
    >
      {t('common.downloadOrder')}
    </Button>
  )

  // const handleDownload = () => {
  //   if (link) {
  //     fileDownload(link, fileName || 'file')
  //   }
  // }

  // return (
  //   <Button {...buttonProps} onClick={handleDownload}>
  //     {t('common.downloadOrder')}
  //   </Button>
  // )
}

export default DownloadButton
