import { useField } from 'formik'

import { RetouchItem } from 'src/components'

import AddRetouchItem from './AddRetouchItem'

import styles from './retouch-block.module.scss'

import {
  IRetouch,
  IRetouchStatus,
  IPhoto,
  IShoot,
  IInventoryWithPhotos,
} from 'src/interfaces'

interface RetouchBlockProps {
  photos: IPhoto[]
  products: IInventoryWithPhotos[]
  currentShoot: IShoot
  freePhotos: IPhoto[]
}

const RetouchBlock = (props: RetouchBlockProps) => {
  const { photos, products, currentShoot, freePhotos } = props

  const [{ value: retouches }, meta, { setValue }] = useField('retouches')

  // const handleAddRetouch = () => {
  //   setValue([
  //     ...retouches,
  //     {
  //       type: 'extra',
  //       status: IRetouchStatus.DRAFT,
  //       images: [],
  //       framing: '1:1',
  //       align: 'CENTER',
  //       background_color: 'GRAY5',
  //     },
  //   ])
  // }

  return (
    <div className={styles.wrapper}>
      {retouches.map(
        (
          _: any,
          index: number, //TODO ts
        ) => (
          <RetouchItem
            key={index}
            index={index}
            photos={photos}
            products={products}
            freePhotos={freePhotos}
            currentPackage={currentShoot.package}
          />
        ),
      )}

      {/* <AddRetouchItem onAdd={handleAddRetouch} /> */}
    </div>
  )
}

export default RetouchBlock
