import { ICategory, IPackage, CreatePhotosetFieldPackage } from 'src/interfaces'

export const getPackageGroup = (
  groupId: number | null,
  categories: ICategory[],
): ICategory | null => {
  let result = null

  if (groupId === null) {
    return result
  }

  categories.forEach((category) => {
    category.sub_categories?.forEach((subcategory) => {
      result =
        subcategory.sub_categories?.find((group) => group.id === groupId) ||
        null
    })
  })

  return result
}

export const getCurrentOrderPackages = (
  value: CreatePhotosetFieldPackage[],
  packages: IPackage[] = [] as IPackage[],
) => {
  return packages.filter((p) =>
    value.find((cp: { id: number }) => cp.id === p.id),
  )
}

export const checkIsPackageComplex = (currentPackages: IPackage[]) =>
  currentPackages?.[0]?.group_package === true ?? false
