import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { Form, Input, SubmitButton } from 'src/components'

import { useRegister, useAddNewBitrixLead } from 'src/hooks'

import styles from './register-block.module.scss'

import { ReactComponent as Icon } from './assets/icon.svg'

import { AxiosError } from 'axios'

interface IRegisterFormValues {
  email: string
  phone?: string
}

interface RegisterBlockProps {
  onAuth?: (data: any) => void
  setCurrentBlock: (block: 'Auth' | 'Register' | 'Forgot') => void
}

const RegisterBlock = (props: RegisterBlockProps) => {
  const { onAuth, setCurrentBlock } = props

  const { t } = useTranslation()

  const registerMutation = useRegister()
  const bitrixLeadMutation = useAddNewBitrixLead()

  const registerFormik = useFormik<IRegisterFormValues>({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, { setFieldError }) => {
      const newValues = { ...values }
      delete newValues.phone

      await registerMutation
        .mutateAsync({
          ...newValues,
          username: values.email,
        })
        .then(({ data }) => {
          if (onAuth) {
            onAuth(data)
          }
        })
        .catch((error) => {
          if ((error as AxiosError).response?.data.password) {
            setFieldError('password', t('error.password.notBeCommonlyUsed'))
          }
        })

      if (values.phone) {
        try {
          await bitrixLeadMutation.mutateAsync({
            email: values.email,
            phone: values.phone,
          })
        } catch (error) {}
      }
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required(t('error.required') || '')
        .email(t('error.email') || ''),
    }),
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Icon />
      </div>

      <div className={styles.title}>{t('common.createAccount')}</div>

      <Form className={styles.form} providerValue={registerFormik}>
        <Input
          required
          className={styles.input}
          name="email"
          type="email"
          placeholder={t('placeholder.yourEmail')}
        />

        <Input
          className={styles.input}
          name="phone"
          type="tel"
          placeholder="+79991111111"
        />

        <div className={styles.description}>
          {t('common.registerAccepting')}
        </div>

        <SubmitButton className={styles.button}>
          {t('common.create')}
        </SubmitButton>
      </Form>

      <div className={styles.optionWithLink}>
        {t('common.alreadyHasAccount')}
        <div className={styles.link} onClick={() => setCurrentBlock('Auth')}>
          {t('common.comeIn')}
        </div>
      </div>
    </div>
  )
}

export default RegisterBlock
