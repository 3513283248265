import {
  useParams,
  useLocation,
  useNavigate,
  generatePath,
} from 'react-router-dom'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { BasicTemplate } from 'src/templates'

import { Form, OrderTitle } from 'src/components'

import HeaderBlock from './HeaderBlock'
import RetouchBlock from './RetouchBlock'
import ResultBlock from './ResultBlock'

import {
  useGetOrder,
  useGetPackages,
  useCreateRetouch,
  useGetInventories,
  useGetInventoryGroups,
  useGetPhotos,
} from 'src/hooks'
import { useNotifications } from 'src/notification'

import { getProductsWithPhotos } from 'src/helpers'

import { PATHS } from 'src/config'

import { IRetouch, IRetouchStatus, IPhoto } from 'src/interfaces'

const CreateOrderRetouchPage = () => {
  const { id: orderId = '' } = useParams()

  const navigate = useNavigate()

  const { t } = useTranslation()

  const location = useLocation()

  const initialData =
    (location.state as { initialData: IRetouch[] })?.initialData ??
    ([] as IRetouch[])

  const { data } = useGetOrder({ orderId })
  const order = data?.data ?? null

  const currentShoot = order?.shoots[0]

  const createRetouchMutation = useCreateRetouch()

  const packages = useGetPackages()
  const inventories = useGetInventories()
  const inventoryGroups = useGetInventoryGroups()
  const photos = useGetPhotos()

  const notification = useNotifications()

  const formik = useFormik<{ retouches: IRetouch[] }>({
    initialValues: {
      retouches: [...initialData],
    },
    onSubmit: async (values) => {
      await handleSubmit()
    }, //TODO add real submitting
  })

  const handleSubmit = async () => {
    const promises = formik.values.retouches
      .filter((retouche) => retouche.status === IRetouchStatus.DRAFT)
      .map((retouch) =>
        createRetouchMutation.mutateAsync({
          ...retouch,
          order_id: currentShoot.order_id,
        }),
      )

    await Promise.all(promises)

    notification?.show('success', t('notifications.orderSuccessfullyCreated'))

    navigate(generatePath(PATHS.SINGLE_ORDER, { id: orderId }))
  }

  // const currentPackages = getCurrentOrderPackages(
  //   currentShoot?.package ? [currentShoot?.package] : [],
  //   packages,
  // )

  //const isPackageComplex = checkIsPackageComplex(currentPackages)

  if (!currentShoot) {
    return null
  }

  const currentPhotos = (photos?.data?.data ?? []).filter(
    (photo: IPhoto) => photo.shoot === currentShoot.id,
  )

  const products = getProductsWithPhotos(
    inventories.data?.data ?? [],
    currentPhotos,
  )

  const freePhotos = currentPhotos.filter(
    (photo: IPhoto) => photo.inventory === null,
  )

  // const compositions = getCompositionsUsingCompositionIds(
  //   currentShoot.inventory_groups || [],
  //   inventoryGroups.data,
  //   inventories.data,
  // )

  const breadcrumbs = [
    {
      title: t('breadcrumb.home'),
      to: '/',
    },
    {
      title: <OrderTitle {...order} />,
      to: generatePath(PATHS.SINGLE_ORDER, { id: orderId }),
    },
    {
      title: t('order.creatingRetouchOrder'),
    },
  ]

  return (
    <BasicTemplate breadcrumbs={breadcrumbs}>
      <HeaderBlock />

      <Form providerValue={formik}>
        <RetouchBlock
          currentShoot={currentShoot}
          photos={photos?.data?.data ?? []}
          products={products}
          freePhotos={freePhotos}
        />

        <ResultBlock
          currentShoot={currentShoot}
          products={products}
          freePhotos={freePhotos}
        />
      </Form>
    </BasicTemplate>
  )
}

export default CreateOrderRetouchPage
