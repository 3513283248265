import { useFormikContext } from 'formik'

import { StorageImage } from 'src/components'

import CreateOrderPackage from './CreateOrderPackage'

import styles from './create-order-category.module.scss'

import { ICategory, IPackage } from 'src/interfaces'

interface CreateOrderCategoryProps extends ICategory {
  packages: IPackage[]
  setCurrentStep: (step: number) => void
}

const CreateOrderCategory = (props: CreateOrderCategoryProps) => {
  const { title, sub_categories = [], packages, setCurrentStep } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.subtitleL}>{title}</div>

      <div className={styles.inner}>
        {sub_categories.length === 1 ? (
          <div className={styles.packagesWrapper}>
            {packages
              .filter((p: IPackage) => p.category_id === sub_categories[0].id)
              .map((p: IPackage) => (
                <CreateOrderPackage
                  key={p.id}
                  {...p}
                  setCurrentStep={setCurrentStep}
                />
              ))}
          </div>
        ) : (
          <>
            {sub_categories.map((sub_category: ICategory) => (
              <div key={sub_category.id}>
                <div className={styles.subcategoryTitle}>
                  {sub_category.title}
                </div>
                <div className={styles.packagesWrapper}>
                  {packages
                    .filter((p: IPackage) => p.category_id === sub_category.id)
                    .map((p: IPackage) => (
                      <CreateOrderPackage
                        key={p.id}
                        {...p}
                        setCurrentStep={setCurrentStep}
                      />
                    ))}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default CreateOrderCategory
