import { Link } from 'react-router-dom'
import { useFormik, FormikProvider } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { AuthTemplate } from 'src/templates'

import { Input, SubmitButton } from 'src/components'

import { useSendResetPasswordLink } from 'src/hooks'
import { useNotifications } from 'src/notification'

import { PATHS } from 'src/config'

import styles from './forgot-page.module.scss'

//TODO refactor: implement using ForgotForm component
const ForgotPage = () => {
  const { t } = useTranslation()

  const resetLinkMutation = useSendResetPasswordLink()

  const notification = useNotifications()

  const forgotFormik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      await resetLinkMutation
        .mutateAsync({ login: values.email })
        .then(({ data }) => {
          notification?.show('success', t('forgot.success'))
        })
        .catch((err) => {
          console.log('CATCH ERROR')
          console.log(err)
        })
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required(t('error.required') || '')
        .email(t('error.email') || ''),
    }),
  })

  return (
    <>
      <AuthTemplate
        backgroundImage="sneakers.png"
        backgroundColor="#ededed"
        title={
          <>
            <div>{t('forgot.passwordRecovery')}</div>
            <div className={styles.subtitle}>{t('forgot.description')}</div>
          </>
        }
        providerValue={forgotFormik}
      >
        <Input
          className={styles.input}
          name="email"
          type="email"
          label={t('label.email')}
          placeholder="example@gmail.com"
        />

        <SubmitButton
          className={styles.button}
          loading={resetLinkMutation.isLoading}
        >
          {t('common.send')}
        </SubmitButton>

        <div className={styles.alreadyHave}>
          {t('common.alreadyHasAccount')}{' '}
          <Link to={PATHS.AUTH}>{t('common.comeIn')}</Link>
        </div>

        <Link className={styles.link} to={PATHS.REGISTER}>
          {t('common.registration')}
        </Link>
      </AuthTemplate>
    </>
  )
}

export default ForgotPage
