import { Modal } from 'src/components'

import { ChatMessageForm } from 'src/pages/ChatPage/components'

import styles from './chat-dev-modal.module.scss'

import { IModal, IChatMessage } from 'src/interfaces'

interface ChatDevModalProps extends IModal {
  onMessageSend: (message: IChatMessage) => void
}

const ChatDevModal = (props: ChatDevModalProps) => {
  const { onMessageSend, onClose } = props

  const devAuthor = {
    id: 'anna',
    name: 'Анна Радченко',
  }

  return (
    <Modal
      className={styles.wrapper}
      id="chatDev"
      title="dev"
      onClose={onClose}
    >
      <ChatMessageForm author={devAuthor} onMessageSend={onMessageSend} />
    </Modal>
  )
}

export default ChatDevModal
