import { useMutation } from 'react-query'
import { api } from 'src/api'

interface ILoginData {
  login: string
  password: string
}

const useLogin = () => {
  return useMutation(
    async (data: ILoginData) => api.post('/accounts/login/', data),
    {
      onSuccess: (result) => {
        if (result) {
        }
      },
    },
  )
}

export default useLogin
