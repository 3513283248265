import { useState, useEffect, useRef } from 'react'
import { useField } from 'formik'
import cx from 'classnames'

import { Icons } from 'src/components'

import SelectOption from './SelectOption'

import styles from './select.module.scss'

import { IOption } from 'src/interfaces'

interface SelectProps {
  value?: string
  name: string
  options: {
    label: string
    value: string
  }[]
  disabled?: boolean
  strictOpen?: boolean
  icon?: string
  innerLabel?: string
  placeholder?: string
  optionClassName?: string
  SelectedOptionComponent?: (props: IOption) => React.ReactElement
  OptionComponent?: (
    props: React.PropsWithChildren<{
      className?: string
      data: IOption
      onChange: () => void
    }>,
  ) => React.ReactElement
  OptionComponentChild?: (props: IOption) => React.ReactElement
  onChange?: (value: string) => void
}

const SelectWithStrictValue = (props: SelectProps) => {
  //TODO abstraction
  const {
    value,
    options,
    disabled,
    strictOpen,
    icon,
    innerLabel,
    placeholder,
    onChange,
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const selectRef = useRef(null)

  useEffect(() => {
    if (selectRef.current) {
      const clickEventHandler = (event: MouseEvent | TouchEvent) => {
        const specifiedElement = selectRef.current
        if (specifiedElement && event.target instanceof Node) {
          const isClickInside = (specifiedElement as HTMLDivElement).contains(
            event.target,
          )
          if (!isClickInside) {
            setIsOpen(false)
          }
        }
      }

      const keyDownEventHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setIsOpen(false)
        }
      }

      window.addEventListener('mousedown', clickEventHandler, false)
      window.addEventListener('touchstart', clickEventHandler, false)
      window.addEventListener('keydown', keyDownEventHandler, false)

      return () => {
        window.removeEventListener('mousedown', clickEventHandler, false)
        window.removeEventListener('touchstart', clickEventHandler, false)
        window.removeEventListener('keydown', keyDownEventHandler, false)
      }
    }
  }, [selectRef])

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen)
    }
  }

  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value)
    }
  }

  const currentOption = options?.find((o) => o.value === value)

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  const selectIsOpen = isOpen || strictOpen

  return (
    <div
      className={cx(
        styles.wrapper,
        selectIsOpen && styles.open,
        disabled && styles.disabled,
      )}
      ref={selectRef}
      onClick={handleToggle}
    >
      {IconComponent ? (
        <div className={styles.icon}>
          <IconComponent />
        </div>
      ) : null}

      <div>
        {innerLabel ? (
          <div className={styles.innerLabel}>{innerLabel}</div>
        ) : null}

        {currentOption ? (
          <div className={styles.currentOption}>{currentOption?.label}</div>
        ) : (
          <div className={styles.placeholder}>{placeholder || ''}</div>
        )}
      </div>

      <div className={cx(styles.arrow, selectIsOpen && styles.open)}>
        <Icons.Arrow />
      </div>

      {selectIsOpen ? (
        <div className={styles.options}>
          {options?.map((o) => (
            <div key={o.value} onClick={() => handleChange(o.value)}>
              {o.label}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

const Select = (props: SelectProps) => {
  const {
    name,
    options,
    disabled,
    strictOpen,
    icon,
    innerLabel,
    placeholder,
    optionClassName,
    SelectedOptionComponent,
    OptionComponent,
    OptionComponentChild,
    onChange,
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const selectRef = useRef(null)

  useEffect(() => {
    if (selectRef.current) {
      const clickEventHandler = (event: MouseEvent | TouchEvent) => {
        const specifiedElement = selectRef.current
        if (specifiedElement && event.target instanceof Node) {
          const isClickInside = (specifiedElement as HTMLDivElement).contains(
            event.target,
          )
          if (!isClickInside) {
            setIsOpen(false)
          }
        }
      }

      const keyDownEventHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setIsOpen(false)
        }
      }

      window.addEventListener('mousedown', clickEventHandler, false)
      window.addEventListener('touchstart', clickEventHandler, false)
      window.addEventListener('keydown', keyDownEventHandler, false)

      return () => {
        window.removeEventListener('mousedown', clickEventHandler, false)
        window.removeEventListener('touchstart', clickEventHandler, false)
        window.removeEventListener('keydown', keyDownEventHandler, false)
      }
    }
  }, [selectRef])

  const [field, meta, { setValue }] = useField(name)

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen)
    }
  }

  const handleChange = (value: string) => {
    setValue(value)

    if (onChange) {
      onChange(value)
    }
  }

  const currentOption = options?.find((o) => o.value === field.value)

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  const selectIsOpen = isOpen || strictOpen

  return (
    <div
      className={cx(
        styles.wrapper,
        meta.error && meta.touched && styles.error,
        selectIsOpen && styles.open,
        disabled && styles.disabled,
      )}
      ref={selectRef}
      onClick={handleToggle}
    >
      {IconComponent ? (
        <div className={styles.icon}>
          <IconComponent />
        </div>
      ) : null}

      <div>
        {innerLabel ? (
          <div className={styles.innerLabel}>{innerLabel}</div>
        ) : null}

        {currentOption ? (
          <div className={styles.currentOption}>
            {SelectedOptionComponent ? (
              <SelectedOptionComponent {...currentOption} />
            ) : (
              currentOption?.label
            )}
          </div>
        ) : (
          <div className={styles.placeholder}>{placeholder || ''}</div>
        )}
      </div>

      <div className={cx(styles.arrow, selectIsOpen && styles.open)}>
        <Icons.Arrow />
      </div>

      {selectIsOpen ? (
        <div className={styles.options}>
          {options?.map((o) => (
            <SelectOption
              key={o.value}
              className={optionClassName}
              data={o}
              OptionComponent={OptionComponent}
              OptionComponentChild={OptionComponentChild}
              onChange={handleChange}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

const SelectWrapper = (props: SelectProps) => {
  const { value } = props

  return typeof value !== 'undefined' ? (
    <SelectWithStrictValue {...props} />
  ) : (
    <Select {...props} />
  )
}

export default SelectWrapper
