import PhotoStepFreePhotos from './PhotoStepFreePhotos'
import PhotoStepProduct from './PhotoStepProduct'

import styles from './photo-step.module.scss'

import { IShoot, IPhoto, IInventoryWithPhotos } from 'src/interfaces'

interface PhotoStepProps {
  currentShoot: IShoot
  products: IInventoryWithPhotos[]
  freePhotos: IPhoto[]
}

const PhotoStep = (props: PhotoStepProps) => {
  const { currentShoot, products, freePhotos } = props

  return (
    <div className={styles.wrapper}>
      {freePhotos.length ? (
        <PhotoStepFreePhotos
          photos={freePhotos}
          currentPackage={currentShoot.package}
        />
      ) : null}

      {products.map((product) => (
        <PhotoStepProduct
          key={product.id}
          {...product}
          currentPackage={currentShoot.package}
        />
      ))}
    </div>
  )
}

export default PhotoStep
