import { useTranslation } from 'react-i18next'

import { Select } from 'src/components'

import RetouchFramingFilterSelectedOption from './RetouchFramingFilterSelectedOption'
import RetouchFramingFilterOption from './RetouchFramingFilterOption'

import { FRAMING_OPTIONS } from 'src/config'

interface RetouchFramingFilterProps {
  index: number
  onChange: (value: string) => void
}

const RetouchFramingFilter = (props: RetouchFramingFilterProps) => {
  const { index, onChange } = props

  const { t } = useTranslation()

  return (
    <Select
      name={`retouches[${index}].framing`}
      icon="Framing"
      options={FRAMING_OPTIONS}
      innerLabel={t('label.framing')}
      SelectedOptionComponent={RetouchFramingFilterSelectedOption}
      OptionComponent={RetouchFramingFilterOption}
      onChange={onChange}
    />
  )
}

export default RetouchFramingFilter
