import { useEffect } from 'react'

import styles from './main.module.scss'

import { renderPrivateRoutes, renderPublicRoutes } from 'src/routing'

import { useAppContext } from 'src/context'
// import { useNotifications } from 'src/notification'

interface MainProps {
  authorized: boolean
}

const Main = (props: MainProps) => {
  const { authorized } = props

  const { appVersion } = useAppContext()

  //const notification = useNotifications()

  return (
    <>
      {authorized ? <>{renderPrivateRoutes()}</> : <>{renderPublicRoutes()}</>}
    </>
  )
}

export default Main
