import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { AuthTemplate } from 'src/templates'

import { Input, SubmitButton, CoolModal } from 'src/components'

import { useAppContext } from 'src/context'

import { useRegister, useAddNewBitrixLead } from 'src/hooks'

import { PATHS } from 'src/config'

import styles from './register-page.module.scss'

import { AxiosError } from 'axios'

interface IRegisterFormValues {
  email: string
  phone?: string
}

const RegisterPage = () => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState<boolean>(false)

  const { setAuthorized } = useAppContext()

  const registerMutation = useRegister()
  const bitrixLeadMutation = useAddNewBitrixLead()

  const registerFormik = useFormik<IRegisterFormValues>({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, { setFieldError }) => {
      const newValues = { ...values }
      delete newValues.phone

      await registerMutation
        .mutateAsync({
          ...newValues,
          username: values.email,
        })
        .then(() => {
          setShowModal(true)
        })
        .catch((error) => {
          if ((error as AxiosError).response?.data.password) {
            setFieldError('password', t('error.password.notBeCommonlyUsed'))
          }
        })

      if (values.phone) {
        try {
          await bitrixLeadMutation.mutateAsync({
            email: values.email,
            phone: values.phone,
          })
        } catch (error) {}
      }
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required(t('error.required') || '')
        .email(t('error.email') || ''),
    }),
  })

  const handleCoolModalClose = () => {
    setShowModal(false)
    setAuthorized(true)
    window.location.href = '/'
  }

  return (
    <>
      <AuthTemplate
        backgroundImage="aerostats.png"
        backgroundColor="#c6e9e3"
        title={
          <>
            <div>{t('common.createAccount')}</div>
            <div className={styles.subtitle}>
              {t('register.itIsFastAndFree')}
            </div>
          </>
        }
        providerValue={registerFormik}
      >
        <Input
          required
          className={styles.input}
          name="email"
          type="email"
          label={t('label.email')}
          placeholder="example@gmail.com"
          comment={t('register.emailComment')}
        />

        <Input
          name="phone"
          type="tel"
          label={t('label.phone')}
          placeholder="+79991111111"
        />

        <div className={styles.terms}>{t('common.registerAccepting')}</div>

        <SubmitButton
          className={styles.button}
          loading={registerMutation.isLoading}
        >
          {t('common.createAccount')}
        </SubmitButton>

        <div className={styles.alreadyHave}>
          {t('common.alreadyHasAccount')}{' '}
          <Link to={PATHS.AUTH}>{t('common.comeIn')}</Link>
        </div>
      </AuthTemplate>

      {showModal ? (
        <CoolModal
          buttonLabel={t('common.comeIn')}
          onClose={handleCoolModalClose}
        />
      ) : null}
    </>
  )
}

export default RegisterPage
