import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Title, Icons } from 'src/components'

import styles from './basic-page-header.module.scss'

interface BasicPageHeaderProps {
  className?: string
  title?: string | JSX.Element | null
  subtitle?: string
  description?: string
  backUrl?: string
  backAction?: () => void
}

const BasicPageHeader = (
  props: React.PropsWithChildren<BasicPageHeaderProps>,
) => {
  const {
    className,
    title,
    subtitle,
    description,
    backUrl,
    backAction,
    children,
  } = props

  const { t } = useTranslation()

  return (
    <>
      {backUrl ? (
        <Link to={backUrl} className={styles.backLink}>
          <Icons.NormalArrow /> {t('common.back')}
        </Link>
      ) : null}

      {!backUrl && backAction ? (
        <div className={styles.backLink} onClick={backAction}>
          <Icons.NormalArrow /> {t('common.back')}
        </div>
      ) : null}

      <div className={cx(styles.wrapper, className)}>
        <div>
          {subtitle ? <Title type="subtitle">{subtitle}</Title> : null}

          {title ? <Title className={styles.title}>{title}</Title> : null}

          {description ? (
            <div className={styles.description}>{description}</div>
          ) : null}
        </div>
        {children}
      </div>
    </>
  )
}

export default BasicPageHeader
