import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { BasicBlock, Button, Textarea, SubmitButton } from 'src/components'

import SingleOrderReferences from './SingleOrderReferences'

import { isOrderStatusPassed } from 'src/helpers'

import styles from './single-order-data.module.scss'

import { IOrder, IOrderStatus } from 'src/interfaces'

interface SingleOrderDataProps {
  order: IOrder
}

const SingleOrderData = (props: SingleOrderDataProps) => {
  const { order } = props

  const { t, i18n } = useTranslation()

  const isConfirmed = isOrderStatusPassed(IOrderStatus.CONFIRMED, order.status)

  return (
    <BasicBlock className={styles.wrapper} padding={32}>
      <div className={styles.header}>
        <div>
          <div className={styles.title}>{t('common.shootingDate')}</div>
          <div className={styles.shootDate}>
            {order.planned_shooting_date
              ? dayjs(order.planned_shooting_date)
                  .locale(i18n.language)
                  .format('DD MMMM hh:mm')
              : '-'}
          </div>
        </div>

        {/* <div>
          <Button disabled>{t('order.loginToOnlineChat')}</Button>
        </div> */}
      </div>

      <div className={styles.comment}>
        <div className={styles.title}>{t('label.shootComment')}</div>
        <Textarea name="comment" disabled={isConfirmed} />
      </div>

      <SingleOrderReferences disabled={isConfirmed} />

      {!isConfirmed ? (
        <div className={styles.buttons}>
          <SubmitButton>{t('common.save')}</SubmitButton>
        </div>
      ) : null}
    </BasicBlock>
  )
}

export default SingleOrderData
