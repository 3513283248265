import { BasicBlock, Button } from 'src/components'

import styles from './tip.module.scss'

interface TipProps {
  title: string
  buttonText: string
  image?: string
  url?: string
  newWindow?: boolean
}

const Tip = (props: React.PropsWithChildren<TipProps>) => {
  const { title, buttonText, image, url, newWindow, children } = props

  return (
    <BasicBlock className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.titleL}>{title}</div>
        <div className={styles.content}>
          <div className={styles.contentInner}>{children}</div>
          <Button
            className={styles.button}
            to={!newWindow ? url : undefined}
            toOutside={newWindow ? url : undefined}
            template="secondary"
            size="small"
          >
            {buttonText}
          </Button>
        </div>
      </div>

      {image ? (
        <img className={styles.image} src={`/img/tips/${image}`} />
      ) : null}
    </BasicBlock>
  )
}

export default Tip
