import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AuthTemplate } from 'src/templates'

import { AuthForm, Input, SubmitButton, Button } from 'src/components'

import { PATHS } from 'src/config'

import styles from './auth-page.module.scss'

import heroImg from './assets/hero.png'

const AuthPage = () => {
  const { t } = useTranslation()

  return (
    <AuthTemplate
      backgroundImage="cameras.png"
      backgroundColor="#f8f9fb"
      title={t('auth.title')}
      FormComponent={AuthForm}
    >
      <Input
        className={styles.input}
        name="email"
        type="email"
        label={t('label.email')}
        placeholder="example@gmail.com"
      />

      <Input
        className={styles.input}
        name="password"
        type="password"
        label={t('label.password')}
      />

      <SubmitButton className={styles.button}>
        {t('auth.submitButton')}
      </SubmitButton>

      <Button
        className={styles.newAccountButton}
        template="ghost"
        to={PATHS.REGISTER}
      >
        {t('auth.newAccountLink')}
      </Button>

      <Link className={styles.link} to={PATHS.FORGOT}>
        {t('auth.forgotPasswordLink')}
      </Link>
    </AuthTemplate>
  )
}

export default AuthPage
