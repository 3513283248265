import { useRoleQuery } from 'src/hooks'

const useGetOrders = () => {
  return useRoleQuery({
    queryKeys: [`getOrders`],
    url: '/api/v1/orders/',
  })
}

export default useGetOrders
