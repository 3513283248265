import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './badge.module.scss'

interface BadgeProps {
  className?: string
  template?: string
  icon?: string
  uppercase?: boolean
}

const Badge = (props: React.PropsWithChildren<BadgeProps>) => {
  const { className, template = 'green', icon, uppercase, children } = props

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  return (
    <div
      className={cx(
        styles.wrapper,
        styles[template],
        uppercase && styles.uppercase,
        IconComponent && styles.withIcon,
        className,
      )}
    >
      {IconComponent ? (
        <div className={styles.icon}>
          <IconComponent />
        </div>
      ) : null}
      {children}
    </div>
  )
}

export default Badge
