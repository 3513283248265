import { IShoot, IUser } from 'src/interfaces'

export enum IOrderStatus {
  DRAFT = 'draft',
  SHIPMENT_PENDING = 'shipment_pending',
  NEEDS_CONFIRMATION = 'needs_confirmation',
  CONFIRMED = 'confirmed',
  SHOOT_IN_PROGRESS = 'shoot_in_progress',
  SHOOT_PROCESSING = 'shoot_processing',
  READY_FOR_RETOUCH = 'ready_for_retouch',
  RETOUCH_ORDER_CREATED = 'retouch_order_created',
  RETOUCH_IN_PROGRESS = 'retouch_in_progress',
  FINISHED = 'finished',
}

export interface IOrder {
  id: number
  title: string | null
  comment: string | null
  created_at: string
  category_id: number
  sku_quantity: number
  total: string
  status: IOrderStatus
  shoots: IShoot[]
  planned_shooting_date: string | null
  references?: string[] | null
  user_id: number
  user: IUser
}

export interface IOrderStatusStep {
  title: string
  icon?: string
  template: string
}

export interface IOrderStatusAction {
  title: string
  description: string
  buttons?: {
    id: string
    label?: string
  }[]
}
