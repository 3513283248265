import { useTranslation } from 'react-i18next'

import { Status } from 'src/components'

import { RETOUCH_STATUSES } from 'src/config'

import { IRetouchStatus } from 'src/interfaces'

interface OrderStatusProps {
  className?: string
  status?: IRetouchStatus
}

const RetouchStatus = (props: OrderStatusProps) => {
  const { status, ...rest } = props

  const { t } = useTranslation()

  const label =
    status && status in RETOUCH_STATUSES
      ? t(RETOUCH_STATUSES[status].title)
      : status

  const type = status === IRetouchStatus.DONE ? 'success' : 'progress'

  return (
    <Status {...rest} type={type}>
      {label}
    </Status>
  )
}

export default RetouchStatus
