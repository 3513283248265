import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Illustrations } from 'src/components'

import styles from './adding-block-product.module.scss'

import { IInventory } from 'src/interfaces'

interface AddingBlockProductProps extends IInventory {
  isAdded: boolean
  onAdd: (id: number) => void
}

const AddingBlockProduct = (props: AddingBlockProductProps) => {
  const { id, title, barcode, sku, isAdded, onAdd } = props

  const { t } = useTranslation()

  const handleAdd = (id: number) => {
    if (!isAdded) {
      onAdd(id)
    }
  }

  return (
    <div className={cx(styles.wrapper, isAdded && styles.added)}>
      <div>
        <Illustrations.Inventory />
      </div>

      <div className={styles.info}>
        <div className={styles.subtitleS}>{title}</div>
        <div className={styles.barcode}>{barcode}</div>
        <div className={styles.article}>{sku}</div>
      </div>

      <div className={styles.button} onClick={() => handleAdd(id)}>
        {t(`common.${!isAdded ? 'add' : 'added'}`)}
      </div>
    </div>
  )
}

export default AddingBlockProduct
