import { useField } from 'formik'
import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './checkbox.module.scss'

interface CheckboxProps {
  className?: string
  value: boolean
  disabled?: boolean
  onChange: () => void
}

const Checkbox = (props: CheckboxProps) => {
  const { className, value, disabled, onChange } = props

  const handleChange = (event: React.MouseEvent<HTMLInputElement>) => {
    if (!disabled) {
      event.stopPropagation()
      onChange()
    }
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        value && styles.checked,
        disabled && styles.disabled,
        className,
      )}
      onClick={handleChange}
    >
      {value ? <Icons.Check /> : null}
    </div>
  )
}

interface CheckboxWrapperProps {
  className?: string
  name?: string
  disabled?: boolean
  value?: boolean
  onChange?: (checked: boolean) => void
}

const CheckboxField = (props: CheckboxWrapperProps) => {
  const { className, name, disabled } = props

  const [{ value }, meta, { setValue }] = useField({ name: name || '' })

  return (
    <Checkbox
      className={className}
      value={value}
      disabled={disabled}
      onChange={() => setValue(!value)}
    />
  )
}

const CheckboxWithStrictValue = (props: CheckboxWrapperProps) => {
  const { className, value, disabled, onChange = () => false } = props

  return (
    <Checkbox
      className={className}
      value={Boolean(value)}
      disabled={disabled}
      onChange={() => onChange(!value)}
    />
  )
}

const CheckboxWrapper = (props: CheckboxWrapperProps) => {
  const { value } = props

  return typeof value !== 'undefined' ? (
    <CheckboxWithStrictValue {...props} />
  ) : (
    <CheckboxField {...props} />
  )
}

export default CheckboxWrapper
