import { useTranslation } from 'react-i18next'

import SingleOrderTabsHeaderItem from './SingleOrderTabsHeaderItem'

import styles from './single-order-tabs-header.module.scss'

interface SingleOrderTabsHeaderProps {
  showGallery: boolean
  currentStep: number
  onSelect: (step: number) => void
}

const SingleOrderTabsHeader = (props: SingleOrderTabsHeaderProps) => {
  const { showGallery, currentStep, onSelect } = props

  const { t } = useTranslation()

  const steps = (() => {
    let result = [
      {
        title: t('common.details'),
        icon: 'Gear',
      },
    ]

    if (showGallery) {
      result = [
        ...result,
        {
          title: t('common.results'),
          icon: 'Collection',
        },
        {
          title: t('common.retouch'),
          icon: 'BrushBig',
        },
      ]
    }

    return result
  })()

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        {steps.map((step, i) => (
          <SingleOrderTabsHeaderItem
            key={i}
            {...step}
            isActive={i === currentStep}
            onSelect={() => onSelect(i)}
          />
        ))}
      </div>

      <div className={styles.border} />
    </div>
  )
}

export default SingleOrderTabsHeader
