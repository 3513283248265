import { useField } from 'formik'
import { IOption } from 'src/interfaces'

const RetouchColorSelectedOption = (props: IOption) => {
  const { value, label, meta } = props

  const [{ value: customColor }] = useField(`${meta.fieldName}.custom_color`)

  return <>{value !== 'CUSTOM' ? label : customColor || label}</>
}

export default RetouchColorSelectedOption
