import { useQuery } from 'react-query'

import { useAppContext } from 'src/context'

import { api } from 'src/api'

import { UseQueryResult, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'

interface RoleQueryParams {
  queryKeys: string[]
  url: string
  requestType?: 'get' | 'post' | 'put' | 'delete'
  resultHandler?: (result: AxiosResponse) => any
  options?: { [key: string]: any }
}

const useRoleQuery = (
  params: RoleQueryParams,
): UseQueryResult<AxiosResponse> => {
  const {
    queryKeys,
    url,
    requestType = 'get',
    resultHandler,
    options = {},
  } = params

  const { currentUser } = useAppContext()

  const roledUrl = `${currentUser?.is_staff ? '/employee' : ''}${url}`

  return useQuery(
    queryKeys,
    async () => {
      const result = await api[requestType](roledUrl)

      if (resultHandler) {
        return resultHandler(result)
      }

      return result
    },
    options,
  )
}

export default useRoleQuery
