import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { BasicTemplate } from 'src/templates'

import { Title, Button } from 'src/components'

import styles from './result-template.module.scss'

import { ReactComponent as SuccessIcon } from './assets/success.svg'
import { ReactComponent as ErrorIcon } from './assets/error.svg'

interface ResultTemplateProps {
  isSuccess: boolean
  title: string
  description: string
  button?: {
    to: string
    text: string
  }
}

const ResultTemplate = (
  props: React.PropsWithChildren<ResultTemplateProps>,
) => {
  const { t } = useTranslation()

  const {
    isSuccess,
    title,
    description,
    button = {
      to: '/',
      text: t('common.backToPersonalAccount'),
    },
    children,
  } = props

  const Icon = isSuccess ? SuccessIcon : ErrorIcon

  return (
    <BasicTemplate>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <Icon />

          <Title
            className={cx(
              styles.title,
              isSuccess ? styles.success : styles.error,
            )}
          >
            {title}
          </Title>

          <div className={styles.description}>{description}</div>

          <Button className={styles.button} to={button.to}>
            {t(button.text)}
          </Button>

          <div>{children}</div>
        </div>
      </div>
    </BasicTemplate>
  )
}

export default ResultTemplate
