import { useRoleQuery } from 'src/hooks'

interface GetOrderParams {
  orderId: string
}

const useGetOrder = (params: GetOrderParams) => {
  const { orderId } = params

  return useRoleQuery({
    queryKeys: [`getOrder`, orderId],
    url: `/api/v1/orders/${orderId}`,
  })
}

export default useGetOrder
