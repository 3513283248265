import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { Form } from 'src/components'

import { useLogin } from 'src/hooks'

import { AxiosError } from 'axios'

interface AuthFormProps {
  className?: string
  onAuth?: (data: any) => void
}

const AuthForm = (props: React.PropsWithChildren<AuthFormProps>) => {
  const { className, onAuth, children } = props

  const { t } = useTranslation()

  const loginMutation = useLogin()

  const authFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values, { setFieldError }) => {
      await loginMutation
        .mutateAsync({
          login: values.email,
          password: values.password,
        })
        .then(({ data }) => {
          if (onAuth) {
            onAuth(data)
          } else {
            document.location.href = '/'
          }
        })
        .catch((error) => {
          setFieldError(
            'password',
            (error as AxiosError).response?.data.detail ?? t('common.error'),
          )
        })
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required(t('error.required') || '')
        .email(t('error.email') || ''),
      password: yup
        .string()
        .required(t('error.required') || '')
        .min(8, 'Не меньше 8 символов'),
    }),
  })

  return (
    <Form className={className} providerValue={authFormik}>
      {children}
    </Form>
  )
}

export default AuthForm
