import cx from 'classnames'

import styles from './template-wrapper.module.scss'

import { AppVersion } from 'src/components'

interface TemplateWrapperProps {
  template?: string
}

const TemplateWrapper = (
  props: React.PropsWithChildren<TemplateWrapperProps>,
) => {
  const { children, template } = props

  return (
    <div className={styles.wrapper}>
      {children}
      {/* <AppVersion className={styles.version} /> */}
    </div>
  )
}

export default TemplateWrapper
