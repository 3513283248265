import { useField } from 'formik'
import cx from 'classnames'

import { InputSearch } from 'src/components'

import AddingBlockProduct from './AddingBlockProduct'
import ResultTable from './ResultTable'

import styles from './adding-products-table.module.scss'

import { IInventory, IProductItem } from 'src/interfaces'

interface AddingProductsTableProps {
  productsList: IInventory[]
  value: IProductItem[]
  setValue: (value: IProductItem[]) => void
}

const AddingProductsTable = (props: AddingProductsTableProps) => {
  const { productsList = [], value = [], setValue } = props

  const handleAdd = (id: number) => {
    setValue([...value, { id, count: 1 }])
  }

  return (
    <div className={styles.wrapper}>
      <InputSearch />

      <div className={styles.inner}>
        {productsList.map((p: IInventory) => (
          <AddingBlockProduct
            key={p.id}
            {...p}
            isAdded={Boolean(
              value.find((vp: { id: number }) => vp.id === p.id),
            )}
            onAdd={handleAdd}
          />
        ))}
      </div>
    </div>
  )
}

AddingProductsTable.Result = ResultTable

export default AddingProductsTable
