import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './info-prompt.module.scss'

interface InfoPromptProps {
  className?: string
}

const InfoPrompt = (props: React.PropsWithChildren<InfoPromptProps>) => {
  const { className, children } = props

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.iconWrapper}>
        <Icons.InfoGhost />
      </div>

      <div className={styles.inner}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}

export default InfoPrompt
