import { Modal, Button } from 'src/components'

import { copyToClipboard } from 'src/helpers'

import styles from './chat-header-add-user-item-invite.module.scss'

interface ChatHeaderAddUserItemInviteProps {
  currentInviteId: number
  onClose: () => void
}

const ChatHeaderAddUserItemInvite = (
  props: ChatHeaderAddUserItemInviteProps,
) => {
  const { currentInviteId, onClose } = props

  const link = `https://studio.kak2c.ru/chat/id${currentInviteId}`

  return (
    <Modal
      className={styles.wrapper}
      id="chatAddUserInvite"
      headerIllustration="Email"
      headerIllustrationAlign="center"
      title="Приглашение в чат"
      showCloseButton
      onClose={onClose}
    >
      <div className={styles.inner}>
        <div>
          Чтобы пригласить в онлайн-чат съемки, скопируйте и отправьте ссылку
          пользователю
        </div>

        <a className={styles.link} href={link} target="_blank">
          {link}
        </a>

        <Button
          className={styles.button}
          size="small"
          template="secondary"
          icon="Copy"
          onClick={() => copyToClipboard(link)}
        >
          Скопировать ссылку
        </Button>
      </div>
    </Modal>
  )
}

export default ChatHeaderAddUserItemInvite
