import { useField } from 'formik'

import { Icons } from 'src/components'

import styles from './chat-message-form-attachment.module.scss'

const ChatMessageFormAttachment = () => {
  const [, , { setValue }] = useField('attachments')

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      setValue(files)
    }
  }

  return (
    <label className={styles.wrapper}>
      <Icons.Attachment />

      <input type="file" onChange={handleFileChange} />
    </label>
  )
}

export default ChatMessageFormAttachment
