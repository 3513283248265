import { useTranslation } from 'react-i18next'

import { BasicBlock, OrderPackage, InfoComment } from 'src/components'

import styles from './result-step-package.module.scss'

import { ICategory, IPackage } from 'src/interfaces'

interface ResultStepPackageProps {
  shootCategory?: ICategory
  shootPackage: IPackage
}

const ResultStepPackage = (props: ResultStepPackageProps) => {
  const { shootPackage } = props

  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <OrderPackage {...props} showImage approximatePrice />

      {shootPackage.description ? (
        <div className={styles.description}>{shootPackage.description}</div>
      ) : null}

      <InfoComment className={styles.postProduction} color="green" icon="Info">
        {t('order.postComment')}
      </InfoComment>
    </div>
  )
}

export default ResultStepPackage
