import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { Illustrations, Price, Button } from 'src/components'

import { calculateOrderTotal } from 'src/helpers'

import styles from './order-gallery-fixed.module.scss'

import {
  IShoot,
  IRetouch,
  IRetouchStatus,
  IInventoryWithPhotos,
  IPhoto,
} from 'src/interfaces'

interface OrderGalleryFixedProps {
  loading: boolean
  currentShoot: IShoot
  isRetouchStep: boolean
  products: IInventoryWithPhotos[]
  freePhotos: IPhoto[]
  onSubmit: () => void
}

const OrderGalleryFixed = (props: OrderGalleryFixedProps) => {
  const {
    loading,
    currentShoot,
    isRetouchStep,
    products,
    freePhotos,
    onSubmit,
  } = props

  const { t } = useTranslation()

  const [{ value: retouches }] = useField('retouches')

  const checkedPhotos =
    retouches.find(
      (retouch: IRetouch) =>
        retouch.type === 'basic' && retouch.status === IRetouchStatus.DRAFT,
    )?.images ?? []

  if (!checkedPhotos.length) {
    return null
  }

  const { basicTotalWithoutDiscount, basicTotalWithDiscount, extraTotal } =
    calculateOrderTotal({
      checkedPhotos,
      currentPackage: currentShoot.package,
      products,
      freePhotos,
    })

  const hasBasic = retouches.find(
    (retouch: IRetouch) => retouch.type === 'basic',
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <Illustrations.Basket />

        <div className={styles.countWrapper}>
          <div className={styles.label}>
            {!isRetouchStep
              ? t('common.orderDetails')
              : t('order.selectedItems') + ' '}
          </div>
          <div className={styles.count}>
            {!isRetouchStep
              ? `Выбрано ${checkedPhotos.length} фото`
              : `+ ${
                  hasBasic ? retouches.length - 1 : retouches.length
                } заказ(а) на доп. ретушь`}
          </div>
        </div>

        {/* <div className={styles.priceWrapper}>
          {!isRetouchStep ? (
            <>
              {basicTotalWithoutDiscount !== basicTotalWithDiscount ? (
                <Price
                  value={basicTotalWithoutDiscount + extraTotal}
                  template="subtracted"
                />
              ) : null}
              <Price value={basicTotalWithDiscount + extraTotal} />
            </>
          ) : (
            <>
              <Price template="label" value={basicTotalWithDiscount} />
              <Price value={extraTotal} />
            </>
          )}
        </div> */}

        <Button className={styles.button} loading={loading} onClick={onSubmit}>
          {t(!isRetouchStep ? 'common.proceed' : 'order.orderCheckout')}
        </Button>
      </div>
    </div>
  )
}

export default OrderGalleryFixed
