import { useState } from 'react'

import { ModalImage } from 'src/components'

import ChatPhotoGalleryItem from './ChatPhotoGalleryItem'

import styles from './chat-photo-gallery.module.scss'

const MOCK_GALLERY = [
  {
    id: 1,
    src: '/img/mock-photos/boots/1.png',
    timestamp: new Date('2023-04-13T12:00:00Z').toISOString(),
  },
  {
    id: 2,
    src: '/img/mock-photos/boots/2.png',
    timestamp: new Date('2023-04-14T12:00:00Z').toISOString(),
  },
  {
    id: 3,
    src: '/img/mock-photos/boots/3.png',
    timestamp: new Date('2023-04-15T12:00:00Z').toISOString(),
  },
  {
    id: 4,
    src: '/img/mock-photos/boots/4.png',
    timestamp: new Date('2023-04-16T12:00:00Z').toISOString(),
  },
  {
    id: 5,
    src: '/img/mock-photos/boots/5.png',
    timestamp: new Date('2023-04-17T12:00:00Z').toISOString(),
  },
  {
    id: 6,
    src: '/img/mock-photos/boots/6.jpeg',
    timestamp: new Date('2023-04-18T12:00:00Z').toISOString(),
  },
  {
    id: 7,
    src: '/img/mock-photos/boots/7.jpeg',
    timestamp: new Date('2023-04-19T12:00:00Z').toISOString(),
  },
  {
    id: 8,
    src: '/img/mock-photos/boots/8.png',
    timestamp: new Date('2023-04-20T12:00:00Z').toISOString(),
  },
]

const ChatPhotoGallery = () => {
  const photos = MOCK_GALLERY

  const [modalCurrentSrc, setModalCurrentSrc] = useState<string | undefined>(
    undefined,
  )

  const handleNavigateModal = (direction: string) => {
    const currentPhotoIndex = photos.findIndex(
      (photo) => photo.src === modalCurrentSrc,
    )

    if (direction === 'next') {
      const nextPhotoIndex = currentPhotoIndex + 1
      const nextPhoto = photos[nextPhotoIndex]

      setModalCurrentSrc((nextPhoto || photos[0]).src)
    } else if (direction === 'prev') {
      const prevPhotoIndex = currentPhotoIndex - 1
      const prevPhoto = photos[prevPhotoIndex]

      setModalCurrentSrc((prevPhoto || photos[photos.length - 1]).src)
    }
  }

  return (
    <>
      <div className={styles.wrapper}>
        {photos.map((photo) => (
          <ChatPhotoGalleryItem
            key={photo.id}
            {...photo}
            onGallery={(src) => setModalCurrentSrc(src)}
          />
        ))}
      </div>

      {modalCurrentSrc ? (
        <ModalImage
          src={modalCurrentSrc}
          onNavigate={handleNavigateModal}
          onClose={() => setModalCurrentSrc(undefined)}
        />
      ) : null}
    </>
  )
}

export default ChatPhotoGallery
