import { useTranslation } from 'react-i18next'

import { Modal, Button } from 'src/components'

import styles from './cool-modal.module.scss'

import coolImg from './assets/cool-picture.png'

interface CoolModalProps {
  buttonLabel?: string
  onClose: () => void
}

const CoolModal = (props: CoolModalProps) => {
  const { buttonLabel, onClose } = props

  const { t } = useTranslation()

  return (
    <Modal
      id="coolModal"
      headerImage={coolImg}
      title={t('common.successRegistration')}
      subtitle={t('common.weSentPassword')}
      onClose={onClose}
    >
      <div>
        <Button className={styles.button} onClick={onClose}>
          {buttonLabel || t('common.close')}
        </Button>
      </div>
    </Modal>
  )
}

export default CoolModal
