import { useTranslation } from 'react-i18next'

import { Icons } from 'src/components'

import rootStyles from '../single-order-data-for-staff.module.scss'
import styles from './single-order-data-for-staff-data.module.scss'

import { IOrder } from 'src/interfaces'

interface SingleOrderDataForStaffDataProps {
  order: IOrder
}

const SingleOrderDataForStaffData = (
  props: SingleOrderDataForStaffDataProps,
) => {
  const { order } = props

  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      {order.comment ? (
        <div>
          <div className={rootStyles.title}>{t('label.shootComment')}</div>

          <div className={styles.comment}>{order.comment}</div>
        </div>
      ) : null}

      {order.references?.length ? (
        <div>
          <div className={rootStyles.title}>
            {t('label.shootingReferences')}
          </div>

          {order.references?.map((reference, index) => (
            <div key={index} className={styles.reference}>
              <div className={styles.icon}>
                <Icons.Link />
              </div>

              <a href="#" target="_blank">
                {reference}
              </a>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default SingleOrderDataForStaffData
