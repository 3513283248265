import { useMutation } from 'react-query'
import { api } from 'src/api'

const useSendResetPasswordLink = () => {
  return useMutation(
    async (data: { login: string }) =>
      api.post('/accounts/send-reset-password-link/', data),
    {
      onSuccess: (result) => {
        if (result) {
        }
      },
    },
  )
}

export default useSendResetPasswordLink
