import { useMutation } from 'react-query'
import { api } from 'src/api'

import { ICreateShootData } from 'src/interfaces'

const useCreateShoot = () => {
  return useMutation(async (data: ICreateShootData) =>
    api.post('/api/v1/shoots/', data),
  )
}

export default useCreateShoot
