import { Icons } from 'src/components'

import ChatHeaderAddUser from './ChatHeaderAddUser'

import styles from './chat-header.module.scss'

const ChatHeader = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Icons.Users />
      </div>

      <div className={styles.inner}>
        <div className={styles.titleS}>Имя съемки</div>

        <div className={styles.attendees}>4 участника(-ов)</div>
      </div>

      <ChatHeaderAddUser />
    </div>
  )
}

export default ChatHeader
