import { Link } from 'react-router-dom'
import cx from 'classnames'

import { Container, Icons } from 'src/components'

import styles from './breadcrumbs.module.scss'

import { IBreadcrumb } from 'src/interfaces'

interface BreadcrumbsProps {
  breadcrumbs: IBreadcrumb[]
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { breadcrumbs } = props

  const handleClick = (
    event: React.MouseEvent<any>,
    breadcrumb: IBreadcrumb,
  ) => {
    if (breadcrumb.onClick) {
      event.preventDefault()
      breadcrumb.onClick()
    }
  }

  return (
    <Container className={styles.wrapper}>
      {breadcrumbs.map((b, i) => (
        <div
          key={i}
          className={cx(i === breadcrumbs.length - 1 && styles.last)}
        >
          {b.to ? (
            <Link to={b.to} onClick={(event) => handleClick(event, b)}>
              {b.title}
            </Link>
          ) : (
            <span onClick={(event) => handleClick(event, b)}>{b.title}</span>
          )}

          {i !== breadcrumbs.length - 1 ? (
            <span className={styles.separator}>
              <Icons.NormalArrow />
            </span>
          ) : null}
        </div>
      ))}
    </Container>
  )
}

export default Breadcrumbs
