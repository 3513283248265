import i18n from 'i18next'

import { AxiosError } from 'axios'

export const basicErrorHandler = (error: AxiosError, itemId: string) => {
  const { response } = error

  let title = ''
  let description = ''

  if (response?.data?.code) {
    title = `${i18n.t('common.error')} ${response.data.code}`

    const i18nKey = `error.${itemId}.${response.data.code}`
    description = i18n.exists(i18nKey) ? i18n.t(i18nKey) : response.data.error
  }

  if (typeof error.response?.data === 'string') {
    title = error.message
    description = response?.data
  }

  if (!description) {
    description = error.message
  }

  window?.showNotification(
    'alert',
    <div>
      {title ? (
        <>
          <div>
            <strong>{title}</strong>
          </div>
          <br />
        </>
      ) : null}

      <div>{description}</div>
    </div>,
  )
}
