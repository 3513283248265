import { ICategory } from 'src/interfaces'

export const getCategoryById = (
  categoryId: number | null,
  categories: ICategory[] = [],
) => {
  if (!categoryId) {
    return undefined
  }
  return categories.find((c: ICategory) =>
    c.sub_categories?.find((s) => s.id === categoryId),
  )
}
