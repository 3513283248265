import cx from 'classnames'

import styles from './informer.module.scss'

interface InformerProps {
  className?: string
  content: string | React.ReactNode
  status?: 'success' | 'system' | 'alert' | 'warning' | undefined
}

const Informer = (props: InformerProps) => {
  const { className, content, status } = props

  return (
    <div className={cx(styles.wrapper, status && styles[status], className)}>
      {content}
    </div>
  )
}

export default Informer
