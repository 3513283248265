import { Link } from 'react-router-dom'

import { Illustrations } from 'src/components'

import styles from './empty-block.module.scss'

import { IEmptyConfig } from 'src/components/Block/interfaces'

const EmptyBlock = (props: IEmptyConfig) => {
  const { illustration, description, linkTitle, linkUrl, linkAction } = props

  const IllustrationComponent =
    illustration &&
    (Object.entries(Illustrations)?.find(
      ([key, value]) => key === illustration,
    )?.[1] ??
      null)

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        {IllustrationComponent ? (
          <div className={styles.illustration}>
            <IllustrationComponent />
          </div>
        ) : null}

        <div className={styles.description}>{description}</div>

        {linkTitle ? (
          <>
            {!linkAction ? (
              <Link className={styles.link} to={linkUrl || '/'}>
                {linkTitle}
              </Link>
            ) : (
              <div className={styles.link} onClick={linkAction}>
                {linkTitle}
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  )
}

export default EmptyBlock
