import { useTranslation } from 'react-i18next'

import { Select } from 'src/components'

import RetouchColorSelectedOption from './RetouchColorSelectedOption'
import RetouchColorFilterPreview from './RetouchColorFilterPreview'

import { BACKGROUND_COLOR_OPTIONS } from 'src/config'

import styles from './retouch-color-filter.module.scss'

interface RetouchColorFilterProps {
  index: number
  onChange: (value: string) => void
}

const RetouchColorFilter = (props: RetouchColorFilterProps) => {
  const { index, onChange } = props

  const { t } = useTranslation()

  const fieldName = `retouches[${index}]`

  const options = BACKGROUND_COLOR_OPTIONS.map((option) => ({
    ...option,
    meta: { ...option.meta, fieldName },
  }))

  return (
    <Select
      name={`${fieldName}.background_color`}
      icon="Color"
      options={options}
      innerLabel={t('label.backgroundColor')}
      optionClassName={styles.option}
      SelectedOptionComponent={RetouchColorSelectedOption}
      OptionComponentChild={RetouchColorFilterPreview} //TODO just OptionComponent
      onChange={onChange}
    />
  )
}

export default RetouchColorFilter
