import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { Input } from 'src/components'

import styles from './single-order-references.module.scss'

interface SingleOrderReferencesProps {
  disabled?: boolean
}

const SingleOrderReferences = (props: SingleOrderReferencesProps) => {
  const { disabled = false } = props

  const { t } = useTranslation()

  const { values } = useFormikContext()

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('label.shootingReferences')}</div>

      <div className={styles.inner}>
        <Input
          disabled={disabled}
          name="references[0]"
          placeholder={t('placeholder.insertLinkToImagesOrProduct')}
          icon="Link"
        />

        <Input
          disabled={disabled}
          name="references[1]"
          placeholder={t('placeholder.insertLinkToImagesOrProduct')}
          icon="Link"
        />

        <Input
          disabled={disabled}
          name="references[2]"
          placeholder={t('placeholder.insertLinkToImagesOrProduct')}
          icon="Link"
        />
      </div>
    </div>
  )
}

export default SingleOrderReferences
