import { LOCAL_STORAGE_NAME } from 'src/config'

import { ICurrentUser } from 'src/interfaces'

class LocalStorage {
  private readonly key: string

  constructor(keyword: string) {
    this.key = keyword
  }

  get state() {
    return localStorage.getItem(this.key) || '{}'
  }

  get token() {
    return this.getData('token')
  }

  set token(token: string) {
    this.setData('token', token)
  }

  get currentUser() {
    return this.getData('currentUser')
  }

  set currentUser(u: ICurrentUser) {
    this.setData('currentUser', u)
  }

  get currentLang() {
    return this.getData('currentLang')
  }

  set currentLang(lang: string) {
    this.setData('currentLang', lang)
  }

  get currentBase() {
    return this.getData('currentBase')
  }

  set currentBase(b: string) {
    this.setData('currentBase', b)
  }

  get currentOperation() {
    return this.getData('currentOperation')
  }

  set currentOperation(b: string) {
    this.setData('currentOperation', b)
  }

  getData(key: string) {
    return this.state ? JSON.parse(this.state)?.[key] ?? undefined : undefined
  }

  setData(key: string, data: any) {
    localStorage.setItem(
      this.key,
      JSON.stringify({ ...JSON.parse(this.state), [key]: data }),
    )
  }

  clearData(key: string) {
    const state = JSON.parse(this.state)
    if (state.hasOwnProperty(key)) {
      delete state[key]
      localStorage.setItem(this.key, JSON.stringify(state))
    }
  }

  clear = () => localStorage.removeItem(this.key)
}

const appLocalStorage = new LocalStorage(LOCAL_STORAGE_NAME)

export default appLocalStorage
