import cx from 'classnames'

import { useChatContext } from 'src/pages/ChatPage/chatContext'

import { Icons } from 'src/components'

import styles from './chat-reply-item.module.scss'

interface ChatReplyItemProps {
  messageId: string
  showIcon?: boolean
}

const ChatReplyItem = (props: ChatReplyItemProps) => {
  const { messageId, showIcon } = props

  const { messages, setMessageIdToScrollTo } = useChatContext()

  const messageToReply = messages.find((message) => message.id === messageId)

  if (!messageToReply) {
    return null
  }

  const isImage = messageToReply?.image

  return (
    <div className={styles.wrapper}>
      {showIcon ? (
        <div className={styles.icon}>
          <Icons.Reply />
        </div>
      ) : null}

      <div
        className={cx(styles.inner, showIcon && styles.withIcon)}
        onClick={() => setMessageIdToScrollTo(messageId)}
      >
        {isImage ? (
          <img className={styles.image} src={messageToReply.image?.imageUrl} />
        ) : null}

        <div className={styles.message}>
          <div className={styles.author}>{messageToReply.author.name}</div>

          <div className={styles.text}>
            {!isImage ? messageToReply.message : 'Фото'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatReplyItem
