import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { Container, LangSelect } from 'src/components'

import styles from './footer-bottom-block.module.scss'

const FooterBottomBlock = () => {
  const { t } = useTranslation()

  return (
    <Container className={styles.wrapper}>
      <div className={styles.companyName}>{`© ${dayjs().format('YYYY')} ${t(
        'settings.companyName',
      )}`}</div>

      <div className={styles.requizites}>1177746324359</div>

      <LangSelect className={styles.langs} />
    </Container>
  )
}

export default FooterBottomBlock
