import cx from 'classnames'

import { Container } from 'src/components'

import Header from './Header'
import Breadcrumbs from './Breadcrumbs'
import Footer from './Footer'

import { useAppContext } from 'src/context'

import styles from './basic-template.module.scss'

import { IBreadcrumb } from 'src/interfaces'

interface BasicTemplateProps {
  breadcrumbs?: IBreadcrumb[]
  TopPanel?: React.ReactNode
}

const BasicTemplate = (props: React.PropsWithChildren<BasicTemplateProps>) => {
  const { breadcrumbs = [], TopPanel, children } = props

  const { authorized } = useAppContext()

  return (
    <div className={styles.wrapper}>
      {authorized ? <Header /> : null}

      {TopPanel ? (
        <div className={styles.topPanel}>
          <Container className={styles.topPanelInner}>{TopPanel}</Container>
        </div>
      ) : null}

      {breadcrumbs.length ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}

      <Container
        className={cx(
          styles.inner,
          breadcrumbs.length && styles.withBreadcrumbs,
        )}
      >
        {children}
      </Container>

      <Footer />
    </div>
  )
}

export default BasicTemplate
