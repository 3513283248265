import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Badge } from 'src/components'

import { calculateOrderItemDiscount } from 'src/helpers'

import styles from './photo-step-product-badges.module.scss'

import { IPackage, IPhoto } from 'src/interfaces'

interface PhotoStepProductBadgesProps {
  currentPackage: IPackage | null
  photo: IPhoto
  discountIsAvailable: boolean
}

const PhotoStepProductBadges = (props: PhotoStepProductBadgesProps) => {
  const { currentPackage, photo, discountIsAvailable } = props

  const { t } = useTranslation()

  return (
    <div className={cx(styles.wrapper, photo.was_bought && styles.wasBought)}>
      {!photo.was_bought ? (
        <>
          {currentPackage?.additional_photo_price && discountIsAvailable ? (
            <Badge template="success">{`-${calculateOrderItemDiscount(
              currentPackage,
            ).toFixed(0)}%`}</Badge>
          ) : null}

          <Badge template="success">{`${
            !discountIsAvailable
              ? currentPackage?.price
              : currentPackage?.additional_photo_price
          }₽`}</Badge>
        </>
      ) : (
        <>
          <Badge template="system">{t('badge.photoPurchased')}</Badge>

          <Badge className={styles.retouchPrice}>{`${t(
            'common.additionalRetouch',
          )}: ${currentPackage?.additional_retouche_price}₽`}</Badge>
        </>
      )}
    </div>
  )
}

export default PhotoStepProductBadges
