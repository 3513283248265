import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Price, Badge } from 'src/components'

import styles from './create-order-package.module.scss'

import { IPackage } from 'src/interfaces'

interface CreateOrderPackageProps extends IPackage {
  setCurrentStep: (step: number) => void
}

const CreateOrderPackage = (props: CreateOrderPackageProps) => {
  const {
    id: packageId,
    category_id,
    title,
    price,
    additional_photo_price,
    banner_image,
    coming_soon,
    is_hit,
    setCurrentStep,
  } = props

  const { t } = useTranslation()

  const { setFieldValue } = useFormikContext()

  const handleSelectPackage = () => {
    if (!coming_soon) {
      setFieldValue('category_id', category_id)
      setFieldValue('packages', [{ id: packageId }])

      setCurrentStep(1)
    }
  }

  return (
    <div className={styles.wrapper} onClick={handleSelectPackage}>
      {coming_soon ? (
        <div className={styles.comingSoonBlocker}>
          <Badge className={styles.badge} icon="Timer" template="disabled">
            {t('badge.soon')}
          </Badge>
        </div>
      ) : null}

      <div className={styles.inner}>
        <div>
          {is_hit ? (
            <Badge className={styles.hit} icon="Fire" template="danger">
              {t('badge.hit')}
            </Badge>
          ) : null}

          <div>
            <div className={styles.titleL}>{title}</div>
            {!coming_soon ? (
              <div className={styles.link}>{t('common.toOrder')}</div>
            ) : null}
          </div>
          {/* {!coming_soon ? (
            <Price
              color="white"
              prefix={t('common.from')}
              value={additional_photo_price || price}
              label={t('common.perPhoto')}
            />
          ) : null} */}
        </div>
      </div>

      {!coming_soon ? (
        <div className={styles.hoverer}>
          <div>{t('common.toOrder')}</div>
        </div>
      ) : null}

      {/* TODO remove hack */}
      <img
        className={cx(styles.bannerImage, coming_soon && styles.disabled)}
        src={
          banner_image
            ? banner_image.replace('http://', 'https://')
            : '/img/logo.svg'
        }
      />
    </div>
  )
}

export default CreateOrderPackage
