import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { BasicTemplate } from 'src/templates'

import * as Blocks from './NeedAuthBlocks'

import { BasicPageHeader } from 'src/components'

import { useAppContext } from 'src/context'

import styles from './need-auth.module.scss'

interface NeedAuthPageProps {
  subtitle?: string
}

const NeedAuthPage = (
  props: React.PropsWithChildren<NeedAuthPageProps> = {},
) => {
  const { t } = useTranslation()

  const { state = {} } = useLocation() as { state: NeedAuthPageProps }

  const [currentBlock, setCurrentBlock] = useState<
    'Auth' | 'Register' | 'Forgot'
  >('Register')

  const { setAuthorized } = useAppContext()

  const { subtitle } = Object.keys(props).length ? props : state

  const handleAuth = () => {
    setAuthorized(true)
  }

  const CurrentBlock = Blocks[`${currentBlock}Block`] || null

  return (
    <BasicTemplate>
      <div className={styles.wrapper}>
        <BasicPageHeader
          title={t('needAuth.title')}
          subtitle={subtitle}
          description={t('needAuth.description')}
        />

        <div className={styles.inner}>
          {CurrentBlock ? (
            <CurrentBlock
              onAuth={handleAuth}
              setCurrentBlock={setCurrentBlock}
            />
          ) : null}
        </div>
      </div>
    </BasicTemplate>
  )
}

export default NeedAuthPage
