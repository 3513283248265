import { useState, useMemo, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import styles from './app.module.scss'

import Main from './Main'

import { useGetAccount } from 'src/hooks'

import { useAppContext } from 'src/context'

// import { NotificationProvider } from 'src/notification'

import { PATHS, PUBLIC_PATHS, COMMON_PATHS } from './config'

const GlobalLoader = () => <div className={styles.globalLoader}>Loading...</div>

const App = () => {
  const {
    globalLoading,
    setGlobalLoading,
    authorized,
    setAuthorized,
    setCurrentUser,
  } = useAppContext()

  const location = useLocation()
  const navigate = useNavigate()

  const [isReady, setIsReady] = useState(false)

  const account = useGetAccount()

  useEffect(() => {
    if (account?.status === 'error') {
      const isPublicPath = Object.values({
        ...PUBLIC_PATHS,
        ...COMMON_PATHS,
      }).find(
        (path) =>
          path === location.pathname || `${path}/` === location.pathname, //TODO refactor
      )

      if (!isPublicPath) {
        navigate(PATHS.AUTH)
      }
    }

    if (account?.status === 'success') {
      setCurrentUser(account.data.data)
      setAuthorized(true)
    }

    if (account.status && account.status !== 'loading') {
      setIsReady(true)
      setGlobalLoading(false)
    }
  }, [account.status])

  return useMemo(
    () => (
      <>
        {isReady ? (
          <>
            {globalLoading ? <GlobalLoader /> : null}
            <Main authorized={authorized} />
          </>
        ) : null}
      </>
    ),
    [authorized, globalLoading, isReady],
  )
}

export default App
