import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './single-order-tabs-header-item.module.scss'

interface SingleOrderTabsHeaderItemProps {
  title: string
  icon: string
  disabled?: boolean
  isActive: boolean
  onSelect: () => void
}

const SingleOrderTabsHeaderItem = (props: SingleOrderTabsHeaderItemProps) => {
  const { title, icon, disabled, isActive, onSelect } = props

  const handleSelect = () => {
    if (!disabled) {
      onSelect()
    }
  }

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  return (
    <div
      className={cx(
        styles.wrapper,
        isActive && styles.active,
        disabled && styles.disabled,
      )}
      onClick={handleSelect}
    >
      {IconComponent ? <IconComponent /> : null}

      {title}
    </div>
  )
}

export default SingleOrderTabsHeaderItem
