import { useField, useFormikContext } from 'formik'
import cx from 'classnames'

import styles from './textarea.module.scss'

import InputProps from 'src/components/Input/InputProps'

interface TextareaProps extends InputProps {
  maxLength?: number
}

const Textarea = (props: TextareaProps) => {
  const { className, name, label, placeholder, required, disabled, maxLength } =
    props

  const [field, meta] = useField({ name })
  const { isSubmitting } = useFormikContext()

  const isDisabled = disabled || isSubmitting

  return (
    <div className={styles.wrapper}>
      {typeof label !== 'undefined' ? (
        label ? (
          <label>
            {label} {required ? <span>*</span> : null}
          </label>
        ) : (
          <label>&nbsp;</label>
        )
      ) : null}

      <div
        className={cx(
          styles.inner,
          maxLength && styles.withCounter,
          meta.touched && meta.error && styles.error,
          isDisabled && styles.disabled,
          className,
        )}
      >
        <textarea
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={isDisabled}
          {...field}
        />

        {maxLength ? (
          <div className={styles.maxLengthCounter}>
            {`${field.value?.length || 0}/${maxLength}`}
          </div>
        ) : null}
      </div>

      {meta.touched && meta.error && (
        <div className={styles.errorComment}>{meta.error}</div>
      )}
    </div>
  )
}

export default Textarea
