import cx from 'classnames'

import styles from './basic-block.module.scss'

import { IBasicBlock } from 'src/components/Block/interfaces'

const BasicBlock = (props: React.PropsWithChildren<IBasicBlock>) => {
  const { className, padding, backgroundImg, children } = props

  return (
    <div
      className={cx(
        styles.wrapper,
        backgroundImg && styles.withBackgroundImg,
        className,
      )}
      style={{
        padding: padding ? `${String(padding)}px` : undefined,
        backgroundImage: backgroundImg ? `url(${backgroundImg})` : undefined,
      }}
    >
      {children}
    </div>
  )
}

export default BasicBlock
