import CreateOrderCategory from './CreateOrderCategory'

import { ICategory, IPackage } from 'src/interfaces'

interface PackagesStepProps {
  categories: ICategory[]
  packages: IPackage[]
  setCurrentStep: (step: number) => void
}

const PackagesStep = (props: PackagesStepProps) => {
  const { categories, packages, setCurrentStep } = props

  const filteredCategories = categories.filter(
    (category: ICategory) => !category.is_hidden,
  )
  const filteredPackages = packages.filter((p: IPackage) => !p.is_hidden)

  return (
    <div>
      {filteredCategories.map((category: ICategory) => (
        <CreateOrderCategory
          key={category.id}
          {...category}
          packages={filteredPackages}
          setCurrentStep={setCurrentStep}
        />
      ))}
    </div>
  )
}

export default PackagesStep
