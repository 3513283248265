import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './info-comment.module.scss'

interface InfoComment {
  className?: string
  color?: string
  icon?: string
  align?: 'center' | 'top'
}

const InfoComment = (props: React.PropsWithChildren<InfoComment>) => {
  const { className, color, icon, align, children } = props

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  return (
    <div
      className={cx(
        styles.wrapper,
        color && styles[color],
        align && styles[align],
        className,
      )}
    >
      {IconComponent ? (
        <span className={styles.icon}>
          <IconComponent />
        </span>
      ) : null}

      <div>{children}</div>
    </div>
  )
}

export default InfoComment
