import { useFormik } from 'formik'

import { Form, Role } from 'src/components'

import SingleOrderAction from './SingleOrderAction'
import SingleOrderPackage from './SingleOrderPackage'
import SingleOrderData from './SingleOrderData'

import { useUpdateOrder } from 'src/hooks'

import styles from './single-order-details-step-customer.module.scss'

import { IOrder, IOrderStatusAction, IShoot } from 'src/interfaces'

interface SingleOrderDetailsStepCustomerProps {
  order: IOrder
  currentShoot: IShoot
  currentAction?: IOrderStatusAction | null
  orderRefetch: () => Promise<any>
  setCurrentStep: (step: number) => void
}

const SingleOrderDetailsStepCustomer = (
  props: SingleOrderDetailsStepCustomerProps,
) => {
  const { order, currentShoot, currentAction, orderRefetch, setCurrentStep } =
    props

  const orderMutation = useUpdateOrder({ id: order.id })

  const formik = useFormik({
    initialValues: {
      comment: order.comment,
      references: order.references || [],
    },
    onSubmit: async (values) => {
      const newValue = {
        ...order,
        ...values,
        id: undefined,
      }

      await orderMutation.mutateAsync(newValue)
    },
  })

  return (
    <Form className={styles.wrapper} providerValue={formik}>
      <Role.Customer>
        {currentAction ? (
          <SingleOrderAction
            order={order}
            currentAction={currentAction}
            orderRefetch={orderRefetch}
            setCurrentStep={setCurrentStep}
          />
        ) : null}
      </Role.Customer>

      <div className={styles.inner}>
        <SingleOrderPackage currentShoot={currentShoot} />

        <SingleOrderData order={order} />
      </div>
    </Form>
  )
}

export default SingleOrderDetailsStepCustomer
