import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { Select, Textarea } from 'src/components'

import RetouchFramingFilter from './RetouchFramingFilter'
import RetouchColorFilter from './RetouchColorFilter'

import { ALIGN_OPTIONS } from 'src/config'

import styles from './retouch-filters.module.scss'

interface RetouchFiltersProps {
  index: number
}

const RetouchFilters = (props: RetouchFiltersProps) => {
  const { index } = props

  const { t } = useTranslation()

  const [{ value: currentRetouch }, _, { setValue }] = useField(
    `retouches[${index}]`,
  )

  const handleChangeOption = (name: string, value: string) => {
    setValue({
      ...currentRetouch,
      [name]: value,
    })
  }

  return (
    <div className={styles.wrapper}>
      <RetouchFramingFilter
        index={index}
        onChange={(value) => handleChangeOption('framing', value)}
      />

      <Select
        name={`retouches[${index}].align`}
        icon="Alignment"
        options={ALIGN_OPTIONS}
        innerLabel={t('label.alignment')}
        onChange={(value) => handleChangeOption('align', value)}
      />

      <RetouchColorFilter
        index={index}
        onChange={(value) => handleChangeOption('background_color', value)}
      />

      <Textarea
        className={styles.comment}
        name={`retouches[${index}].comment`}
        placeholder={t('placeholder.orderComment')}
        maxLength={155}
      />
    </div>
  )
}

export default RetouchFilters
