import { useState } from 'react'
import dayjs from 'dayjs'
import cx from 'classnames'

import { ChatUserAvatar } from 'src/pages/ChatPage/components'

import * as ChatMessagesTypes from './ChatMessagesTypes'
import ChatMessagesItemContextMenu from './ChatMessagesItemContextMenu/ChatMessagesItemContextMenu'

import styles from './chat-messages-item.module.scss'

import { IChatMessage } from 'src/interfaces'

interface ChatMessagesItemProps extends IChatMessage {
  isLastInGroup?: boolean
}

const ChatMessagesItem = (props: ChatMessagesItemProps) => {
  const {
    id,
    isLastInGroup,
    message,
    timestamp,
    author,
    image,
    repliedToMessageId,
  } = props

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const handleContextMenu = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()

    setIsContextMenuOpen(true)
  }

  const time = dayjs(timestamp).format('HH:mm')

  const MessageComponent = (() => {
    let messageType = 'Text'

    if (image) {
      messageType = 'Image'
    }

    return ChatMessagesTypes[
      `ChatMessagesItem${messageType}` as keyof typeof ChatMessagesTypes
    ]
  })()

  return (
    <div
      className={cx(styles.wrapper, author.isUser && styles.isUser)}
      onContextMenu={handleContextMenu}
    >
      {!author.isUser && isLastInGroup ? (
        <ChatUserAvatar name={author.name.split(' ')} />
      ) : null}

      <MessageComponent
        messageId={id}
        message={message}
        time={time}
        author={author}
        image={image}
        repliedToMessageId={repliedToMessageId}
      />

      {isContextMenuOpen ? (
        <ChatMessagesItemContextMenu
          messageId={id}
          onClose={() => setIsContextMenuOpen(false)}
        />
      ) : null}
    </div>
  )
}

export default ChatMessagesItem
