import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './count.module.scss'

interface CountProps {
  className?: string
  value: number
  setValue: (value: number) => void
}

const Count = (props: CountProps) => {
  const { className, value, setValue } = props

  return (
    <div className={cx(styles.wrapper, className)}>
      <div
        className={styles.button}
        onClick={() => setValue(value > 1 ? value - 1 : value)}
      >
        <Icons.MinusCircle />
      </div>

      <div className={styles.value}>{value}</div>

      <div className={styles.button} onClick={() => setValue(value + 1)}>
        <Icons.PlusCircle />
      </div>
    </div>
  )
}

export default Count
