import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Form } from 'src/components'

import { useSendResetPasswordLink } from 'src/hooks'
import { useNotifications } from 'src/notification'

interface ForgotFormProps {
  className?: string
}

const ForgotForm = (props: React.PropsWithChildren<ForgotFormProps>) => {
  const { className, children } = props

  const { t } = useTranslation()

  const resetLinkMutation = useSendResetPasswordLink()

  const notification = useNotifications()

  const forgotFormik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      await resetLinkMutation
        .mutateAsync({ login: values.email })
        .then(({ data }) => {
          notification?.show('success', t('forgot.success'))
        })
        .catch((err) => {
          console.log('CATCH ERROR')
          console.log(err)
        })
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required(t('error.required') || '')
        .email(t('error.email') || ''),
    }),
  })
  return (
    <Form className={className} providerValue={forgotFormik}>
      {children}
    </Form>
  )
}

export default ForgotForm
