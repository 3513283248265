import { IOrderStatus, IOrderStatusAction } from 'src/interfaces'

export const ORDER_STATUSES = {
  [IOrderStatus.DRAFT]: {
    title: 'status.order.draft',
    icon: 'Flag',
    inRoadmap: true,
  },
  // [IOrderStatus.SHIPMENT_PENDING]: {
  //   title: 'status.order.shipmentPending',
  //   icon: 'Box',
  //   inRoadmap: true,
  //   action: {
  //     title: 'status.order.shipmentPendingActionTitle',
  //     description: 'status.order.shipmentPendingActionDescription',
  //     buttons: [
  //       {
  //         id: 'addItems',
  //         label: 'dashboard.addProduct',
  //       },
  //       {
  //         id: 'confirmOrder',
  //         label: 'status.order.needsConfirmationActionButton',
  //       },
  //     ],
  //   },
  // },
  [IOrderStatus.SHIPMENT_PENDING]: {
    title: 'status.order.needsConfirmation',
    icon: 'CheckShield',
    roadmapFallback: IOrderStatus.NEEDS_CONFIRMATION,
  },
  [IOrderStatus.NEEDS_CONFIRMATION]: {
    title: 'status.order.needsConfirmation',
    icon: 'CheckShield',
    inRoadmap: true,
    action: {
      title: 'status.order.needsConfirmationActionTitle',
      description: 'status.order.needsConfirmationActionDescription',
      buttons: [
        {
          id: 'confirmOrder',
          label: 'common.confirm',
        },
      ],
    },
  },
  [IOrderStatus.CONFIRMED]: {
    title: 'status.order.confirmed',
    icon: 'MoviePlay',
    inRoadmap: true,
    action: {
      title: 'status.order.confirmedActionTitle',
      description: 'status.order.confirmedActionDescription',
      buttons: [
        {
          id: 'openFAQ',
        },
      ],
    },
  },
  [IOrderStatus.SHOOT_IN_PROGRESS]: {
    title: 'status.order.shootInProgress',
    roadmapFallback: IOrderStatus.CONFIRMED,
    action: {
      title: 'status.order.shootInProgressActionTitle',
      description: 'status.order.shootInProgressActionDescription',
      buttons: [
        {
          id: 'openFAQ',
        },
      ],
    },
  },
  [IOrderStatus.SHOOT_PROCESSING]: {
    title: 'status.order.shootProcessing',
    roadmapFallback: IOrderStatus.CONFIRMED,
    action: {
      title: 'status.order.shootProcessingActionTitle',
      description: 'status.order.shootProcessingActionDescription',
      buttons: [
        {
          id: 'openFAQ',
        },
      ],
    },
  },
  [IOrderStatus.READY_FOR_RETOUCH]: {
    title: 'status.order.readyForRetouch',
    icon: 'Bag',
    inRoadmap: true,
    action: {
      title: 'status.order.readyForRetouchActionTitle',
      description: 'status.order.readyForRetouchActionDescription',
      buttons: [
        {
          id: 'choosePhotos',
          label: 'status.order.readyForRetouchActionButton',
        },
      ],
    },
  },
  [IOrderStatus.RETOUCH_ORDER_CREATED]: {
    title: 'status.order.retouchOrderCreated',
    roadmapFallback: IOrderStatus.READY_FOR_RETOUCH,
    action: {
      title: 'status.order.retouchOrderCreatedActionTitle',
      description: 'status.order.retouchOrderCreatedActionDescription',
      buttons: [
        {
          id: 'choosePhotos',
          label: 'status.order.retouchOrderCreatedActionButton',
        },
      ],
    },
  },
  [IOrderStatus.RETOUCH_IN_PROGRESS]: {
    title: 'common.retouch',
    icon: 'Brush',
    inRoadmap: true,
  },
  [IOrderStatus.FINISHED]: {
    title: 'status.order.finished',
    icon: 'FlagFinish',
    inRoadmap: true,
    action: {
      title: 'status.order.finishedActionTitle',
      description: 'status.order.finidhedActionDescription',
      buttons: [
        {
          id: 'downloadPhotos',
          label: 'common.downloadOrder',
        },
      ],
    },
  },
} as {
  [key in IOrderStatus]: {
    title: string
    icon?: string
    inRoadmap?: boolean
    roadmapFallback?: IOrderStatus
    action?: IOrderStatusAction
  }
}
