import { useMutation } from 'react-query'
import { api } from 'src/api'

interface IAddNewBitrixLeadData {
  phone: string
  email: string
}

const useAddNewBitrixLead = () => {
  return useMutation(async (data: IAddNewBitrixLeadData) => {
    const { phone, email } = data
    const title = `(фото) Регистрация в ЛК: ${phone}`

    const url = `https://kak2c.bitrix24.ru/rest/66/zurmp5lxef7rwvvw/crm.lead.add.json?FIELDS[TITLE]=${title}&FIELDS[EMAIL][0][VALUE]=${email}&FIELDS[PHONE][0][VALUE]=${phone}`

    return await api.get(url)
  })
}

export default useAddNewBitrixLead
