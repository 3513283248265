import { FormikContextType } from 'formik'
import cx from 'classnames'

import { Form, Logo, LangSelect } from 'src/components'

import styles from './auth-template.module.scss'

interface AuthTemplateProps {
  backgroundImage?: string
  backgroundColor?: string
  hero?: React.ReactNode
  heroClass?: string
  heroPosition?: string
  title: React.ReactNode
  FormComponent?: React.ComponentType<{ className?: string }>
  providerValue?: FormikContextType<any>
}

const AuthTemplate = (props: React.PropsWithChildren<AuthTemplateProps>) => {
  const {
    backgroundImage,
    backgroundColor,
    hero,
    heroClass,
    heroPosition,
    title,
    FormComponent,
    providerValue,
    children,
  } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.formBlock}>
        <Logo />

        <div className={styles.title}>{title}</div>

        {FormComponent ? (
          <FormComponent className={styles.contentWrapper}>
            {children}
          </FormComponent>
        ) : providerValue ? (
          <Form className={styles.contentWrapper} providerValue={providerValue}>
            {children}
          </Form>
        ) : (
          <div className={styles.contentWrapper}>{children}</div>
        )}

        <LangSelect className={styles.langSelect} />
      </div>

      <div
        className={cx(styles.heroBlock, heroPosition && styles[heroPosition])}
        style={{
          backgroundImage: backgroundImage
            ? `url(/img/auth/${backgroundImage})`
            : 'none',
          backgroundColor: backgroundColor || 'transparent',
        }}
      >
        {hero ? (
          <div className={cx(styles.heroTitle, heroClass)}>{hero}</div>
        ) : null}
      </div>
    </div>
  )
}

export default AuthTemplate
