import { useTranslation } from 'react-i18next'

import { Input, Textarea } from 'src/components'

import styles from './result-step-params.module.scss'

const ResultStepParams = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <Input required name="title" label={t('label.shootTitle')} />

        <Textarea name="comment" label={t('label.enterShootComment')} />
      </div>
    </div>
  )
}

export default ResultStepParams
