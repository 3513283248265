import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Form, Input, SubmitButton } from 'src/components'

import { useUpdateOrder } from 'src/hooks'

import styles from './single-order-header-form.module.scss'

import { IOrder } from 'src/interfaces'

interface SingleOrderHeaderFormProps {
  order: IOrder
  onSubmit: () => void
}

const SingleOrderHeaderForm = (props: SingleOrderHeaderFormProps) => {
  const { order, onSubmit } = props

  const { t } = useTranslation()

  const orderMutation = useUpdateOrder({ id: order.id })

  const formik = useFormik({
    initialValues: {
      title: order.title,
    },
    onSubmit: async (values) => {
      const newValue = {
        ...order,
        ...values,
        id: undefined,
      }

      //delete newValue.id

      await orderMutation.mutateAsync(newValue)

      onSubmit()
    },
  })

  return (
    <Form className={styles.wrapper} providerValue={formik}>
      <Input name="title" />

      <SubmitButton>{t('common.save')}</SubmitButton>
    </Form>
  )
}

export default SingleOrderHeaderForm
