import { useQuery } from 'react-query'

import { api } from 'src/api'

interface UseGetCategoriesParams {
  ordering?: 'priority'
}

const useGetCategories = (params: UseGetCategoriesParams = {}) => {
  return useQuery([`getCategories`], async () => {
    const result = await api.get(`/api/v1/categories/`, { params })
    if (result?.status === 200) {
      return result.data
    }

    return []
  })
}

export default useGetCategories
