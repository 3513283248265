import { useMutation } from 'react-query'
import { api } from 'src/api'

import { IVerifyData } from 'src/interfaces'

type VerifyDataProps = IVerifyData & {
  password?: string
}

const useVerify = (endpoint: string) => {
  return useMutation(
    async (data: VerifyDataProps) => api.post(`/accounts/${endpoint}/`, data),
    {
      onSuccess: (result) => {
        if (result) {
        }
      },
    },
  )
}

export default useVerify
