import { BasicBlock } from 'src/components'

import { SingleOrderOptions } from 'src/pages/SingleOrderPage/SingleOrderMainBlock/SingleOrderTabs/components'

import SingleOrderDataForStaffCustomer from './SingleOrderDataForStaffCustomer'
import SingleOrderDataForStaffPackage from './SingleOrderDataForStaffPackage'
import SingleOrderDataForStaffData from './SingleOrderDataForStaffData'

import styles from './single-order-data-for-staff.module.scss'

import { IOrder, IShoot } from 'src/interfaces'

interface SingleOrderDataForStaffProps {
  order: IOrder
  currentShoot: IShoot
}

const SingleOrderDataForStaff = (props: SingleOrderDataForStaffProps) => {
  const { order, currentShoot } = props

  return (
    <BasicBlock className={styles.wrapper} padding={32}>
      <SingleOrderDataForStaffCustomer userId={String(order.user_id)} />

      <SingleOrderDataForStaffPackage currentShoot={currentShoot} />

      <SingleOrderDataForStaffData order={order} />

      <SingleOrderOptions />
    </BasicBlock>
  )
}

export default SingleOrderDataForStaff
