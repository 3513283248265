export default {
  common: {
    user: 'Пользователь',
    backToPersonalAccount: 'Вернуться в личный кабинет',
    package: 'Пакет',
    itemShort: 'шт.',
    apiece: 'Поштучно',
    background: 'Фон',
    choose: 'выбрать',
    noChoice: 'без выбора',
    model: 'Модель',
    shortDescription: 'Краткое описание',
    send: 'Отправить',
    edit: 'Редактировать',
    makeReservation: 'Создать съемку',
    back: 'Назад',
    proceed: 'Продолжить',
    shootCreating: 'Создание съемки',
    order: 'Съемка',
    orders: 'Съемки',
    barcode: 'Штрихкод',
    articleShort: 'Арт.',
    article: 'Артикул',
    amountShort: 'Кол-во',
    inventory: 'Инвентарь',
    downloadOrder: 'Скачать фото',
    supply: 'Посылка',
    supplies: 'Посылки',
    deliveryService: 'Служба доставки',
    unknown: '–',
    registration: 'Регистрация',
    comeIn: 'Войти',
    create: 'Создать',
    createAccount: 'Создать учетную запись',
    registerAccepting:
      'Я подтверждаю, что согласен на обработку персональных данных и принимаю пользовательское соглашение и условия оказания услуг.',
    alreadyHasAccount: 'Уже есть учетная запись?',
    category: 'Категория',
    product: 'Товар',
    product_s: 'товар(-ов)',
    addPhoto: 'Добавить фото',
    gallery: 'Галерея',
    details: 'Детали',
    retouchOrder: 'Заказ на ретушь',
    orderDetails: 'Детали заказа',
    retouch: 'Ретушь',
    additionalRetouch: 'Доп. ретушь',
    add: 'Добавить',
    added: 'Добавлен',
    successRegistration: 'Успешная регистрация',
    weSentPassword: 'Пароль отправлен Вам на e-mail',
    close: 'Закрыть',
    supportedFormats: 'Поддерживаемые форматы',
    error: 'Ошибка',
    chooseBackgroundColorForShoot: 'Выберите цвет фона для съемки',
    shootAtStands: 'Съемка на стендах',
    newCompositionCreating: 'Создание новой композиции',
    newCompositionCreatingDescription:
      'Выберите необходимые товары и придумайте название для композиции',
    composition: 'Композиция',
    cancel: 'Отменить',
    addComposition: 'Добавить композицию',
    chooseProductFromInventory: 'Выберите товары из инвентаря',
    chooseProductFromInventoryDescription:
      'Добавьте необходимые товары для съемки со склада',
    newProductDescription: 'Напишите наименование штрихкод и артикул товара',
    createProduct: 'Создать товар',
    save: 'Сохранить',
    newSupplyDescription:
      'Добавьте товары которые находятся в посылке, укажите курьерскую службу и трек-номер посылки',
    editSupply: 'Редактировать посылку',
    areYouSureAboutCancellation: 'Вы уверены что хотите отменить?',
    allDataWillBeLost: 'Все данные будут утеряны',
    productsForShoot: 'Товары для съемки',
    newProduct: 'Новый товар',
    addFromInventory: 'Добавить из инвентаря',
    logout: 'Выйти',
    stepCounter: 'Шаг {{step}} из {{count}}',
    discount: 'Скидка',
    discountReceived: 'Скидка получена',
    results: 'Результаты',
    shootingDate: 'Дата съемки',
    toOrder: 'Заказать',
    confirm: 'Подтвердить',
    from: 'от',
    perPhoto: 'За фото',
    whenOrderingFrom: 'при заказа от {{count}} шт.',
  },
  label: {
    email: 'Email',
    password: 'Пароль',
    phone: 'Номер телефона',
    framing: 'Кадрирование',
    alignment: 'Выравнивание',
    backgroundColor: 'Цвет фона',
    newPassword: 'Новый пароль',
    productName: 'Наименование товара',
    barcodeAndArticle: 'Штрихкод и артикул товара',
    enterShootComment: 'Напишите задание и цель съемки',
    shootComment: 'Задание и цель съемки',
    customColor: 'Свой цвет',
    shootTitle: 'Введите название съемки',
    shootingReferences: 'Примеры для съемки',
  },
  'Enter shooting title': 'Введите название съемки',
  placeholder: {
    categoryTitle: 'Название категории',
    yourEmail: 'Ваш email',
    enterPassword: 'Введите пароль',
    enterNameOrBarcodeOfProduct: 'Введите наименование или штрихкод товара',
    productName: 'Введите наименование товара',
    barcode: 'Введите штрихкод',
    article: 'Введите артикул',
    trackNumber: 'Трек-номер',
    insertLinkToImagesOrProduct: 'Вставьте ссылку на изображения или товар',
    orderComment: 'Комментарий к заказу',
  },
  auth: {
    title: 'Войти в личный кабинет',
    submitButton: 'Войти в личный кабинет',
    newAccountLink: 'Создать аккаунт',
    forgotPasswordLink: 'Забыли пароль?',
  },
  badge: {
    novelty: 'New',
    hit: 'Хит',
    soon: 'Скоро',
    freeRetouch: 'Ретушь бесплатно',
    photoPurchased: 'Фото куплено',
  },
  status: {
    inventory: {
      new: 'Новый',
      ready: 'Готов к съемке',
      awaiting: 'Ожидает поступления',
    },
    supply: { route: 'В пути', arrived: 'На приёмке', received: 'Принята' },
    order: {
      draft: 'Черновик',
      shipmentPending: 'Выбор товаров',
      shipmentPendingActionTitle: 'Съемка создана, выберите товары для съемки',
      shipmentPendingActionDescription:
        'Съемка успешно создана! Выберите товары для съемки. Если у вас ещё нет товаров, тогда создайте новые товары и отправьте их к нам в студию.',
      needsConfirmation: 'Ожидание подтверждения',
      needsConfirmationActionTitle:
        'Заполните необходимые параметры для подтверждения съемки',
      needsConfirmationActionDescription:
        'Поздравляем, съемка успешно создана! Теперь необходимо выбрать параметры съемки и прикрепить референсы.',
      needsConfirmationActionButton: 'Подтвердить съемку',
      confirmed: 'Отправлено на съемку',
      confirmedActionTitle: 'Отправлено на съемку',
      confirmedActionDescription:
        'Товары отправлены на съемку, в скором времени вы увидите результат нашей работы в разделе "Результаты съемки"',
      shootInProgress: 'Идет съемка',
      shootInProgressActionTitle: 'Отправлено на съемку',
      shootInProgressActionDescription:
        'Товары отправлены на съемку, в скором времени вы увидите результат нашей работы в разделе "Результаты съемки"',
      shootProcessing: 'Съемка завершена',
      shootProcessingActionTitle: 'Отправлено на съемку',
      shootProcessingActionDescription:
        'Товары отправлены на съемку, в скором времени вы увидите результат нашей работы в разделе "Результаты съемки"',
      readyForRetouch: 'Выбор фотографий',
      readyForRetouchActionTitle: 'Выберите фото для покупки и ретуширования',
      readyForRetouchActionDescription:
        'Поздравляем, съемка успешно завершена! Выберите и закажите понравившиеся фотографии в разделе "Результаты съемки".',
      readyForRetouchActionButton: 'Выбрать фото',
      retouchOrderCreated: 'Ретуширование',
      retouchOrderCreatedActionTitle:
        'Выберите фото для покупки и ретуширования',
      retouchOrderCreatedActionDescription:
        'Поздравляем, съемка успешно завершена! Выберите и закажите понравившиеся фотографии в разделе "Галерея".',
      retouchOrderCreatedActionButton: 'Выбрать фото',
      finished: 'Завершен',
      finishedActionTitle: 'Ваш заказ готов!',
      finidhedActionDescription:
        'Заказ успешно выполнен! Вы можете посмотреть результаты ретуши и скачать готовые фото',
    },
    retouch: {
      draft: 'Черновик',
      paid: 'Оформлен',
      inProgress: 'В работе',
      done: 'Готов',
    },
  },
  order: {
    tariffInformation: 'Информация о тарифах',
    enterTheNameAndTaskForOrder: 'Введите название и задание для съемки',
    chooseTypeOrTheme: 'Выберите тип или тему съемки',
    whatIsYourProduct: 'Какой у вас товар?',
    addTitleAndTaskForShoot: 'Напишите название и задание для съемки',
    shootElementsTitle: 'Выберите элементы съемки',
    chooseBackground: 'Выберите цвет фона',
    chooseGender: 'Выберите пол модели',
    chooseProductSubcategory: 'Выберите подкатегорию товара',
    noCategory: 'Нет вашей категории?',
    noCategoryDescription:
      'Впишите в поле необходимую категорию и мы добавим ее в ближайшее время',
    postComment: 'Постобработка и ретушь включены в общую стоимость',
    successCreating: 'Съемка успешно создана',
    errorCreating: 'Что-то пошло не так',
    successCreatingDescription:
      'Добавьте товары к вашей съемке и подтвердите её для продолжения',
    errorCreatingDescription:
      'Не расстраивайтесь, ваш заказ будет зарезервирован какое-то время в личном кабинете',
    selectedItems: 'Выбранных фото на',
    orderCheckout: 'Оформить заказ',
    addRetouchVariant: 'Добавить вариант ретуши',
    addTaskForAdditionalRetouch:
      'Добавьте еще одно задание на дополнительную ретушь',
    choosePhotoForRetouchOrder: 'Выберите фото для заказа на ретушь',
    basicRetouchInPrice: 'Базовая ретушь (входит в стоимость покупки)',
    additionalRetouchOrder: 'Заказ на дополнительную ретушь',
    retouchOrder: 'Заказ на ретушь',
    chooseRetouchParams: 'Выберите параметры ретуши',
    creatingRetouchOrder: 'Оформление заказа на ретушь',
    choosePhotoForOrderCreating: 'Выберите фото для оформления заказа',
    shootResult: 'Результаты съемки',
    selectMoreItemsForDiscount:
      'Выберите еще {{moreCount}} фото чтобы получить скидку',
    shootingCreated: 'Съемка создана {{date}}',
    shootingType: 'Тип съемки',
    shootingOptions: 'Параметры Съемки',
    onlinePresenceWhenShooting: 'Онлайн присутствие при съемке',
    chooseTheRightAnglesWhenShooting:
      'Выбирайте подходящие ракурсы во время проведения съемки',
    loginToOnlineChat: 'Войти в онлайн-чат',
    goToOrder: 'Перейти в съемку',
    orderCost: 'Стоимость заказа',
    photosPerOrder: 'В заказе {{photosCount}} фото',
    whatIsIncludedInRetouching: 'Что входит в ретушь?',
    creatingOrder: 'Оформление заказа',
  },
  dashboard: {
    noOrders: 'Пока вы не создали ни одной съемки',
    createOrder: 'Создать съемку',
    noProducts: 'Пока вы не добавили ни одного товара',
    noFindProducts: 'По запросу "{{search}}" не найдено ни одного товара',
    addProduct: 'Добавить товар',
    shootParticipation: 'Участие в съемках',
    newOrder: 'Новая съемка',
    retouchOrders: 'Заказы на ретушь',
    noRetouches: 'Пока у вас нет заказов на ретушь',
    noSupplies: 'Пока у вас нет активных посылок',
    addSupply: 'Добавить посылку',
    products: 'Товаров',
    tips: {
      1: {
        title: 'Управляйте съемкой в реальном времени!',
        content:
          'Вы можете увидеть снимки в процессе съемки, попросить внести коррективы или утвердить их на месте.',
        button: 'Заказать онлайн съемку',
      },
      2: {
        title: 'Все преимущества на одном фото!',
        content: 'Сделаем инфографику и выделим преимущества вашего товара',
        benefit: 'Поможет увеличить ваши продажи',
        button: 'Заказать инфографику',
      },
    },
    welcome: 'Добро пожаловать',
  },
  forgot: {
    success: 'Сообщение отправлено на вашу почту',
    passwordRecovery: 'Восстановление пароля',
    description:
      'Введите ваш email и мы отправим письмо с инструкциями по восстановлению',
    comeIn: 'Войти',
  },
  needAuth: {
    titleLogin: 'Вход в учетную запись',
    title: 'Необходимо войти в систему',
    description:
      'Чтобы продолжить авторизуйтесь в системе или создайте аккаунт',
  },
  register: {
    itIsFastAndFree: 'Это быстро и бесплатно',
    emailComment: 'Отправим пароль на этот email',
  },
  verifyForgot: {
    success: 'Пароль успешно изменен',
    passwordChanging: 'Смена пароля',
  },
  verify: { registrationVerification: 'Подтверждение регистрации' },
  supply: {
    emptyItemsModal: 'Выберите товары для добавления в посылку',
    newSupplyModalTitle: 'Создание новой посылки',
    editSupplyModalTitle: 'Редактирование посылки',
  },
  product: {
    newProductModalTitle: 'Создание нового товара',
    editProductModalTitle: 'Редактирование товара',
  },
  error: {
    required: 'Необходимое поле',
    email: 'Невалидный email',
    password: {
      notBeCommonlyUsed: 'Не должен относиться к часто используемым',
    },
    existedBarcode: 'Данный штрихкод уже используется у другого товара',
    shootСonfirmation: {
      1000: 'Невозможно подтвердить съемку',
      1001: 'Недостаточно товаров на складе для подтверждения съемки',
      1002: 'К съемке не привязано ни одного товара',
    },
  },
  settings: { companyName: 'ООО "Темполайн Логистика"' },
  notifications: { orderSuccessfullyCreated: 'Заказ успешно создан' },
  breadcrumb: { home: 'Главная' },
}
