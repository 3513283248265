import { useState, useEffect } from 'react'
import { HexColorPicker } from 'react-colorful'
import cx from 'classnames'

import parentStyles from '../retouch-color-filter-preview.module.scss'
import styles from './retouch-color-filter-picker.module.scss'

interface RetouchColorFilterPickerProps {
  color: string | undefined
  setColor: (color: string) => void
}

const RetouchColorFilterPicker = (props: RetouchColorFilterPickerProps) => {
  const { color, setColor } = props

  const [inputValue, setInputValue] = useState('')
  const [invalidHex, setInvalidHex] = useState(false)

  useEffect(() => {
    if (color) {
      setInvalidHex(false)
    }

    setInputValue(color || '')
  }, [color])

  const handleChange = (value: string) => {
    setInputValue(value)

    const isFull = value.length > 6
    if (!isFull) {
      setInvalidHex(false)
      return false
    }

    const isValid = /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(value)
    if (isValid) {
      setColor(value)
    }

    setInvalidHex(!isValid)
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
  }

  return (
    <div
      className={cx(parentStyles.wrapper, styles.wrapper)}
      onClick={handleClick}
    >
      <div className={cx(parentStyles.inner, styles.inner)}>
        <HexColorPicker color={color} onChange={setColor} />

        <input
          className={cx(styles.input, invalidHex && styles.invalid)}
          value={inputValue}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </div>
  )
}

export default RetouchColorFilterPicker
