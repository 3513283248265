import { useTranslation } from 'react-i18next'

import { Input } from 'src/components'

interface InputSearchProps {
  className?: string
  placeholder?: string
}

const InputSearch = (props: InputSearchProps) => {
  const { t } = useTranslation()

  const {
    className,
    placeholder = t('placeholder.enterNameOrBarcodeOfProduct'),
  } = props

  return (
    <Input
      className={className}
      name="search"
      icon="Magnifier"
      placeholder={placeholder}
    />
  )
}

export default InputSearch
