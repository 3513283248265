import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Icons, Spinner } from 'src/components'

import { useLogout } from 'src/hooks'

import styles from './user-block-menu.module.scss'

interface UserBlockMenuProps {
  isMenuOpen: boolean
  setIsMenuOpen: (value: boolean) => void
}

const UserBlockMenu = (props: UserBlockMenuProps) => {
  const { isMenuOpen, setIsMenuOpen } = props

  const { t } = useTranslation()

  const logoutMutation = useLogout()

  useEffect(() => {
    const clickEventHandler = (event: MouseEvent | TouchEvent) => {
      const specifiedElement = document.getElementById('userBlockMenu')
      if (specifiedElement && event.target instanceof Node) {
        const isClickInside = specifiedElement.contains(event.target)
        if (!isClickInside) {
          setIsMenuOpen(false)
        }
      }
    }

    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsMenuOpen(false)
      }
    }

    window.addEventListener('mousedown', clickEventHandler, false)
    window.addEventListener('touchstart', clickEventHandler, false)
    window.addEventListener('keydown', keyDownEventHandler, false)

    return () => {
      window.removeEventListener('mousedown', clickEventHandler, false)
      window.removeEventListener('touchstart', clickEventHandler, false)
      window.removeEventListener('keydown', keyDownEventHandler, false)
    }
  }, [])

  const handleLogout = async () => {
    if (!logoutMutation.isLoading) {
      await logoutMutation
        .mutateAsync()
        .then(({ data }) => {
          document.location.href = '/'
        })
        .catch((err) => {
          console.log('CATCH ERROR')
          console.log(err)
        })
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.menuArrow}>
        <Icons.Arrow />
      </div>

      <div
        className={cx(styles.menu, isMenuOpen && styles.open)}
        id="userBlockMenu"
      >
        <div onClick={handleLogout}>
          {!logoutMutation.isLoading ? (
            <span>{t('common.logout')}</span>
          ) : (
            <div className={styles.spinnerWrapper}>
              <Spinner color="secondary" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserBlockMenu
