import { Badge } from 'src/components'

import { calculateOrderItemDiscount } from 'src/helpers'

import styles from './retouch-gallery-badges.module.scss'

import { IPackage, IPhoto } from 'src/interfaces'

interface RetouchGalleryBadgesProps {
  photo: IPhoto
  currentPackage: IPackage | null
  discountIsAvailable: boolean
}

const RetouchGalleryBadges = (props: RetouchGalleryBadgesProps) => {
  const { photo, currentPackage, discountIsAvailable } = props

  return (
    <div className={styles.wrapper}>
      {!photo.was_bought ? (
        <>
          {currentPackage?.additional_photo_price && discountIsAvailable ? (
            <Badge template="success">{`-${calculateOrderItemDiscount(
              currentPackage,
            ).toFixed(0)}%`}</Badge>
          ) : null}

          <Badge template="success">{`${
            !discountIsAvailable
              ? currentPackage?.price
              : currentPackage?.additional_photo_price
          }₽`}</Badge>
        </>
      ) : (
        <Badge>{`${currentPackage?.additional_retouche_price}₽`}</Badge>
      )}
    </div>
  )
}

export default RetouchGalleryBadges
