import { useMutation } from 'react-query'
import { api } from 'src/api'

import { ICreateShootData } from 'src/interfaces'

interface IConfirmShootParams {
  id: number
}

const useConfirmShoot = (params: IConfirmShootParams) => {
  const { id } = params
  return useMutation(async (data: ICreateShootData) =>
    api.post(`/api/v1/shoots/${id}/confirm/`, data),
  )
}

export default useConfirmShoot
