import { useRoleQuery } from 'src/hooks'

interface GetUserByIdParams {
  userId: string
}

const useGetUserById = (params: GetUserByIdParams) => {
  const { userId } = params

  return useRoleQuery({
    queryKeys: [`getUserById`, userId],
    url: `/api/v1/user/${userId}/`,
  })
}

export default useGetUserById
