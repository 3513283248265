import { ORDER_STATUSES } from 'src/config'

import {
  IPackage,
  IShoot,
  IOrderStatus,
  IRetouchImage,
  IInventoryWithPhotos,
  IPhoto,
} from 'src/interfaces'

export const isOrderStatusPassed = (
  boundStatus: IOrderStatus,
  currentStatus?: IOrderStatus,
) => {
  if (!currentStatus) {
    return false
  }

  const currentStatusIndex = Object.keys(ORDER_STATUSES).findIndex(
    (status) => status === currentStatus,
  )
  const boundStatusIndex = Object.keys(ORDER_STATUSES).findIndex(
    (status) => status === boundStatus,
  )

  return currentStatusIndex >= boundStatusIndex
}

export const calculateOrderItemDiscount = (currentPackage: IPackage | null) =>
  currentPackage
    ? ((currentPackage?.price - (currentPackage?.additional_photo_price ?? 0)) /
        currentPackage?.price) *
      100
    : 0

export const calculateOrderTotalForPhotosArray = (data: {
  photos: IPhoto[]
  checkedPhotosIds: number[]
  currentPackage: IPackage | null
}) => {
  const { photos, checkedPhotosIds, currentPackage } = data

  const checkedPhotos = photos.filter((photo) =>
    checkedPhotosIds.includes(photo.id),
  )

  const checkedPhotosForProductRetouch = checkedPhotos.filter(
    (photo) => !photo.was_bought,
  )

  const discountIsAvailable =
    checkedPhotosForProductRetouch.length >= (currentPackage?.photo_count ?? 0)

  const basicProductTotalWithoutDiscount =
    checkedPhotosForProductRetouch.length * (currentPackage?.price ?? 0)
  const basicProductTotalWithDiscount =
    checkedPhotosForProductRetouch.length *
    (currentPackage?.[
      discountIsAvailable ? 'additional_photo_price' : 'price'
    ] ?? 0)

  const checkedPhotosForProductExtra = checkedPhotos.filter(
    (photo) => photo.was_bought,
  )
  const extraProductTotal =
    checkedPhotosForProductExtra.length *
    (currentPackage?.additional_retouche_price ?? 0)

  return {
    basicTotalWithoutDiscount: basicProductTotalWithoutDiscount || 0,
    basicTotalWithDiscount: basicProductTotalWithDiscount || 0,
    extraTotal: extraProductTotal || 0,
  }
}

interface ICalculateOrderTotalResult {
  basicTotalWithoutDiscount: number
  basicTotalWithDiscount: number
  extraTotal: number
}

export const calculateOrderTotal = (data: {
  //TODO refactor
  checkedPhotos: IRetouchImage[]
  currentPackage: IPackage | null
  products: IInventoryWithPhotos[]
  freePhotos?: IPhoto[]
}) => {
  const { checkedPhotos, currentPackage, products, freePhotos = [] } = data

  const checkedPhotosIds = checkedPhotos.map((photo) => photo.id)

  return products.reduce(
    (a: ICalculateOrderTotalResult, product: IInventoryWithPhotos) => {
      const productCalculation = calculateOrderTotalForPhotosArray({
        photos: product.photos,
        checkedPhotosIds,
        currentPackage,
      })

      return {
        basicTotalWithoutDiscount:
          a.basicTotalWithoutDiscount +
          productCalculation.basicTotalWithoutDiscount,
        basicTotalWithDiscount:
          a.basicTotalWithDiscount + productCalculation.basicTotalWithDiscount,
        extraTotal: a.extraTotal + productCalculation.extraTotal,
      }
    },
    {
      ...calculateOrderTotalForPhotosArray({
        photos: freePhotos,
        checkedPhotosIds,
        currentPackage,
      }),
    },
  )
}
