import { useMutation } from 'react-query'
import { api } from 'src/api'

import { AppContext } from 'src/context'

const useLogout = () => {
  return useMutation(async () =>
    api.post('/accounts/logout/', { revoke_token: true }),
  )
}

export default useLogout
