import { createElement } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { Spinner, Icons } from 'src/components'

import styles from './basic-button.module.scss'

import ButtonProps from '../ButtonProps'

interface ButtonWrapperProps {
  className?: string
  type?: 'button' | 'submit'
  disabled?: boolean
  to?: string
  toOutside?: string
  onClick?: () => void
}

const WrapperComponent = (
  props: React.PropsWithChildren<ButtonWrapperProps>,
) => {
  const {
    className,
    type,
    disabled,
    to,
    toOutside,
    onClick,
    children,
    ...rest
  } = props
  if (to) {
    return (
      <Link className={className} to={to}>
        {children}
      </Link>
    )
  }

  if (toOutside) {
    return (
      <a
        className={className}
        href={toOutside}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    )
  }

  return (
    <button
      className={className}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
const Button = (props: React.PropsWithChildren<ButtonProps>) => {
  const {
    className,
    type = 'button',
    size = 'normal',
    to,
    toOutside,
    icon,
    iconAfter,
    template,
    loading,
    disabled,
    children,
    onClick = () => false,
  } = props

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  const IconAfterComponent =
    iconAfter &&
    (Object.entries(Icons)?.find(([key, value]) => key === iconAfter)?.[1] ??
      null)

  return (
    <WrapperComponent
      className={cx(
        styles.button,
        template && styles[template],
        size && styles[size],
        className,
      )}
      type={type}
      disabled={disabled || loading}
      to={to}
      toOutside={toOutside}
      onClick={() => onClick()}
    >
      {IconComponent ? (
        <span className={styles.icon}>
          <IconComponent />
        </span>
      ) : null}

      {!loading ? children : <Spinner />}

      {IconAfterComponent ? (
        <span className={styles.iconAfter}>
          <IconAfterComponent />
        </span>
      ) : null}
    </WrapperComponent>
  )
}

export default Button
