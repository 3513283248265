import { useReducer, useContext, createContext } from 'react'

import INITIAL_STATE from './initialState'

const ChatContext = createContext({ ...INITIAL_STATE })

const ChatDispatchContext = createContext({})

const reducer = (
  state: typeof INITIAL_STATE,
  action: { type: string; payload: any },
) => {
  if (action?.type) {
    const replacedKey = action.type.replace('set', '')
    const key = replacedKey.charAt(0).toLowerCase() + replacedKey.slice(1)

    if (key in INITIAL_STATE) {
      return { ...state, [key as keyof typeof INITIAL_STATE]: action.payload }
    }
  }

  return state
}

export const ChatContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const [state, dispatch] = useReducer(reducer, { ...INITIAL_STATE })

  const dispatchProviderValue = Object.keys(INITIAL_STATE).reduce(
    (acc, key: string) => {
      const actionKey = `set${key.charAt(0).toUpperCase() + key.slice(1)}`
      acc[actionKey] = (value: typeof key) =>
        dispatch({ type: actionKey, payload: value })
      return acc
    },
    {} as { [key: string]: (value: any) => void },
  )

  return (
    <ChatContext.Provider value={state}>
      <ChatDispatchContext.Provider value={dispatchProviderValue}>
        {children}
      </ChatDispatchContext.Provider>
    </ChatContext.Provider>
  )
}

export const useChatContext = () => {
  const context = useContext(ChatContext)
  const dispatch = useContext(ChatDispatchContext)

  return {
    ...(context || {}),
    ...(dispatch || {}),
  } as typeof INITIAL_STATE & { [key: string]: (value: any) => void }
}
