import { BasicBlock, EmptyBlock, Spinner } from 'src/components'

import styles from './basic-items-block.module.scss'

import { IBasicBlock, IEmptyConfig } from 'src/components/Block/interfaces'

interface BasicItemsBlockProps extends IBasicBlock {
  isLoading?: boolean
  emptyConfig?: IEmptyConfig
}

const BasicItemsBlock = (
  props: React.PropsWithChildren<BasicItemsBlockProps>,
) => {
  const { isLoading, emptyConfig, children, ...rest } = props

  const isEmpty = !children || (Array.isArray(children) && !children?.length)

  return (
    <BasicBlock {...rest}>
      {isLoading ? (
        <div className={styles.spinnerWrapper}>
          <Spinner color="secondary" size="middle" />
        </div>
      ) : !isEmpty ? (
        children
      ) : emptyConfig ? (
        <EmptyBlock {...emptyConfig} />
      ) : null}
    </BasicBlock>
  )
}

export default BasicItemsBlock
