import { useTranslation } from 'react-i18next'

import { Checkbox, Icons } from 'src/components'

import styles from './single-order-options.module.scss'

const SingleOrderOptions = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('order.shootingOptions')}</div>

      <div className={styles.online}>
        <div className={styles.icon}>
          <Icons.CloudCheck />
        </div>

        <div>
          <div className={styles.titleS}>
            {t('order.onlinePresenceWhenShooting')}
          </div>
          <div className={styles.description}>
            {t('order.chooseTheRightAnglesWhenShooting')}
          </div>
        </div>

        <Checkbox className={styles.checkbox} disabled value={true} />
      </div>
    </div>
  )
}

export default SingleOrderOptions
