import { useEffect } from 'react'

import { useChatContext } from 'src/pages/ChatPage/chatContext'

import ChatMessagesItemContextMenuItem from './ChatMessagesItemContextMenuItem'

import styles from './chat-messages-item-context-menu.module.scss'

interface ChatMessagesItemContextMenuProps {
  messageId: string
  onClose: () => void
}

const ID = 'chatMessagesItemContextMenu' //TODO mb refactor

const ChatMessagesItemContextMenu = (
  props: ChatMessagesItemContextMenuProps,
) => {
  const { messageId, onClose } = props

  const { messages, setMessages, setMessageIdToReply } = useChatContext()

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    const clickEventHandler = (event: MouseEvent | TouchEvent) => {
      const specifiedElement = document.getElementById(ID)
      if (specifiedElement && event.target instanceof Node) {
        const isClickInside = specifiedElement.contains(event.target)
        if (!isClickInside) {
          handleClose()
        }
      }
    }

    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    window.addEventListener('mousedown', clickEventHandler, false)
    window.addEventListener('touchstart', clickEventHandler, false)
    window.addEventListener('keydown', keyDownEventHandler, false)

    return () => {
      window.removeEventListener('mousedown', clickEventHandler, false)
      window.removeEventListener('touchstart', clickEventHandler, false)
      window.removeEventListener('keydown', keyDownEventHandler, false)
    }
  }, [onClose])

  const handleClick = async (
    action: (data: any) => void | Promise<any>,
    data: any,
  ) => {
    handleClose()
    await action(data)
  }

  const menuItems = [
    {
      icon: 'Reply',
      label: 'Комментировать',
      onClick: (messageId: string) => {
        setMessageIdToReply(messageId)
      },
    },
    {
      icon: 'Trash',
      label: 'Удалить',
      template: 'critical',
      onClick: (messageId: string) => {
        const newMessages = messages.filter(
          (message) => message.id !== messageId,
        )
        setMessages(newMessages)
      },
    },
  ]

  return (
    <div className={styles.wrapper} id={ID}>
      {menuItems.map((item, index) => (
        <ChatMessagesItemContextMenuItem
          key={index}
          {...item}
          onClick={() => handleClick(item.onClick, messageId)}
        />
      ))}
    </div>
  )
}

export default ChatMessagesItemContextMenu
