import { Modal } from 'src/components'

import styles from './modal-image.module.scss'

import { IModal } from 'src/interfaces'

interface PhotoStepProductModalProps extends IModal {
  src?: string
}

const ModalImage = (props: PhotoStepProductModalProps) => {
  const { src, onNavigate, onClose } = props

  return (
    <Modal
      id="fullSizeImageModal"
      template="big"
      showCloseButton
      onNavigate={onNavigate}
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        {src ? <img className={styles.fullSizeImage} src={src} /> : null}
      </div>
    </Modal>
  )
}

export default ModalImage
