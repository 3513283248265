import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { BasicPageHeader } from 'src/components'

import SingleOrderHeaderTitle from './SingleOrderHeaderTitle'

import styles from './single-order-header.module.scss'

import { IOrder } from 'src/interfaces'

const SingleOrderHeader = (props: IOrder) => {
  const { created_at, shoots, comment } = props

  const { t, i18n } = useTranslation()

  return (
    <>
      <BasicPageHeader
        title={<SingleOrderHeaderTitle {...props} />}
        description={t('order.shootingCreated', {
          date: dayjs(created_at).locale(i18n.language).format('DD MMMM hh:mm'),
        })}
      />

      {shoots[0] ? (
        <div className={styles.shootId}>{`ID${shoots[0].id}`}</div>
      ) : null}

      {comment ? <div className={styles.comment}>{comment}</div> : null}
    </>
  )
}

export default SingleOrderHeader
