export default {
  common: {
    user: 'User',
    backToPersonalAccount: 'Back to dashboard',
    package: 'Package',
    itemShort: 'pcs.',
    apiece: 'For single',
    background: 'Background',
    choose: 'pick',
    noChoice: 'None',
    model: 'Model',
    shortDescription: 'Short description',
    send: 'Send',
    edit: 'Edit',
    makeReservation: 'Create shooting',
    back: 'Back',
    proceed: 'Next',
    shootCreating: 'Create a shoot',
    order: 'Shooting',
    orders: 'Shootings',
    barcode: 'Barcode',
    articleShort: 'Code',
    article: 'Vendor code',
    amountShort: 'Qty',
    inventory: 'Inventory',
    downloadOrder: 'Download',
    supply: 'Shipment',
    supplies: 'Shipments',
    deliveryService: 'Courier service',
    unknown: '–',
    registration: 'New user',
    comeIn: 'Login',
    create: 'Create',
    createAccount: 'Create',
    registerAccepting:
      'I confirm that I agree to the processing of personal data and accept the user agreement and terms of service.',
    alreadyHasAccount: 'Already have an account?',
    category: 'Category',
    product: 'Product',
    product_s: 'Products',
    addPhoto: 'Add photo',
    gallery: 'Gallery',
    details: 'Details',
    retouchOrder: 'Retouching',
    orderDetails: 'Order details',
    retouch: 'Retouching',
    additionalRetouch: 'Add. retouch',
    add: 'New',
    added: 'Created',
    successRegistration: 'Registration is successful',
    weSentPassword: 'Password was sent to your e-mail',
    close: 'Close',
    supportedFormats: 'Supported formats',
    error: 'Error',
    chooseBackgroundColorForShoot: 'Pick background color',
    shootAtStands: 'Shoots on stages',
    newCompositionCreating: 'New composition',
    newCompositionCreatingDescription:
      'Pick products and desired name for composition',
    composition: 'Composition',
    cancel: 'Cancel',
    addComposition: 'New composition',
    chooseProductFromInventory: 'Select products from inventory',
    chooseProductFromInventoryDescription: 'Select products from inventory',
    newProductDescription: 'Input product details',
    createProduct: 'New product',
    save: 'Save',
    newSupplyDescription:
      'Specify the composition of the shipment, the courier service and the shipment tracking number',
    editSupply: 'Edit shipment',
    areYouSureAboutCancellation: 'Are you sure you want to cancel?',
    allDataWillBeLost: 'All unsaved changes will be lost',
    productsForShoot: 'Products for shooting',
    newProduct: 'New product',
    addFromInventory: 'Add from inventory',
    logout: 'Logout',
    stepCounter: 'Step {{step}} of {{count}}',
    discount: 'Discount',
    discountReceived: 'Discount received',
    results: 'Results',
    shootingDate: 'Shooting date',
    toOrder: 'Order',
    confirm: 'Confirm',
    from: 'from',
    perPhoto: 'Per photo',
    whenOrderingFrom: 'when ordering from {{count}} pcs.',
  },
  label: {
    email: 'Email',
    password: 'Password',
    phone: 'Phone number',
    framing: 'Crop',
    alignment: 'Alignment',
    backgroundColor: 'Background color',
    newPassword: 'Enter new password',
    productName: 'Product name',
    barcodeAndArticle: 'Barcode and vendor code',
    enterShootComment: 'Enter task and purpose of shooting',
    shootComment: 'Task and purpose of shooting',
    customColor: 'Custom color',
    shootTitle: 'Enter the name of the shooting',
    shootingReferences: 'Shooting references',
  },
  'Enter shooting title': 'Shooting title',
  placeholder: {
    categoryTitle: 'Category title',
    yourEmail: 'Your e-mail',
    enterPassword: 'Enter password',
    enterNameOrBarcodeOfProduct: 'Enter the product name or barcode',
    productName: 'Enter the product name',
    barcode: 'Enter barcode',
    article: 'Enter vendor code',
    trackNumber: 'Tracking number',
    insertLinkToImagesOrProduct: 'Insert a link to images or a product',
    orderComment: 'Order comment',
  },
  auth: {
    title: 'Account login',
    submitButton: 'Login',
    newAccountLink: 'New account',
    forgotPasswordLink: 'Forgot your password?',
  },
  badge: {
    novelty: 'New',
    hit: 'Hit',
    soon: 'Soon',
    freeRetouch: 'Retouch for free',
    photoPurchased: 'Photo purchased',
  },
  status: {
    inventory: {
      new: 'New',
      ready: 'Ready for shooting',
      awaiting: 'Awaiting shipment',
    },
    supply: { route: 'On the way', arrived: 'Processing', received: 'Done' },
    order: {
      draft: 'Draft',
      shipmentPending: 'Inventory selection',
      shipmentPendingActionTitle:
        'Shooting created, select products for shooting',
      shipmentPendingActionDescription:
        "Shooting successfully created! Select items to shoot. If you don't have any products yet, then create new products and send them to our studio.",
      needsConfirmation: 'Awaiting confirmation',
      needsConfirmationActionTitle:
        'Fill in the required parameters to confirm the shooting',
      needsConfirmationActionDescription:
        'Congratulations, the shoot has been successfully created! Now you need to select shooting parameters and attach references.',
      needsConfirmationActionButton: 'Confirm shooting',
      confirmed: 'Sent to shooting',
      confirmedActionTitle: 'Shooting is in progress',
      confirmedActionDescription:
        'The goods have been sent for shooting, soon you will see the result of our work in the "Shooting Results" section',
      shootInProgress: 'Shooting in progress',
      shootInProgressActionTitle: 'Shooting is in progress',
      shootInProgressActionDescription:
        'The goods have been sent for shooting, soon you will see the result of our work in the "Shooting Results" section',
      shootProcessing: 'Shooting is finished',
      shootProcessingActionTitle: 'Shooting is in progress',
      shootProcessingActionDescription:
        'The goods have been sent for shooting, soon you will see the result of our work in the "Shooting Results" section',
      readyForRetouch: 'Checkout of photos',
      readyForRetouchActionTitle: 'Select a photo to buy and retouch',
      readyForRetouchActionDescription:
        'Congratulations, the shooting has been successfully completed! Select and order your favorite photos in the "Shooting Results" section.',
      readyForRetouchActionButton: 'Pick photos',
      retouchOrderCreated: 'Retouching',
      retouchOrderCreatedActionTitle: 'Select a photo to buy and retouch',
      retouchOrderCreatedActionDescription:
        'Shooting completed! Choose and order your favorite photos in the "Gallery" section',
      retouchOrderCreatedActionButton: 'Pick photos',
      finished: 'Finished',
      finishedActionTitle: 'Your order is ready!',
      finidhedActionDescription:
        'Order completed successfully! You can see the results of retouching and download finished photos.',
    },
    retouch: {
      draft: 'Draft',
      paid: 'Ready for processing',
      inProgress: 'Processing',
      done: 'Done',
    },
  },
  order: {
    tariffInformation: 'Tariff Information',
    enterTheNameAndTaskForOrder: 'Enter the name and task for shooting',
    chooseTypeOrTheme: 'Choose the type or theme of your shoot',
    whatIsYourProduct: 'What kind of product do you have?',
    addTitleAndTaskForShoot: 'Add the title and task for shooting',
    shootElementsTitle: 'Pick shooting elements',
    chooseBackground: 'Select background color',
    chooseGender: 'Select model gender',
    chooseProductSubcategory: 'Select product subcategory',
    noCategory: "Didn't find you category?",
    noCategoryDescription:
      'Enter the desired category in the field and we will add it shortly',
    postComment:
      'Post-processing and retouching are included in the total price',
    successCreating: 'Shooting is created successfully',
    errorCreating: 'Whoops, something is going wrong...',
    successCreatingDescription:
      'Add products to your shoot and confirm to continue',
    errorCreatingDescription:
      "Don't worry, your order will be reserved for a while in your personal cabinet",
    selectedItems: 'Cart amount:',
    orderCheckout: 'Place order',
    addRetouchVariant: 'Add new retouch task',
    addTaskForAdditionalRetouch: 'Add another task for additional retouching',
    choosePhotoForRetouchOrder: 'Select photos to order for retouching',
    basicRetouchInPrice: 'Basic retouching (included in the purchase price)',
    additionalRetouchOrder: 'Order for additional retouching',
    retouchOrder: 'Order retouching',
    chooseRetouchParams: 'Select retouch options',
    creatingRetouchOrder: 'Placing an order for retouching',
    choosePhotoForOrderCreating: 'Select photos to place an order',
    shootResult: 'Shooting results',
    selectMoreItemsForDiscount:
      'Choose {{moreCount}} more photos to get a discount',
    shootingCreated: 'Shooting created {{date}}',
    shootingType: 'Shooting type',
    shootingOptions: 'Shooting Options',
    onlinePresenceWhenShooting: 'Online presence when shooting',
    chooseTheRightAnglesWhenShooting: 'Choose the right angles when shooting',
    loginToOnlineChat: 'Login to online chat',
    goToOrder: 'Go to order',
    orderCost: 'Order cost',
    photosPerOrder: '{{photosCount}} photos per order',
    whatIsIncludedInRetouching: 'What is included in retouching?',
    creatingOrder: 'Placing an order',
  },
  dashboard: {
    noOrders: 'You have no shootings yet',
    createOrder: 'New shooting',
    noProducts: 'Please add new products',
    noFindProducts: 'No products found for "{{search}}"',
    addProduct: 'New product',
    shootParticipation: 'Shooting participation',
    newOrder: 'New shooting',
    retouchOrders: 'Retouch orders',
    noRetouches: 'You do not have any orders yet',
    noSupplies: 'You do not have any shipments yet',
    addSupply: 'New shipment',
    products: 'Products',
    tips: {
      1: {
        title: 'Take control of your live shooting!',
        content:
          'You can view the shots as they are being shot, ask for corrections, or have them approved on site.',
        button: 'Order online shooting',
      },
      2: {
        title: 'All benefits in one photo!',
        content:
          'We will make infographics and highlight the benefits of your product',
        benefit: 'Help increase your sales',
        button: 'Order infographic',
      },
    },
    welcome: 'Welcome',
  },
  forgot: {
    success: 'Message was sent to your email',
    passwordRecovery: 'Password recovery',
    description:
      'Enter your email and we will send you an email with recovery instructions',
    comeIn: 'Login',
  },
  needAuth: {
    titleLogin: 'Account login',
    title: 'You need to be logged in',
    description: 'Login or register an account to continue',
  },
  register: {
    itIsFastAndFree: "It's fast and free",
    emailComment: 'We will send the password to this email',
  },
  verifyForgot: {
    success: 'Your password is successfully changed',
    passwordChanging: 'Password changing',
  },
  verify: { registrationVerification: 'Registration verification' },
  supply: {
    emptyItemsModal: 'Select items to add to package',
    newSupplyModalTitle: 'Create a new package',
    editSupplyModalTitle: 'Editing a package',
  },
  product: {
    newProductModalTitle: 'Creating a new product',
    editProductModalTitle: 'Product editing',
  },
  error: {
    required: 'Required',
    email: 'Invalid e-mail',
    password: { notBeCommonlyUsed: 'Should not be used frequently' },
    existedBarcode: 'This barcode is already used on another product',
    shootСonfirmation: {
      1000: 'Unable to confirm shooting',
      1001: 'Not enough products in stock to confirm shooting',
      1002: 'There are no products linked to the photo.',
    },
  },
  settings: { companyName: 'OOO "Tempoline"' },
  notifications: { orderSuccessfullyCreated: 'Order successfully created' },
  breadcrumb: { home: 'Home' },
}
