import { Icons } from 'src/components'

import styles from './retouch-not-draft-left-panel-detail.module.scss'

interface RetouchNotDraftLeftPanelDetailProps {
  title: string
  value: string
  icon: string
}

const RetouchNotDraftLeftPanelDetail = (
  props: RetouchNotDraftLeftPanelDetailProps,
) => {
  const { title, value, icon } = props

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  return (
    <div className={styles.wrapper}>
      {IconComponent ? (
        <div className={styles.icon}>
          <IconComponent />
        </div>
      ) : null}

      <div className={styles.inner}>
        <div className={styles.title}>{title}</div>

        <div className={styles.value}>{value}</div>
      </div>
    </div>
  )
}

export default RetouchNotDraftLeftPanelDetail
