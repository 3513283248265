import { useRef, useEffect, useContext } from 'react'

import { useChatContext } from 'src/pages/ChatPage/chatContext'

import ChatMessagesItem from './ChatMessagesItem'

import styles from './chat-messages.module.scss'

import { IChatMessage } from 'src/interfaces'

const MESSAGES_MOCK: IChatMessage[] = [
  {
    id: '1',
    message: 'Привет! Как дела?',
    timestamp: new Date('2023-04-13T12:00:00Z').toISOString(),
    author: {
      id: 'anna',
      name: 'Анна Радченко',
    },
  },
  {
    id: '2',
    message: 'Все хорошо, спасибо! Как ты?',
    timestamp: new Date('2023-04-13T12:05:00Z').toISOString(),
    author: {
      id: 'john',
      name: 'Джон Смит',
      isUser: true,
    },
  },
  {
    id: '3',
    message: 'У меня тоже все ок, спасибо, что спросил',
    timestamp: new Date('2023-04-13T12:10:00Z').toISOString(),
    author: {
      id: 'anna',
      name: 'Анна Радченко',
    },
  },
  {
    id: '4',
    message: 'Как проходит работа?',
    timestamp: new Date('2023-04-13T12:15:00Z').toISOString(),
    author: {
      id: 'john',
      name: 'Джон Смит',
      isUser: true,
    },
  },
  {
    id: '5',
    message: 'Да, неплохо, но много работы как всегда',
    timestamp: new Date('2023-04-13T12:20:00Z').toISOString(),
    author: {
      id: 'anna',
      name: 'Анна Радченко',
    },
  },
  {
    id: '6',
    message: 'Планируешь что-нибудь на выходные?',
    timestamp: new Date('2023-04-13T12:25:00Z').toISOString(),
    author: {
      id: 'john',
      name: 'Джон Смит',
      isUser: true,
    },
  },
  {
    id: '7',
    message: 'Да, мы с друзьями собираемся в парк, ты хочешь присоединиться?',
    timestamp: new Date('2023-04-13T12:30:00Z').toISOString(),
    author: {
      id: 'anna',
      name: 'Анна Радченко',
    },
  },
  {
    id: '8',
    message:
      'К сожалению, у меня уже есть планы на выходные, может в следующий раз',
    timestamp: new Date('2023-04-13T12:35:00Z').toISOString(),
    author: {
      id: 'john',
      name: 'Джон Смит',
      isUser: true,
    },
  },
  {
    id: '9',
    image: {
      imageUrl: '/img/mock-photos/boots/2.png',
    },
    timestamp: new Date('2023-04-13T12:45:00Z').toISOString(),
    author: {
      id: 'anna',
      name: 'Анна Радченко',
    },
  },
]

const ChatMessages = () => {
  const { messages, setMessages, messageIdToScrollTo, setMessageIdToScrollTo } =
    useChatContext()

  const messagesRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    const messagesContainer = messagesRef.current
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight
    }
  }

  useEffect(() => {
    //scrollToBottom()

    setMessages([...MESSAGES_MOCK])
  }, [])

  useEffect(() => {
    if (messageIdToScrollTo) {
      const $message = document.getElementById(`message-${messageIdToScrollTo}`)
      if ($message) {
        $message.scrollIntoView()

        setMessageIdToScrollTo(null)

        return
      }
    }

    const lastMessage = messages[messages.length - 1]
    if (lastMessage?.image) {
      const $lastMessage = document.getElementById(`message-${lastMessage.id}`)
      if ($lastMessage) {
        $lastMessage.querySelector('img')?.addEventListener('load', () => {
          scrollToBottom()
        })

        return
      }
    }

    scrollToBottom()
  }, [messages, messageIdToScrollTo])

  console.log('messages', messages)

  return (
    <div className={styles.wrapper} ref={messagesRef}>
      <div className={styles.inner}>
        {messages.map((message: IChatMessage, index) => (
          <ChatMessagesItem
            key={message.id}
            isLastInGroup={messages[index + 1]?.author.id !== message.author.id}
            {...message}
          />
        ))}
      </div>
    </div>
  )
}

export default ChatMessages
