import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Title, Button, Illustrations, Icons } from 'src/components'

import styles from './modal.module.scss'

interface ModalProps {
  className?: string
  id: string
  headerIllustration?: string
  headerIllustrationAlign?: string
  headerImage?: string
  title?: string
  titleAlign?: string
  subtitle?: string
  description?: string
  template?: string
  showCloseButton?: boolean
  preventClose?: boolean
  noHeader?: boolean
  onNavigate?: (direction: string) => void
  onClose?: () => void
}

const Modal = (props: React.PropsWithChildren<ModalProps>) => {
  const {
    className,
    id,
    headerIllustration,
    headerIllustrationAlign,
    headerImage,
    title,
    titleAlign,
    subtitle,
    description,
    template = 'normal',
    showCloseButton,
    preventClose,
    noHeader,
    children,
    onNavigate,
    onClose = () => false,
  } = props

  const { t } = useTranslation()

  const [showPreventModal, setShowPreventModal] = useState<boolean>(false)

  const handleClose = () => {
    if (!preventClose) {
      onClose()
    } else {
      setShowPreventModal(true)
    }
  }

  useEffect(() => {
    const clickEventHandler = (event: MouseEvent | TouchEvent) => {
      const specifiedElement = document.getElementById(id)
      if (specifiedElement && event.target instanceof Node) {
        const isClickInside = specifiedElement.contains(event.target)
        if (!isClickInside) {
          handleClose()
        }
      }
    }

    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    window.addEventListener('mousedown', clickEventHandler, false)
    window.addEventListener('touchstart', clickEventHandler, false)
    window.addEventListener('keydown', keyDownEventHandler, false)

    return () => {
      window.removeEventListener('mousedown', clickEventHandler, false)
      window.removeEventListener('touchstart', clickEventHandler, false)
      window.removeEventListener('keydown', keyDownEventHandler, false)
    }
  }, [id, onClose])

  const HeaderIllustrationComponent =
    headerIllustration &&
    (Object.entries(Illustrations)?.find(
      ([key, value]) => key === headerIllustration,
    )?.[1] ??
      null)

  return (
    <div className={styles.wrapper}>
      <div id={id} className={cx(styles.inner, styles[template], className)}>
        {preventClose && showPreventModal ? (
          <div className={styles.preventModal}>
            <div className={styles.icon}>
              <Illustrations.Warning />
            </div>

            <div className={styles.title}>
              {t('common.areYouSureAboutCancellation')}
            </div>
            <div className={styles.subtitle}>
              {t('common.allDataWillBeLost')}
            </div>

            <div className={styles.buttons}>
              <Button onClick={() => setShowPreventModal(false)}>
                {t('common.back')}
              </Button>
              <Button template="ghost" onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        ) : null}

        {onNavigate ? (
          <div className={styles.navigation}>
            <div className={styles.prev} onClick={() => onNavigate('prev')}>
              <Icons.Arrow />
            </div>

            <div className={styles.next} onClick={() => onNavigate('next')}>
              <Icons.Arrow />
            </div>
          </div>
        ) : null}

        {showCloseButton ? (
          <div className={styles.close} onClick={handleClose}>
            <Icons.Close />
          </div>
        ) : null}

        {!noHeader ? (
          <div className={styles.header}>
            {HeaderIllustrationComponent ? (
              <div
                className={cx(
                  styles.illustration,
                  headerIllustrationAlign && styles[headerIllustrationAlign],
                )}
              >
                <HeaderIllustrationComponent />
              </div>
            ) : null}

            {headerImage ? (
              <img className={styles.image} src={headerImage} />
            ) : null}

            {title ? (
              <Title
                className={cx(styles.title, titleAlign && styles[titleAlign])}
              >
                {title}
              </Title>
            ) : null}

            {subtitle ? (
              <Title className={styles.subtitle} type="subtitle">
                {subtitle}
              </Title>
            ) : null}

            {description ? (
              <div className={styles.description}>{description}</div>
            ) : null}
          </div>
        ) : null}
        {children}
      </div>
    </div>
  )
}

export default Modal
