import dayjs from 'dayjs'

import { Icons } from 'src/components'

import styles from './chat-photo-gallery-item.module.scss'

interface ChatPhotoGalleryItemProps {
  id: number
  src: string
  timestamp: string
  onGallery: (src: string) => void
}

const ChatPhotoGalleryItem = (props: ChatPhotoGalleryItemProps) => {
  const { src, timestamp, onGallery } = props

  const handleReply = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: `url(${src})` }}
      onClick={() => onGallery(src)}
    >
      <div className={styles.overlay}>
        <Icons.Zoom />
      </div>

      <div className={styles.time}>
        {dayjs(timestamp).format('D MMM HH:mm')}
      </div>

      <div className={styles.reply} onClick={handleReply}>
        <Icons.Reply />
      </div>
    </div>
  )
}

export default ChatPhotoGalleryItem
