export enum IRetouchStatus {
  DRAFT = 'draft',
  PAID = 'paid',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

export interface IRetouchImage {
  id: number
}
export interface IRetouch {
  id?: number
  order_id?: number
  type: 'basic' | 'extra'
  created_at?: string
  status: IRetouchStatus
  images: IRetouchImage[]
  framing?: string
  align?: string
  background_color?: string
  custom_color?: string
  link?: string | null
  isInitial?: boolean
  comment?: string
}
