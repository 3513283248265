import { useTranslation } from 'react-i18next'

import { Status } from 'src/components'

import { ORDER_STATUSES } from 'src/config'

import { IOrderStatus } from 'src/interfaces'

interface OrderStatusProps {
  className?: string
  type?: 'success'
  status?: IOrderStatus
}

const OrderStatus = (props: OrderStatusProps) => {
  const { status, ...rest } = props

  const { t } = useTranslation()

  const label =
    status && status in ORDER_STATUSES
      ? t(ORDER_STATUSES[status].title)
      : status

  return <Status {...rest}>{label}</Status>
}

export default OrderStatus
