import { useState, useEffect } from 'react'
import { useSearchParams, generatePath } from 'react-router-dom'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { NeedAuthPage } from 'src/pages'

import { BasicTemplate, ResultTemplate } from 'src/templates'

import { BasicPageHeader, Form } from 'src/components'

import * as Steps from './CreateOrderSteps'

import { useAppContext } from 'src/context'

import {
  useGetCategories,
  useGetPackages,
  useCreateOrder,
  useCreateShoot,
} from 'src/hooks'

import { PATHS } from 'src/config'

import {
  ICategory,
  IPackage,
  ICreateShootData,
  CreatePhotosetFields,
} from 'src/interfaces'

const CreateOrderPage = () => {
  const { t } = useTranslation()

  const { authorized } = useAppContext()

  const [searchParams] = useSearchParams()
  const initialPackage = searchParams.get('initial-package')

  const [currentOrderId, setCurrentOrderId] = useState<number | null>(null)

  const [currentStep, setCurrentStep] = useState<number>(initialPackage ? 1 : 0) //TODO refactor for more universal
  const [resultStatus, setResultStatus] = useState<boolean | null>(null)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const categories = useGetCategories({ ordering: 'priority' })
  const packages = useGetPackages()

  const createOrderMutation = useCreateOrder()
  const createPhotosetMutation = useCreateShoot()

  const formik = useFormik<CreatePhotosetFields>({
    initialValues: {
      packages: initialPackage ? [{ id: Number(initialPackage) }] : [], //TODO refactor for more universal
      title: '',
      comment: '',
      inventories: [],
      inventory_groups: [],
    },
    onSubmit: () => {
      setIsSubmitted(true)
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      category_id: yup.number(),
      packages: yup.array().min(1),
    }),
  })

  useEffect(() => {
    ;(async () => {
      if (!authorized || !isSubmitted) return

      const { values } = formik

      const order = await createOrderMutation.mutateAsync({
        title: values.title,
        comment: values.comment,
      })

      const order_id = order.data?.id
      setCurrentOrderId(order_id)

      if (order_id) {
        const promises = []

        for (let photoset of values.packages) {
          const newPhotoset = {
            order_id,
            category_id: values.category_id,
            package_id: photoset.id,
            total: packages.data.find((p: IPackage) => p.id === photoset.id)
              .price,
            package_options: { ...photoset },
            inventories: values.inventories.length
              ? values.inventories
              : undefined,
            inventory_groups: values.inventory_groups.length
              ? values.inventory_groups
              : undefined,
          } as ICreateShootData

          delete newPhotoset.package_options.id

          promises.push(createPhotosetMutation.mutateAsync({ ...newPhotoset }))
        }

        const shootsResult = await Promise.allSettled(promises)

        const hasError =
          shootsResult?.some((r) => r.status === 'rejected') ?? true
        setResultStatus(!hasError ? true : false)
      } else {
        setResultStatus(false)
      }
    })()
  }, [authorized, isSubmitted])

  const currentTitle = t(
    `order.${
      !currentStep ? 'chooseTypeOrTheme' : 'enterTheNameAndTaskForOrder'
    }`,
  )

  const breadcrumbs = [
    {
      title: t('common.shootCreating'),
      to: PATHS.CREATE_ORDER,
      onClick: () => setCurrentStep(0),
    },
    { title: currentTitle },
  ]

  const StepComponent = Object.values(Steps)[currentStep]

  if (resultStatus !== null) {
    return (
      <ResultTemplate
        isSuccess={resultStatus}
        title={t(`order.${resultStatus ? 'success' : 'error'}Creating`)}
        description={t(
          `order.${resultStatus ? 'success' : 'error'}CreatingDescription`,
        )}
        button={
          resultStatus
            ? {
                to: generatePath(PATHS.SINGLE_ORDER, {
                  id: String(currentOrderId),
                }),
                text: t('order.goToOrder'),
              }
            : undefined
        }
      />
    )
  }

  if (!authorized && isSubmitted) {
    return <NeedAuthPage subtitle={t('common.shootCreating')} />
  }

  return (
    <BasicTemplate breadcrumbs={breadcrumbs}>
      <BasicPageHeader
        title={currentTitle}
        subtitle={t('common.shootCreating')}
      />

      <Form providerValue={formik}>
        <StepComponent
          categories={categories.data || []}
          packages={packages.data || []}
          setCurrentStep={setCurrentStep}
        />
      </Form>
    </BasicTemplate>
  )
}

export default CreateOrderPage
