import cx from 'classnames'

import { RetouchGalleryItem } from 'src/components'

import RetouchGalleryBadges from './RetouchGalleryBadges'
import RetouchAddPhoto from './RetouchAddPhoto'

import { calculateOrderTotal } from 'src/helpers'

import styles from './retouch-gallery.module.scss'

import {
  IRetouch,
  IRetouchImage,
  IRetouchStatus,
  IPhoto,
  IInventoryWithPhotos,
  IPackage,
} from 'src/interfaces'

interface RetouchGalleryProps extends IRetouch {
  className?: string
  index: number
  photos: IPhoto[]
  products: IInventoryWithPhotos[]
  freePhotos: IPhoto[]
  currentPackage: IPackage | null
  isBasic: boolean
  isDraft: boolean
}

const RetouchGallery = (props: RetouchGalleryProps) => {
  const {
    className,
    index,
    photos,
    images,
    products,
    freePhotos,
    currentPackage,
    isBasic, //TODO remove
    isDraft,
  } = props

  const currentPhotos = photos.filter((photo) =>
    images.find((image) => image.id === photo.id),
  )

  const getDiscountIsAvailable = (photo: IPhoto) => {
    if (!isBasic) {
      return false
    }

    const currentProduct = products.find(
      (product) => product.id === photo.inventory,
    )

    const { basicTotalWithoutDiscount, basicTotalWithDiscount } =
      calculateOrderTotal({
        checkedPhotos: images.filter((image: IRetouchImage) =>
          currentProduct?.photos.find(
            (productPhoto: IPhoto) => productPhoto.id === image.id,
          ),
        ),
        currentPackage,
        products,
        freePhotos,
      })

    return basicTotalWithoutDiscount !== basicTotalWithDiscount
  }

  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.inner, className)}>
        {currentPhotos.map((photo) => (
          <RetouchGalleryItem key={photo.id} className={styles.item} {...photo}>
            <>
              {isDraft ? (
                <RetouchGalleryBadges
                  photo={photo}
                  discountIsAvailable={getDiscountIsAvailable(photo)}
                  currentPackage={currentPackage}
                />
              ) : null}
            </>
          </RetouchGalleryItem>
        ))}
      </div>

      {/* {isDraft ? (
        <RetouchAddPhoto
          currentPhotosIds={currentPhotos.map((photo) => photo.id)}
          isEmpty={!currentPhotos.length}
          retouchIndex={index}
          products={products}
          freePhotos={freePhotos}
          currentPackage={currentPackage}
        />
      ) : null} */}
    </div>
  )
}

export default RetouchGallery
