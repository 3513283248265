import { useState } from 'react'
import cx from 'classnames'

import { ModalImage } from 'src/components'

import basicStyles from '../chat-messages-types.module.scss'
import styles from './chat-messages-item-image.module.scss'

import { IChatMessageImage } from 'src/interfaces'

interface ChatMessagesItemImageProps {
  messageId: string
  image?: IChatMessageImage
  time: string
}

const ChatMessagesItemImage = (props: ChatMessagesItemImageProps) => {
  const { messageId, image, time } = props

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div
        id={`message-${messageId}`}
        className={cx(basicStyles.wrapper, styles.wrapper)}
        onClick={() => setIsModalOpen(true)}
      >
        {image?.imageUrl ? (
          <img className={styles.image} src={image?.imageUrl} />
        ) : null}

        <div className={cx(basicStyles.time, styles.time)}>{time}</div>
      </div>

      {isModalOpen ? (
        <ModalImage
          src={image?.imageUrl}
          onClose={() => setIsModalOpen(false)}
        />
      ) : null}
    </>
  )
}

export default ChatMessagesItemImage
