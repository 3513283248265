import { Link } from 'react-router-dom'

import { Logo, Container, Icons } from 'src/components'

import UserBlock from './UserBlock'

import { PATHS } from 'src/config'

import styles from './header.module.scss'

const Header = () => {
  return (
    <div className={styles.wrapper}>
      <Container className={styles.inner}>
        <Link className={styles.logo} to="/">
          <Logo />
        </Link>

        <div className={styles.icons}>
          <Link to={PATHS.DASHBOARD}>
            <Icons.CardsView />
          </Link>
        </div>

        <UserBlock />
      </Container>
    </div>
  )
}

export default Header
