import { useFormik } from 'formik'

import { DashboardBlockHeader, DashboardTable } from '../components'

import OrderBlockHeader from './OrderBlockHeader'

import { useGetOrders } from 'src/hooks'

import styles from './orders-block.module.scss'

import { IOrder } from 'src/interfaces'

const OrdersBlock = () => {
  const { data } = useGetOrders()
  const orders = data?.data ?? []

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: () => {},
  })

  const filteredOrders = orders.filter(
    //const filteredOrders = [].filter(
    (order: IOrder) =>
      String(order.id).includes(formik.values.search) ||
      order.title?.includes(formik.values.search),
  )

  return (
    <div className={styles.wrapper}>
      <DashboardBlockHeader>
        <OrderBlockHeader providerValue={formik} />
      </DashboardBlockHeader>

      <DashboardTable items={filteredOrders} />
    </div>
  )
}

export default OrdersBlock
