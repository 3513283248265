import SingleOrderRoadMapItem from './SingleOrderRoadMapItem'

import { ORDER_STATUSES } from 'src/config'

import styles from './single-order-roadmap.module.scss'

import { IOrderStatusStep } from 'src/interfaces'

interface SingleOrderRoadMapProps {
  steps: IOrderStatusStep[]
}

const SingleOrderRoadMap = (props: SingleOrderRoadMapProps) => {
  const { steps } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.stepsWrapper}>
          {steps.map((s, i) => (
            <SingleOrderRoadMapItem key={i} {...s} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SingleOrderRoadMap
