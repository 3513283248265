import { useSearchParams, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { AuthTemplate } from 'src/templates'

import { Form, Input, SubmitButton } from 'src/components'

import { useVerify } from 'src/hooks'
import { useNotifications } from 'src/notification'

import { PATHS } from 'src/config'

import styles from './verify-forgot-page.module.scss'

import { IVerifyData } from 'src/interfaces'

const VerifyForgotPage = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const verifyMutation = useVerify('reset-password')

  const notification = useNotifications()

  const verifyFormik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: async (values) => {
      const params = [...searchParams]
      if (params.length) {
        const paramsObj = params.reduce(
          (a, [key, value]) => {
            a[key as keyof IVerifyData] = value
            return a
          },
          {
            user_id: '',
            timestamp: '',
            signature: '',
          },
        )

        await verifyMutation
          .mutateAsync({ ...paramsObj, password: values.password })
          .then(({ data }) => {
            notification?.show('success', t('verifyForgot.success'))
            navigate('/')
          })
          .catch((err) => {
            console.log('CATCH ERROR')
            console.log(err)
          })
      }
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required(t('error.required') || '')
        .min(8, 'Не меньше 8 символов'),
    }),
  })

  return (
    <AuthTemplate
      backgroundColor="#ededed"
      title={
        <>
          <div>{t('verifyForgot.passwordChanging')}</div>
        </>
      }
      providerValue={verifyFormik}
    >
      <Input
        className={styles.input}
        name="password"
        type="password"
        label={t('label.newPassword')}
      />

      <SubmitButton
        className={styles.button}
        loading={verifyMutation.isLoading}
      >
        {t('common.send')}
      </SubmitButton>
    </AuthTemplate>
  )
}

export default VerifyForgotPage
