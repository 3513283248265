import { useTranslation } from 'react-i18next'

import Tip from './Tip'

import { Icons } from 'src/components'

import { PATHS } from 'src/config'

import styles from './tips-block.module.scss'

const TipsBlock = () => {
  const { t } = useTranslation()

  const tips = [
    {
      title: t('dashboard.tips.1.title'),
      content: t('dashboard.tips.1.content'),
      buttonText: t('dashboard.tips.1.button'),
      image: '1.png',
      url: PATHS.CREATE_ORDER,
    },
    {
      title: t('dashboard.tips.2.title'),
      content: t('dashboard.tips.2.content'),
      benefit: t('dashboard.tips.2.benefit'),
      buttonText: t('dashboard.tips.2.button'),
      image: '2.png',
      url: 'https://kak2c.ru/fotosemka-dlya-marketpleisov#infographics',
      newWindow: true,
    },
  ]

  return (
    <div className={styles.wrapper}>
      {tips.map((tip, i) => (
        <Tip key={i} {...tip}>
          {tip.content}

          {tip.benefit ? (
            <div className={styles.benefit}>
              <Icons.Lightning />
              {tip.benefit}
            </div>
          ) : null}
        </Tip>
      ))}
    </div>
  )
}

export default TipsBlock
