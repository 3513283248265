import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Spinner } from 'src/components'

import { useConfirmShoot } from 'src/hooks'

import styles from './single-order-action.module.scss'

import { basicErrorHandler } from 'src/helpers'

import { ERRORS } from 'src/config'

import { AxiosError } from 'axios'
import { IOrder, IOrderStatusAction } from 'src/interfaces'

interface SingleOrderActionProps {
  order: IOrder
  currentAction: IOrderStatusAction
  orderRefetch: () => Promise<any>
  setCurrentStep: (step: number) => void
}

const SingleOrderAction = (props: SingleOrderActionProps) => {
  const { order, currentAction, orderRefetch, setCurrentStep } = props

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const confirmShootMutation = useConfirmShoot({
    id: order.shoots[0].id,
  })

  const handleConfirm = async () => {
    const result = await confirmShootMutation
      .mutateAsync({ ...order.shoots[0] })
      .catch((error: AxiosError) =>
        basicErrorHandler(error, ERRORS.SHOOT_CONFIRMATION),
      )

    await orderRefetch()

    return result
  }

  const actions = {
    // addItems: () =>
    //   setShowModal(`add${!isPackageComplex ? 'Inventory' : 'Composition'}`),
    addItems: () => {}, //TODO mb remove
    confirmOrder: async () => await handleConfirm(),
    choosePhotos: () => {
      setCurrentStep(1)

      //TODO mb implement
      document.getElementById('singleOrderGallery')?.scrollIntoView({
        behavior: 'smooth',
      })
    },
    downloadPhotos: () => {}, //TODO action
  }

  const handleAction = async (id: string) => {
    const currentAction = actions[id as keyof typeof actions]

    //: (() => Promise<any> | void) | undefined

    if (currentAction) {
      setLoading(true)

      await currentAction()

      setLoading(false)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.title}>{t(currentAction.title)}</div>

        <div className={styles.description}>{t(currentAction.description)}</div>
      </div>

      <div className={styles.buttonsWrapper}>
        {!loading ? (
          <>
            {(currentAction?.buttons ?? []).map((button, i) => (
              <Fragment key={i}>
                {button.label ? (
                  <Button
                    className={styles.button}
                    onClick={() => handleAction(button.id)}
                  >
                    {t(button.label)}
                  </Button>
                ) : null}
              </Fragment>
            ))}
          </>
        ) : (
          <Spinner color="secondary" size="middle" />
        )}
      </div>
    </div>
  )
}

export default SingleOrderAction
