import { useTranslation } from 'react-i18next'

import { BasicItemsBlock } from 'src/components'

import DashboardTableItem from './DasboardTableItem'

import { useGetCategories } from 'src/hooks'

import { PATHS } from 'src/config'

import { IOrder } from 'src/interfaces'

interface PhotosetsBlockProps {
  items: IOrder[]
}

const DashboardTable = (props: PhotosetsBlockProps) => {
  const { items = [] } = props

  const { t } = useTranslation()

  const { data, isLoading } = useGetCategories()

  const emptyConfig = {
    illustration: 'NoShoots',
    description: t('dashboard.noOrders'),
    linkTitle: t('dashboard.createOrder'),
    linkUrl: PATHS.CREATE_ORDER,
  }

  return (
    <BasicItemsBlock
      padding={32}
      emptyConfig={emptyConfig}
      isLoading={isLoading}
    >
      {items.map((item) => (
        <DashboardTableItem key={item.id} {...item} />
      ))}
    </BasicItemsBlock>
  )
}

export default DashboardTable
