import { useEffect } from 'react'
import { useFormik } from 'formik'
import { v4 as uuid } from 'uuid'

import { useChatContext } from 'src/pages/ChatPage/chatContext'

import { Form, Icons } from 'src/components'

import { ChatReplyItem } from 'src/pages/ChatPage/components'

import ChatMessageFormAttachment from './ChatMessageFormAttachment'
import ChatMessageFormInput from './ChatMessageFormInput'

import styles from './chat-message-form.module.scss'

import { IChatMessage, IChatAuthor } from 'src/interfaces'

interface ChatMessageFormProps {
  author?: IChatAuthor
  onMessageSend: (message: IChatMessage) => void
}

const ChatMessageForm = (props: ChatMessageFormProps) => {
  const {
    author = {
      id: 'john',
      name: 'Джон Смит',
      isUser: true,
    },
    onMessageSend,
  } = props

  const { messageIdToReply, setMessageIdToReply } = useChatContext()

  const formik = useFormik({
    initialValues: {
      message: '',
      attachments: [],
    },
    onSubmit: (values, { resetForm }) => {
      //TODO send message form-data API request

      onMessageSend({
        id: uuid(),
        message: values.message,
        timestamp: new Date().toISOString(),
        author,
        repliedToMessageId: messageIdToReply || undefined,
      })

      return Promise.resolve().then(() => {
        resetForm()

        if (messageIdToReply) {
          setMessageIdToReply(null)
        }
      })
    },
  })

  const handleSend = () => {
    //TODO refactor
    if (!formik.values.message && !formik.values.attachments.length) return

    formik.submitForm()
  }

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        handleSend()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleSend])

  return (
    <Form className={styles.wrapper} providerValue={formik}>
      {messageIdToReply ? (
        <ChatReplyItem messageId={messageIdToReply} showIcon />
      ) : null}

      <div className={styles.inner}>
        <ChatMessageFormAttachment />

        <ChatMessageFormInput />

        <div className={styles.send} onClick={handleSend}>
          <Icons.ArrowSend />
        </div>
      </div>
    </Form>
  )
}

export default ChatMessageForm
