import { useChatContext } from 'src/pages/ChatPage/chatContext'

import { ChatMessageForm } from 'src/pages/ChatPage/components'

import { IChatMessage } from 'src/interfaces'

const ChatFooter = () => {
  const { messages, setMessages } = useChatContext()

  const handleMessageSend = (newMessage: IChatMessage) => {
    setMessages([...messages, newMessage])
  }

  return <ChatMessageForm onMessageSend={handleMessageSend} />
}

export default ChatFooter
