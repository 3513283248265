import { useTranslation } from 'react-i18next'

import { BasicBlock, OrderPackage } from 'src/components'

import { SingleOrderOptions } from 'src/pages/SingleOrderPage/SingleOrderMainBlock/SingleOrderTabs/components'

import styles from './single-order-package.module.scss'

import { IShoot } from 'src/interfaces'

interface SingleOrderPackageProps {
  currentShoot: IShoot
}

const SingleOrderPackage = (props: SingleOrderPackageProps) => {
  const { currentShoot } = props

  const { t } = useTranslation()

  return (
    <BasicBlock className={styles.wrapper} padding={32}>
      <div>
        <div className={styles.title}>{t('order.shootingType')}</div>

        {currentShoot.package ? (
          <OrderPackage shootPackage={currentShoot.package} />
        ) : null}
      </div>

      <SingleOrderOptions />
    </BasicBlock>
  )
}

export default SingleOrderPackage
