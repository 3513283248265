import cx from 'classnames'

import styles from './status.module.scss'

interface StatusProps {
  className?: string
  type?: 'success' | 'progress'
}

const Status = (props: React.PropsWithChildren<StatusProps>) => {
  const { className, type = 'success', children } = props

  return (
    <div className={cx(styles.wrapper, className)}>
      {children} <span className={cx(type && styles[type])} />
    </div>
  )
}

export default Status
