import { useMutation } from 'react-query'
import { api } from 'src/api'

import { IRetouch } from 'src/interfaces'

interface ICreateRetouchData extends IRetouch {}

const useCreateRetouch = () => {
  return useMutation(async (data: ICreateRetouchData) =>
    api.post('/api/v1/retouche/', data),
  )
}

export default useCreateRetouch
