import { IRetouchStatus } from 'src/interfaces'

export const RETOUCH_STATUSES = {
  [IRetouchStatus.DRAFT]: {
    title: 'status.retouch.draft',
  },
  [IRetouchStatus.PAID]: {
    title: 'status.retouch.paid',
  },
  [IRetouchStatus.IN_PROGRESS]: {
    title: 'status.retouch.inProgress',
  },
  [IRetouchStatus.DONE]: {
    title: 'status.retouch.done',
  },
} as {
  [key in IRetouchStatus]: {
    title: string
  }
}

export const FRAMING_OPTIONS = [
  { label: 'Квадрат', value: '1:1' },
  { label: 'Вертикально', value: '3:4' },
  { label: 'Горизонтально', value: '4:3' },
]

export const ALIGN_OPTIONS = [
  { label: 'По центру', value: 'CENTER' },
  { label: 'По низу', value: 'BOTTOM' },
]

export const BACKGROUND_COLOR_OPTIONS = [
  {
    label: 'Серый 5%',
    value: 'GRAY5',
    meta: {
      color: '#f2f2f2',
    },
  },
  {
    label: 'Белый',
    value: 'WHITE',
    meta: {
      color: '#fff',
    },
  },
  {
    label: 'Свой цвет',
    value: 'CUSTOM',
  },
]
