import { useTranslation } from 'react-i18next'

import { ForgotForm, Input, SubmitButton } from 'src/components'

import styles from './forgot-block.module.scss'

import { ReactComponent as Icon } from './assets/icon.svg'

interface ForgotBlockProps {
  onAuth: (data: any) => void
  setCurrentBlock: (block: 'Auth' | 'Register' | 'Forgot') => void
}

const ForgotBlock = (props: ForgotBlockProps) => {
  const { setCurrentBlock } = props

  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Icon />
      </div>

      <div className={styles.title}>{t('needAuth.titleLogin')}</div>

      <ForgotForm className={styles.form}>
        <Input
          className={styles.input}
          name="email"
          type="email"
          label={t('label.email')}
          placeholder="example@gmail.com"
        />

        <SubmitButton className={styles.button}>
          {t('common.send')}
        </SubmitButton>

        <div className={styles.optionWithLink}>
          <div className={styles.link} onClick={() => setCurrentBlock('Auth')}>
            {t('common.comeIn')}
          </div>
        </div>

        <div className={styles.optionWithLink}>
          <div
            className={styles.link}
            onClick={() => setCurrentBlock('Register')}
          >
            {t('auth.newAccountLink')}
          </div>
        </div>
      </ForgotForm>
    </div>
  )
}

export default ForgotBlock
