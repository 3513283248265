import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './single-order-roadmap-item.module.scss'

import { IOrderStatusStep } from 'src/interfaces'

const SingleOrderRoadMapItem = (props: IOrderStatusStep) => {
  const { title, icon, template } = props

  const { t } = useTranslation()

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  return (
    <div className={cx(styles.wrapper, styles[template])}>
      <div className={styles.inner}>
        {IconComponent ? (
          <div className={styles.icon}>
            <IconComponent />
          </div>
        ) : null}

        <div className={styles.divider} />
      </div>

      <div className={styles.title}>{t(title)}</div>
    </div>
  )
}

export default SingleOrderRoadMapItem
