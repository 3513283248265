export const LANGS = [
  {
    id: 'ru',
    label: 'Русский',
    flag: 'Ru',
  },
  {
    id: 'en',
    label: 'English',
    flag: 'En',
  },
  {
    id: 'tr',
    label: 'Türk',
    flag: 'Tr',
  },
]
