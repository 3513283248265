import { Suspense, useEffect } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { appLocalStorage } from 'src/libs'
import { privateRoutes, publicRoutes, commonRoutes, IRoutes } from './'

import { TemplateWrapper } from 'src/templates'

interface IRoutesRendering {
  routes: IRoutes
}

const RoutesRendering = (props: IRoutesRendering) => {
  const { routes } = props

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === '/') {
      const defaultRoute = routes.find((route) => route.default)
      if (defaultRoute) {
        navigate(defaultRoute.path || '/404', { replace: true })
      }
    }
  }, [location.pathname])

  const actualRoutes = [...routes, ...commonRoutes]

  return (
    <Routes>
      {actualRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path || ''}
          element={
            <TemplateWrapper template={route.template}>
              <Suspense fallback={null}>
                <route.component />
              </Suspense>
            </TemplateWrapper>
          }
        />
      ))}
      {/* <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} /> */}
      {/* {defaultPath && <Redirect to={defaultPath} />} */}
    </Routes>
  )
}

export const renderPrivateRoutes = () => (
  <RoutesRendering routes={privateRoutes} />
)
export const renderPublicRoutes = () => (
  <RoutesRendering routes={publicRoutes} />
)
