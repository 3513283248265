import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './chat-messages-item-context-menu-item.module.scss'

interface ChatMessagesItemContextMenuItemProps {
  icon: string
  label: string
  template?: string
  onClick: (data: any) => void | Promise<any>
}

const ChatMessagesItemContextMenuItem = (
  props: ChatMessagesItemContextMenuItemProps,
) => {
  const { icon, label, template, onClick } = props

  const IconComponent =
    icon && (Object.entries(Icons)?.find(([key]) => key === icon)?.[1] ?? null)

  return (
    <div
      className={cx(styles.wrapper, template && styles[template])}
      onClick={onClick}
    >
      {IconComponent ? (
        <div className={styles.icon}>
          <IconComponent />
        </div>
      ) : null}
      <div className={styles.label}>{label}</div>
    </div>
  )
}

export default ChatMessagesItemContextMenuItem
