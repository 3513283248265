import { useTranslation } from 'react-i18next'

import { BasicTemplate } from 'src/templates'

import { Title, Role } from 'src/components'

import OrdersBlock from './OrdersBlock'
import TipsBlock from './TipsBlock'

import styles from './dashboard-page.module.scss'

const DashboardPage = () => {
  const { t } = useTranslation()

  return (
    <BasicTemplate>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Title className={styles.title}>
            <span>{t('dashboard.welcome')}</span> 👋
          </Title>
        </div>

        <Role.Customer>
          <TipsBlock />
        </Role.Customer>

        <OrdersBlock />
      </div>
    </BasicTemplate>
  )
}

export default DashboardPage
