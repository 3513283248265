import { useMutation } from 'react-query'
import { api } from 'src/api'

interface ICreateOrderData {}

const useCreateOrder = () => {
  return useMutation(async (data: ICreateOrderData) =>
    api.post('/api/v1/orders/', data),
  )
}

export default useCreateOrder
