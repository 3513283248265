import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import UserBlockMenu from './UserBlockMenu'

import { useAppContext } from 'src/context'

import { getAvatarText } from 'src/helpers'

import styles from './user-block.module.scss'

const UserBlock = () => {
  const { currentUser } = useAppContext()

  const { t } = useTranslation()

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const firstName = currentUser.first_name
  const lastName = currentUser.last_name
  const userName =
    firstName || lastName ? `${firstName} ${lastName}` : t('common.user')

  const avatarText = getAvatarText(t('common.user'), [firstName, lastName])

  return (
    <div className={styles.wrapper} onClick={() => setIsMenuOpen(true)}>
      <div className={styles.userDataWrapper}>
        <div className={styles.name}>{userName}</div>

        <div className={styles.email}>{currentUser.email}</div>
      </div>

      <div className={styles.userAvatar}>{avatarText}</div>

      <UserBlockMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </div>
  )
}

export default UserBlock
