import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { BasicBlock, Badge } from 'src/components'

import { PhotoStepProductGallery } from '../components'

import { calculateOrderItemDiscount } from 'src/helpers'

import parentStyles from '../photo-step.module.scss'
import styles from './photo-step-free-photos.module.scss'

import { IRetouch, IPhoto, IPackage } from 'src/interfaces'

interface PhotoStepFreePhotosProps {
  photos: IPhoto[]
  currentPackage: IPackage | null
}

const PhotoStepFreePhotos = (props: PhotoStepFreePhotosProps) => {
  const { photos, currentPackage } = props

  const { t } = useTranslation()

  const [{ value }, meta, { setValue }] = useField('retouches')
  const photosValue =
    value.find((retouch: IRetouch) => retouch.type === 'basic')?.images ?? []
  const retouchValue =
    value.find((retouch: IRetouch) => retouch.type === 'extra')?.images ?? []

  const checkedBasicPhotos = photos.filter((photo: IPhoto) =>
    photosValue.find(
      (photoValue: { id: number }) => photoValue.id === photo.id,
    ),
  )

  const isAllSelected = checkedBasicPhotos.length === photos.length

  const handleToggleAll = () => {
    //TODO refactor
    const newPhotoImagesValue = value[0].images
      .filter((image: { id: number }) => {
        const usesLikeFreePhoto = Boolean(
          photos.find((photo) => photo.id === image.id)?.inventory === null,
        )

        return usesLikeFreePhoto ? false : true
      })
      .concat(!isAllSelected ? photos.map((photo) => ({ id: photo.id })) : [])

    setValue([
      {
        ...value[0],
        images: newPhotoImagesValue,
      },
    ])
  }

  const discountIsAvailable =
    checkedBasicPhotos.length >= (currentPackage?.photo_count ?? 0)
  const leftForDiscount = discountIsAvailable
    ? 0
    : (currentPackage?.photo_count ?? 0) - checkedBasicPhotos.length

  const discount = calculateOrderItemDiscount(currentPackage)

  return (
    <BasicBlock className={parentStyles.productWrapper}>
      <div className={parentStyles.headerWrapper}>
        <div className={cx(parentStyles.subheader, styles.subheader)}>
          <div className={parentStyles.discount}>
            <Badge
              className={parentStyles.badge}
              template={discountIsAvailable ? 'success' : 'danger'}
            >
              {t(`common.discount${discountIsAvailable ? 'Received' : ''}`) +
                ` -${discount.toFixed(0)}%`}
            </Badge>

            {!discountIsAvailable
              ? t('order.selectMoreItemsForDiscount', {
                  moreCount: leftForDiscount,
                })
              : null}
          </div>

          {photos.length ? (
            <div className={parentStyles.selecter} onClick={handleToggleAll}>
              {`${!isAllSelected ? 'Выбрать' : 'Убрать'} все`}
            </div>
          ) : null}
        </div>
      </div>

      <PhotoStepProductGallery
        className={parentStyles.gallery}
        photos={photos}
        currentPackage={currentPackage}
        discountIsAvailable={discountIsAvailable}
      />
    </BasicBlock>
  )
}

export default PhotoStepFreePhotos
