import { useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AuthTemplate } from 'src/templates'

import { Button } from 'src/components'

import { useVerify } from 'src/hooks'

import { PATHS } from 'src/config'

import styles from './verify-page.module.scss'

import { IVerifyData } from 'src/interfaces'

const VerifyPage = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()

  const verifyMutation = useVerify('verify-registration')

  useEffect(() => {
    ;(async () => {
      const params = [...searchParams]
      if (params.length) {
        const paramsObj = params.reduce(
          (a, [key, value]) => {
            a[key as keyof IVerifyData] = value
            return a
          },
          {
            user_id: '',
            timestamp: '',
            signature: '',
          },
        )

        await verifyMutation
          .mutateAsync(paramsObj)
          .then(({ data }) => {
            window.location.href = '/'
          })
          .catch((err) => {
            console.log('CATCH ERROR')
            console.log(err)
          })
      }
    })()
  }, [])

  return (
    <AuthTemplate
      backgroundColor="#ededed"
      title={
        <>
          <div>{t('verify.registrationVerification')}</div>
        </>
      }
    >
      <Button className={styles.button} loading={!verifyMutation.isSuccess}>
        {t('common.comeIn')}
      </Button>
    </AuthTemplate>
  )
}

export default VerifyPage
