import styles from './logo.module.scss'

const Logo = () => {
  return (
    <div className={styles.logo}>
      <img src="/img/logo.svg" alt="logo" />
    </div>
  )
}

export default Logo
