import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { BasicBlock, Badge, Price, Icons } from 'src/components'

import RetouchGallery from './RetouchGallery'
import RetouchFilters from './RetouchFilters'
import RetouchNotDraftLeftPanel from './RetouchNotDraftLeftPanel'
import RetouchInfoPrompt from './RetouchInfoPrompt'

import { calculateOrderTotal } from 'src/helpers'

import styles from './retouch-step-item.module.scss'

import {
  IPhoto,
  IInventoryWithPhotos,
  IPackage,
  IRetouchStatus,
} from 'src/interfaces'

interface RetouchStepItemProps {
  index: number
  photos: IPhoto[]
  products: IInventoryWithPhotos[]
  freePhotos: IPhoto[]
  currentPackage: IPackage | null
}

const RetouchStepItem = (props: RetouchStepItemProps) => {
  const { index, photos, products, freePhotos, currentPackage } = props

  const { t } = useTranslation()

  const [{ value }] = useField('retouches')

  const currentRetouch = value[index]
  const isBasic = currentRetouch.type === 'basic'
  const isDraft = currentRetouch.status === IRetouchStatus.DRAFT

  const { basicTotalWithoutDiscount, basicTotalWithDiscount, extraTotal } =
    calculateOrderTotal({
      checkedPhotos: currentRetouch.images,
      currentPackage,
      products,
      freePhotos,
    })

  return (
    <BasicBlock className={styles.wrapper}>
      {isDraft ? (
        <div className={styles.header}>
          <div className={styles.titleL}>{t('order.retouchOrder')}</div>

          <RetouchInfoPrompt />

          {/*<div className={styles.priceWrapper}>
            <Price
              value={basicTotalWithDiscount + extraTotal}
              template="based"
            />
          </div>*/}
        </div>
      ) : null}

      <div className={cx(styles.inner, !isDraft && styles.notDraft)}>
        {isDraft ? (
          <RetouchFilters index={index} />
        ) : (
          <RetouchNotDraftLeftPanel {...currentRetouch} />
        )}

        <RetouchGallery
          className={styles.gallery}
          index={index}
          photos={photos}
          products={products}
          freePhotos={freePhotos}
          currentPackage={currentPackage}
          isBasic={isBasic}
          isDraft={isDraft}
          discountIsAvailable={
            basicTotalWithoutDiscount !== basicTotalWithDiscount
          }
          {...currentRetouch}
        />
      </div>
    </BasicBlock>
  )
}

export default RetouchStepItem
