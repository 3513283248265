import { useState } from 'react'
import { useFormik } from 'formik'
import cx from 'classnames'

import { OrderTitle, Icons } from 'src/components'

import SingleOrderHeaderForm from './SingleOrderHeaderForm'

import { isOrderStatusPassed } from 'src/helpers'

import styles from './single-order-header-title.module.scss'

import { IOrder, IOrderStatus } from 'src/interfaces'

const SingleOrderHeaderTitle = (props: IOrder) => {
  const { shoots, status } = props

  const [isEdit, setIsEdit] = useState(false)

  const isConfirmed = isOrderStatusPassed(IOrderStatus.CONFIRMED, status)

  const handleClick = () => {
    if (!isConfirmed) {
      setIsEdit(true)
    }
  }

  return (
    <div
      className={cx(styles.wrapper, isConfirmed && styles.disabled)}
      onClick={handleClick}
    >
      {!isEdit ? (
        <>
          <OrderTitle {...props} />

          {!isConfirmed ? (
            <div className={styles.edit}>
              <Icons.Edit />
            </div>
          ) : null}
        </>
      ) : (
        <SingleOrderHeaderForm
          order={{ ...props }}
          onSubmit={() => setIsEdit(false)}
        />
      )}
    </div>
  )
}

export default SingleOrderHeaderTitle
