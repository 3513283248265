import { useState } from 'react'

import { ChatUserAvatar } from 'src/pages/ChatPage/components'

import ChatHeaderAddUserItemInvite from './ChatHeaderAddUserItemInvite'

import styles from './chat-header-add-user-item.module.scss'

//TODO move it to common
interface ChatHeaderAddUserItemProps {
  id: number
  name: string
  role: string
  color: string
}

const ChatHeaderAddUserItem = (props: ChatHeaderAddUserItemProps) => {
  const { id, name, role, color } = props

  const [currentInviteId, setCurrentInviteId] = useState<number | null>(null)

  return (
    <>
      <div className={styles.wrapper} onClick={() => setCurrentInviteId(id)}>
        <ChatUserAvatar name={[name, role]} color={color} />

        <div className={styles.inner}>
          <div className={styles.textSMedium}>{name}</div>

          <div className={styles.role}>{role}</div>
        </div>
      </div>

      {currentInviteId !== null ? (
        <ChatHeaderAddUserItemInvite
          currentInviteId={currentInviteId}
          onClose={() => setCurrentInviteId(null)}
        />
      ) : null}
    </>
  )
}

export default ChatHeaderAddUserItem
