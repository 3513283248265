import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { appLocalStorage } from 'src/libs'

import * as langFiles from './resources'

const resources = Object.entries(langFiles).reduce((a, [key, file]) => {
  a[key] = {
    translation: file,
  }
  return a
}, {} as any) //TODO better

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: appLocalStorage?.currentLang ?? 'ru',
  fallbackLng: 'ru',
  returnNull: false,
  //debug: true,

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
