import { Input, Icons } from 'src/components'

import styles from './chat-message-form-input.module.scss'

const ChatMessageFormInput = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <Input name="message" placeholder="Сообщение" />

        <div className={styles.emoji}>
          <Icons.Smile />
        </div>
      </div>
    </div>
  )
}

export default ChatMessageFormInput
