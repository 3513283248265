import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { BasicBlock, Badge, Role } from 'src/components'

import { PhotoStepProductGallery } from '../components'

import { calculateOrderItemDiscount } from 'src/helpers'

import parentStyles from '../photo-step.module.scss'
import styles from './photo-step-product.module.scss'

import {
  IInventoryWithPhotos,
  IRetouch,
  IRetouchStatus,
  IPhoto,
  IPackage,
} from 'src/interfaces'

interface PhotoStepProductProps extends IInventoryWithPhotos {
  currentPackage: IPackage | null
}

const PhotoStepProduct = (props: PhotoStepProductProps) => {
  const { id: productId, title, barcode, sku, photos, currentPackage } = props

  const { t } = useTranslation()

  const [{ value }, meta, { setValue }] = useField('retouches')
  const photosValue =
    value.find(
      (retouch: IRetouch) =>
        retouch.type === 'basic' && retouch.status === IRetouchStatus.DRAFT,
    )?.images ?? []

  const checkedBasicPhotos = photos.filter((photo: IPhoto) =>
    photosValue.find(
      (photoValue: { id: number }) => photoValue.id === photo.id,
    ),
  )

  const isAllSelected = checkedBasicPhotos.length === photos.length

  const handleToggleAll = () => {
    //TODO refactor
    const newPhotoImagesRetouchIndex = value.findIndex(
      (retouch: IRetouch) =>
        retouch.type === 'basic' && retouch.status === IRetouchStatus.DRAFT,
    )
    const newPhotoImagesValue = value[newPhotoImagesRetouchIndex].images
      .filter((image: { id: number }) => {
        const usesByCurrentProduct = Boolean(
          photos.find((photo) => photo.id === image.id)?.inventory ===
            productId,
        )

        return usesByCurrentProduct ? false : true
      })
      .concat(!isAllSelected ? photos.map((photo) => ({ id: photo.id })) : [])

    setValue([
      {
        ...value[newPhotoImagesRetouchIndex],
        images: newPhotoImagesValue,
      },
    ])
  }

  const discountIsAvailable =
    checkedBasicPhotos.length >= (currentPackage?.photo_count ?? 0)
  const leftForDiscount = discountIsAvailable
    ? 0
    : (currentPackage?.photo_count ?? 0) - checkedBasicPhotos.length

  const discount = calculateOrderItemDiscount(currentPackage)

  return (
    <BasicBlock className={parentStyles.productWrapper}>
      <div className={parentStyles.headerWrapper}>
        <div className={parentStyles.header}>
          <div className={cx(parentStyles.label, parentStyles.article)}>
            {t('common.articleShort')}
            <span>{sku}</span>
          </div>

          <div className={parentStyles.title}>{title}</div>

          <div className={parentStyles.label}>{barcode}</div>
        </div>
        <Role.Customer>
          <div className={cx(parentStyles.subheader, styles.subheader)}>
            <div className={parentStyles.discount}>
              <Badge
                className={parentStyles.badge}
                template={discountIsAvailable ? 'success' : 'danger'}
              >
                {t(`common.discount${discountIsAvailable ? 'Received' : ''}`) +
                  ` -${discount.toFixed(0)}%`}
              </Badge>

              {!discountIsAvailable
                ? t('order.selectMoreItemsForDiscount', {
                    moreCount: leftForDiscount,
                  })
                : null}
            </div>

            {photos.length ? (
              <div
                className={cx(parentStyles.selecter, styles.selecter)}
                onClick={handleToggleAll}
              >
                {`${!isAllSelected ? 'Выбрать' : 'Убрать'} все`}
              </div>
            ) : null}
          </div>
        </Role.Customer>
      </div>

      <PhotoStepProductGallery
        className={parentStyles.gallery}
        photos={photos}
        currentPackage={currentPackage}
        discountIsAvailable={discountIsAvailable}
      />
    </BasicBlock>
  )
}

export default PhotoStepProduct
