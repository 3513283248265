import { useTranslation } from 'react-i18next'

import { Icons } from 'src/components'

import { useGetCategories } from 'src/hooks'

import { getCategoryById } from 'src/helpers'

import rootStyles from '../single-order-data-for-staff.module.scss'
import styles from './single-order-data-for-staff-package.module.scss'

import { IShoot } from 'src/interfaces'

interface SingleOrderDataForStaffPackageProps {
  currentShoot: IShoot
}

const SingleOrderDataForStaffPackage = (
  props: SingleOrderDataForStaffPackageProps,
) => {
  const { currentShoot } = props

  const { t } = useTranslation()

  const categories = useGetCategories({ ordering: 'priority' })

  const shootCategory = getCategoryById(
    currentShoot.package?.category_id ?? null,
    categories.data,
  )

  return (
    <div className={styles.wrapper}>
      <div className={rootStyles.title}>{t('order.shootingType')}</div>

      {shootCategory ? (
        <div className={styles.categoryTitle}>{shootCategory.title}</div>
      ) : null}

      <div className={styles.titleM}>{currentShoot.package?.title}</div>

      {/* TODO mb move to common */}
      <div className={styles.infoStatus}>
        <div className={styles.icon}>
          <Icons.PhotoCameraSecondary />
        </div>

        <div className={styles.text}>
          Ожидает назначения даты и времени съемки
        </div>
      </div>
    </div>
  )
}

export default SingleOrderDataForStaffPackage
