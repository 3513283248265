import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { apiClient } from './api'

import 'src/i18n'

import 'dayjs/locale/ru'
import 'dayjs/locale/tr'

import App from './App'

import { NotificationProvider } from 'src/notification'
import { AppContextProvider } from 'src/context'

import './styles/globals.scss'

declare global {
  //TODO Better
  interface Window {
    showNotification: any
    clearNotification: any
  }
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={apiClient}>
      <BrowserRouter>
        <NotificationProvider>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </NotificationProvider>
      </BrowserRouter>

      {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
