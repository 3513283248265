import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Flags } from 'src/components'

import { appLocalStorage } from 'src/libs'

import styles from './lang-select-option.module.scss'

interface LangSelectOptionProps {
  id: string
  label: string
  flag: string
}

const LangSelectOption = (props: LangSelectOptionProps) => {
  const { id, label, flag } = props

  const { i18n } = useTranslation()

  const handleSelect = () => {
    appLocalStorage.currentLang = id
    i18n.changeLanguage(id)
  }

  const FlagComponent =
    flag &&
    (Object.entries(Flags)?.find(([key, value]) => key === flag)?.[1] ?? null)

  return (
    <div
      className={cx(styles.wrapper, i18n.language === id && styles.active)}
      onClick={handleSelect}
    >
      <div>{label}</div>

      {FlagComponent ? (
        <div className={styles.flag}>
          <FlagComponent />
        </div>
      ) : null}
    </div>
  )
}

export default LangSelectOption
