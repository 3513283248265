import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { Count, Icons } from 'src/components'

import styles from './product-item.module.scss'

import { IInventory, IProductItem } from 'src/interfaces'

interface ProductItemProps {
  data?: IInventory
  value: IProductItem
  onChange: (value: number) => void
  onRemove: () => void
}

const ProductItem = (props: ProductItemProps) => {
  const { data, value, onChange, onRemove } = props

  const { t } = useTranslation()

  if (!data) {
    return null
  }

  const { title, barcode, sku } = data

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <div className={styles.subtitleS}>{title}</div>
        <div className={styles.barcode}>{barcode}</div>
        <div className={styles.article}>{`${t(
          'common.articleShort',
        )} ${sku}`}</div>
      </div>

      <Count className={styles.count} value={value.count} setValue={onChange} />

      <div className={styles.close} onClick={onRemove}>
        <Icons.Close />
      </div>
    </div>
  )
}

export default ProductItem
