import { useTranslation } from 'react-i18next'

import { Price, InfoComment } from 'src/components'

import styles from './order-package.module.scss'

import { ICategory, IPackage } from 'src/interfaces'

interface OrderPackageProps {
  showImage?: boolean
  approximatePrice?: boolean
  shootCategory?: ICategory
  shootPackage: IPackage
}

const OrderPackage = (props: OrderPackageProps) => {
  const { showImage, approximatePrice, shootCategory, shootPackage } = props

  const { title, additional_photo_price, photo_count, price, banner_image } =
    shootPackage

  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      {showImage ? (
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${
              banner_image
                ? banner_image.replace('http://', 'https://')
                : '/img/logo.svg'
            })`,
          }}
        />
      ) : null}

      <div>
        {shootCategory ? (
          <div className={styles.categoryTitle}>{shootCategory.title}</div>
        ) : null}

        <div className={styles.titleM}>{title}</div>

        {/* <div className={styles.prices}>
          {approximatePrice ? (
            <Price
              inline
              prefix={t('common.from')}
              value={price}
              label={t('common.perPhoto')}
            />
          ) : (
            <>
              {typeof additional_photo_price !== 'undefined' ? (
                <Price
                  inline
                  value={additional_photo_price}
                  label={`${t('common.perPhoto')} ${t(
                    'common.whenOrderingFrom',
                    { count: photo_count },
                  )}`}
                />
              ) : (
                <Price inline value={price} label={t('common.apiece')} />
              )}
            </>
          )}
        </div> */}

        <a
          className={styles.tariffs}
          href="https://wiki.kak2c.ru/books/oferty-i-uslugi/page/uslugi-fotosemki-tovarov"
          target="_blank"
        >
          <InfoComment color="blue" icon="Info">
            {t('order.tariffInformation')}
          </InfoComment>
        </a>
      </div>
    </div>
  )
}

export default OrderPackage
