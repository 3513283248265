import { useTranslation } from 'react-i18next'

import { Title, Button, Form, InputSearch, Role } from 'src/components'

import { PATHS } from 'src/config'

import styles from './order-block-header.module.scss'

import { FormikContextType } from 'formik'

interface OrderBlockHeaderProps {
  providerValue: FormikContextType<any>
}

const OrderBlockHeader = (props: OrderBlockHeaderProps) => {
  const { providerValue } = props

  const { t } = useTranslation()

  return (
    <>
      <Title>{t('common.orders')}</Title>

      <Role.Customer>
        <Button to={PATHS.CREATE_ORDER}>{t('dashboard.newOrder')}</Button>
      </Role.Customer>

      <Role.Staff>
        <Form providerValue={providerValue}>
          <InputSearch
            className={styles.searchInput}
            placeholder="Введите название съемки или ID"
          />
        </Form>
      </Role.Staff>
    </>
  )
}

export default OrderBlockHeader
