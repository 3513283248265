import { useRoleQuery } from 'src/hooks'

const useGetRetouches = () => {
  return useRoleQuery({
    queryKeys: [`getRetouches`],
    url: '/api/v1/retouche/',
  })
}

export default useGetRetouches
