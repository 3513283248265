import cx from 'classnames'

import { ChatReplyItem } from 'src/pages/ChatPage/components'

import basicStyles from '../chat-messages-types.module.scss'

import { IChatAuthor } from 'src/interfaces'

interface ChatMessagesItemTextProps {
  messageId: string
  message?: string
  time: string
  author: IChatAuthor
  repliedToMessageId?: string
}

const ChatMessagesItemText = (props: ChatMessagesItemTextProps) => {
  const { messageId, message, time, author, repliedToMessageId } = props

  return (
    <div
      id={`message-${messageId}`}
      className={cx(basicStyles.wrapper, author.isUser && basicStyles.isUser)}
    >
      {repliedToMessageId ? (
        <ChatReplyItem messageId={repliedToMessageId} />
      ) : null}

      <div>{message}</div>

      <div className={basicStyles.time}>{time}</div>
    </div>
  )
}

export default ChatMessagesItemText
