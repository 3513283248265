import { useTranslation } from 'react-i18next'

import { BasicBlock, EmptyBlock } from 'src/components'

interface RetouchStepEmptyProps {
  onCreate: () => void
}

const RetouchStepEmpty = (props: RetouchStepEmptyProps) => {
  const { onCreate } = props

  const { t } = useTranslation()

  const emptyConfig = {
    illustration: 'Cart',
    description: t('dashboard.noRetouches'),
    linkTitle: t('common.create'),
    linkAction: onCreate,
  }

  return (
    <BasicBlock padding={32}>
      <EmptyBlock {...emptyConfig} />
    </BasicBlock>
  )
}

export default RetouchStepEmpty
