import { useSearchParams } from 'react-router-dom'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { BasicBlock, SubmitButton } from 'src/components'

import ResultStepParams from './ResultStepParams'
import ResultStepPackage from './ResultStepPackage'

import { getCurrentOrderPackages, getCategoryById } from 'src/helpers'

import styles from './result-step.module.scss'

import { IPackage, ICategory } from 'src/interfaces'

interface ResultStepProps {
  packages: IPackage[]
  categories: ICategory[]
  setCurrentStep: (step: number) => void
}

const ResultStep = (props: ResultStepProps) => {
  const { categories = [], packages = [], setCurrentStep } = props

  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const initialPackage = searchParams.get('initial-package')

  const [{ value: currentPackagesValue }] = useField('packages')

  const currentPackages = getCurrentOrderPackages(
    currentPackagesValue,
    packages,
  )

  const shootPackage = currentPackages?.[0] ?? null

  const shootCategory = getCategoryById(shootPackage?.category_id, categories)

  return (
    <BasicBlock className={styles.wrapper}>
      <div className={styles.inner}>
        <ResultStepParams />

        <ResultStepPackage
          shootCategory={shootCategory}
          shootPackage={shootPackage}
        />
      </div>

      <SubmitButton className={styles.button}>
        {t('common.makeReservation')}
      </SubmitButton>
    </BasicBlock>
  )
}

export default ResultStep
