import { Icons } from 'src/components'

import { useGetUserById } from 'src/hooks'

import { copyToClipboard } from 'src/helpers'

import rootStyles from '../single-order-data-for-staff.module.scss'
import styles from './single-order-data-for-staff-customer.module.scss'

interface SingleOrderDataForStaffCustomerProps {
  userId: string
}

const SingleOrderDataForStaffCustomer = (
  props: SingleOrderDataForStaffCustomerProps,
) => {
  const { userId } = props

  const { data } = useGetUserById({ userId })
  const user = data?.data

  if (!user) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={rootStyles.title}>Данные пользователя</div>

      <div className={styles.inner}>
        <div>
          <div className={styles.name}>{`${user.first_name || 'Неизвестно'} ${
            user.last_name || 'Неизвестно'
          }`}</div>

          <div className={styles.email}>{user.email}</div>
        </div>

        <div
          className={styles.copy}
          onClick={() => copyToClipboard(user.email)}
        >
          <Icons.Copy />
        </div>
      </div>
    </div>
  )
}

export default SingleOrderDataForStaffCustomer
