import { useQuery } from 'react-query'

import { api } from 'src/api'

const useGetAccount = () => {
  return useQuery(
    [`getAccount`],
    async () => await api.get(`/accounts/profile/`),
  )
}

export default useGetAccount
