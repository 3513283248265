import { BasicTemplate } from 'src/templates'

import { ChatContextProvider } from './chatContext'

import ChatHeader from './ChatHeader'
import ChatMessages from './ChatMessages'
import ChatFooter from './ChatFooter'
import ChatDev from './ChatDev'
import ChatPhotoGallery from './ChatPhotoGallery'

import styles from './chat-page.module.scss'

import { IBreadcrumb } from 'src/interfaces'

const ChatPage = () => {
  const breadcrumbs = [
    { title: 'Главная' },
    { title: 'Имя съемки' },
    { title: 'Онлайн-чат' },
  ] as IBreadcrumb[] //TODO

  return (
    <ChatContextProvider>
      <BasicTemplate breadcrumbs={breadcrumbs}>
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <ChatHeader />

            <ChatMessages />

            <ChatFooter />
          </div>

          <ChatPhotoGallery />
        </div>
      </BasicTemplate>

      <ChatDev />
    </ChatContextProvider>
  )
}

export default ChatPage
