import { useState, useEffect } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'
import { useFormik } from 'formik'

import { Form, OrderGalleryFixed } from 'src/components'

import SingleOrderTabsHeader from './SingleOrderTabsHeader'
import * as Steps from './SingleOrderSteps'

import { useAppContext } from 'src/context'

import { useGetRetouches, useGetPhotos, useGetInventories } from 'src/hooks'

import { isOrderStatusPassed, getProductsWithPhotos } from 'src/helpers'

import { PATHS } from 'src/config'

import styles from './single-order-tabs.module.scss'

import {
  IOrder,
  IOrderStatus,
  IOrderStatusAction,
  IShoot,
  IRetouch,
  IRetouchStatus,
  IPhoto,
} from 'src/interfaces'

interface SingleOrderTabsProps {
  order: IOrder
  currentShoot: IShoot
  currentAction?: IOrderStatusAction | null
  orderRefetch: () => Promise<any>
}

const SingleOrderTabs = (props: SingleOrderTabsProps) => {
  const { order, currentShoot, currentAction, orderRefetch } = props

  const navigate = useNavigate()

  const { currentUser } = useAppContext()

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const { data: retouches, isSuccess: retouchesIsSuccess } = useGetRetouches()
  const loadedRetouches = (retouches?.data ?? []).filter(
    (retouch: IRetouch) => retouch.order_id === order.id,
  )

  const inventories = useGetInventories()
  const photos = useGetPhotos()

  const formik = useFormik<{ retouches: IRetouch[] }>({
    initialValues: {
      retouches: [
        {
          images: [],
          type: 'basic',
          status: IRetouchStatus.DRAFT,
          framing: '1:1',
          align: 'CENTER',
          background_color: 'GRAY5',
          isInitial: true,
        },
      ],
    },
    onSubmit: () => {}, //TODO add real submitting
  })

  useEffect(() => {
    if (retouchesIsSuccess) {
      if (loadedRetouches.length) {
        formik.setFieldValue('retouches', [
          ...loadedRetouches,
          ...formik.values.retouches,
        ])
        if (!currentUser.is_staff) {
          setCurrentStep(2) //open retouches tab if there are retouches
        }
      } else {
        if (isOrderStatusPassed(IOrderStatus.READY_FOR_RETOUCH, order.status)) {
          if (!currentUser.is_staff) {
            setCurrentStep(1) //open gallery tab if there are no retouches
          }
        }
      }
    }
  }, [retouchesIsSuccess])

  const handleSubmit = async () => {
    setLoading(true)

    formik.setFieldValue(
      //TODO mb remove
      'retouches',
      formik.values.retouches.filter((retouch) => retouch.images.length),
    )

    navigate(
      generatePath(PATHS.CREATE_ORDER_RETOUCH, { id: String(order.id) }),
      {
        state: {
          initialData: formik.values.retouches.filter(
            //TODO mb refactor
            (retouch) => retouch.images.length && retouch.isInitial,
          ),
        },
      },
    )
  }

  const currentPhotos = (photos?.data?.data ?? []).filter(
    (photo: IPhoto) => photo.shoot === currentShoot.id,
  )

  const products = getProductsWithPhotos(
    inventories.data?.data ?? [],
    currentPhotos,
  )

  const freePhotos = currentPhotos.filter(
    (photo: IPhoto) => photo.inventory === null,
  )

  const showGallery = isOrderStatusPassed(
    IOrderStatus.READY_FOR_RETOUCH,
    order.status,
  )

  const StepComponent = (() => {
    const currentItem = Object.values(Steps)[currentStep]

    if (!currentItem) {
      return null
    }

    if (typeof currentItem === 'function') {
      console.log('looool kek')
      return currentItem
    } else {
      return currentItem[currentUser.is_staff ? 'Staff' : 'Customer']
    }
  })()

  if (!currentShoot) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <SingleOrderTabsHeader
        showGallery={showGallery}
        currentStep={currentStep}
        onSelect={setCurrentStep}
      />

      <Form providerValue={formik}>
        {StepComponent ? (
          <StepComponent
            order={order}
            currentShoot={currentShoot}
            currentAction={currentAction}
            photos={photos?.data?.data ?? []}
            products={products}
            freePhotos={freePhotos}
            setCurrentStep={setCurrentStep}
            orderRefetch={orderRefetch}
          />
        ) : null}

        <OrderGalleryFixed
          loading={loading}
          currentShoot={currentShoot}
          isRetouchStep={false}
          products={products}
          freePhotos={freePhotos}
          onSubmit={handleSubmit}
        />
      </Form>
    </div>
  )
}

export default SingleOrderTabs
