import { useAppContext } from 'src/context'

const RoleCustomer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const { currentUser } = useAppContext()

  return <>{!currentUser.is_staff ? children : null}</>
}

export default RoleCustomer
