import { useEffect, useState, useContext, createContext } from 'react'
import dayjs from 'dayjs'

import { appLocalStorage } from 'src/libs'

// import { version } from 'src/../package.json'
import packageInfo from 'src/../package.json'

import { ICurrentUser } from 'src/interfaces'

const { version } = packageInfo

export const AppContext = createContext({
  globalLoading: true,
  setGlobalLoading: (globalLoading: boolean) => void globalLoading as void,
  authorized: false,
  setAuthorized: (authorized: boolean) => void authorized as void,
  currentUser: {} as ICurrentUser,
  setCurrentUser: (currentUser: ICurrentUser) => void currentUser as void,
  appVersion: version,
  setAppVersion: (value: string) => void value as void,
})

//TODO REDUCER
export const AppContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const [authorized, setAuthorized] = useState(false)
  const [currentUser, setCurrentUser] = useState<ICurrentUser>({})
  const [globalLoading, setGlobalLoading] = useState(true)

  const [appVersion, setAppVersion] = useState(version)

  return (
    <AppContext.Provider
      value={{
        globalLoading,
        setGlobalLoading,
        authorized,
        setAuthorized,
        currentUser,
        setCurrentUser: (u: ICurrentUser) => {
          appLocalStorage.currentUser = u
          setCurrentUser(u)
        },
        appVersion,
        setAppVersion,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)
  return context
}
