import { useState } from 'react'

import { Icons } from 'src/components'

import ChatHeaderAddUserItem from './ChatHeaderAddUserItem'

import styles from './chat-header-add-user.module.scss'

const MOCK_USERS = [
  {
    id: 1,
    name: 'Анна',
    role: 'Фотограф',
    color: 'linear-gradient(180deg, #006D77 -9.38%, #004B52 115.62%)',
  },
  {
    id: 2,
    name: 'Гость',
    role: 'Пользователь',
    color: 'linear-gradient(180deg, #11998E 0%, #38EF7D 100%)',
  },
  {
    id: 3,
    name: 'Иван',
    role: 'Ретушер',
    color: 'linear-gradient(180deg, #0575E6 0%, #021B79 100%)',
  },
  {
    id: 4,
    name: 'Мария',
    role: 'Техподдержка',
    color: 'linear-gradient(180deg, #DCE35B 0%, #45B649 100%)',
  },
]

const ChatHeaderAddUser = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false)

  return (
    <>
      <div className={styles.button} onClick={() => setIsOpened(!isOpened)}>
        <Icons.UserAdd />
      </div>

      {isOpened ? (
        <div className={styles.usersDropdown}>
          {MOCK_USERS.map((user) => (
            <ChatHeaderAddUserItem key={user.id} {...user} />
          ))}
        </div>
      ) : null}
    </>
  )
}

export default ChatHeaderAddUser
