import axios from 'axios'

import { API_BASE_PROTOCOL, API_BASE_URL } from 'src/config'

const api = axios.create({
  baseURL: `${API_BASE_PROTOCOL}://${API_BASE_URL}`,
  withCredentials: true,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
})

api.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (error.response?.status === 401) {
      console.error('UNATHORIZED')
      await api
        .post('/accounts/logout/', { revoke_token: true })
        .finally(() => {
          window.location.href = '/'
        })
    }

    return Promise.reject(error)
  },
)

export default api
