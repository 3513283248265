export const API_BASE_PROTOCOL = process.env.REACT_APP_API_PROTOCOL || 'https'
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'studio-app.kak2c.ru'

export const QUERY_CLIENT_DEFAULT_OPTIONS = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
  },
}
