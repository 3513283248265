import styles from './retouch-framing-filter-option.module.scss'

import { IOption } from 'src/interfaces'

const RetouchFramingFilterOption = (
  props: React.PropsWithChildren<{
    className?: string
    data: IOption
    onChange: () => void
  }>,
) => {
  const { data, onChange } = props

  return (
    <div className={styles.wrapper} onClick={onChange}>
      <div>{data.value}</div>

      <div className={styles.label}>{data.label}</div>
    </div>
  )
}

export default RetouchFramingFilterOption
