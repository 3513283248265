import { ComponentClass, FunctionComponent } from 'react'

import {
  AuthPage,
  RegisterPage,
  ForgotPage,
  VerifyPage,
  VerifyForgotPage,
  NeedAuthPage,
  DashboardPage,
  CreateOrderPage,
  SingleOrderPage,
  CreateOrderRetouchPage,
  ChatPage,
} from 'src/pages'

import { PRIVATE_PATHS, PUBLIC_PATHS, COMMON_PATHS } from 'src/config'

export type IRoutes = {
  path?: string
  component: FunctionComponent | ComponentClass
  default?: boolean
  template?: string
}[]

export const privateRoutes: IRoutes = [
  {
    path: PRIVATE_PATHS.DASHBOARD,
    component: DashboardPage,
    default: true,
  },
  {
    path: PRIVATE_PATHS.SINGLE_ORDER,
    component: SingleOrderPage,
  },
  {
    path: PRIVATE_PATHS.CREATE_ORDER_RETOUCH,
    component: CreateOrderRetouchPage,
  },
]

export const publicRoutes: IRoutes = [
  { path: PUBLIC_PATHS.AUTH, component: AuthPage, default: true },
  { path: PUBLIC_PATHS.REGISTER, component: RegisterPage },
  { path: PUBLIC_PATHS.FORGOT, component: ForgotPage },
  { path: PUBLIC_PATHS.VERIFY, component: VerifyPage },
  { path: PUBLIC_PATHS.VERIFY_FORGOT, component: VerifyForgotPage },
  { path: PUBLIC_PATHS.NEED_AUTH, component: NeedAuthPage },
]

export const commonRoutes: IRoutes = [
  { path: COMMON_PATHS.CREATE_ORDER, component: CreateOrderPage },
  { path: COMMON_PATHS.CHAT, component: ChatPage },
  //{ path: PUBLIC_PATHS.NEED_AUTH, component: NeedAuthPage }
]
