import { useState, useEffect, createElement } from 'react'
import cx from 'classnames'

import styles from './title.module.scss'

interface TitleProps {
  type?: string
  className?: string
}

const Title = (props: React.PropsWithChildren<TitleProps>) => {
  const { type = 'title', className, children } = props

  const [elementProps, setElementProps] = useState<{
    tag?: string
    className?: string
  }>({})

  useEffect(() => {
    let currentElementProps = {}

    switch (type) {
      case 'subtitle':
        currentElementProps = {
          tag: 'h2',
        }
        break

      case 'small':
        currentElementProps = {
          tag: 'h3',
        }
        break

      case 'mini':
        currentElementProps = {
          tag: 'h4',
        }
        break

      case 'ghost':
        currentElementProps = {
          tag: 'span',
        }
        break

      default:
        currentElementProps = {
          tag: 'h1',
        }
    }

    setElementProps(currentElementProps)
  }, [type])

  if (!elementProps.tag) {
    return null
  }

  return (
    <>
      {createElement(
        elementProps.tag,
        {
          ...elementProps,
          className: cx(styles[elementProps.tag], styles[type], className),
        },
        children,
      )}
    </>
  )
}

export default Title
