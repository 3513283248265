import { useFormikContext } from 'formik'

import { Button } from 'src/components'

import ButtonProps from '../ButtonProps'

const SubmitButton = (props: React.PropsWithChildren<ButtonProps>) => {
  const { submitForm, isSubmitting } = useFormikContext()

  return (
    <Button
      type="submit"
      onClick={submitForm}
      loading={isSubmitting}
      {...props}
    />
  )
}

export default SubmitButton
