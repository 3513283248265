import { InfoPrompt } from 'src/components'

import styles from './retouch-info-prompt.module.scss'

const RetouchInfoPrompt = () => {
  return (
    <InfoPrompt className={styles.wrapper}>
      <div>
        <div className={styles.title}>В услуги входят:</div>

        <ul className={styles.list}>
          <li>Чистка пыли, царапин, грязи</li>
          <li>Отражения предмета</li>
          <li>Коррекция бликов</li>
          <li>Удаление элементов</li>
          <li>Приведение к единому оттенку кожи</li>
          <li>Ретушь одежды</li>
        </ul>
      </div>
    </InfoPrompt>
  )
}

export default RetouchInfoPrompt
