import { useTranslation } from 'react-i18next'

import { IOrder } from 'src/interfaces'

const OrderTitle = (props: IOrder) => {
  const { id, title } = props

  const { t } = useTranslation()

  return <span>{title || `${t('common.order')} #${id}`}</span>
}

export default OrderTitle
