import { useTranslation } from 'react-i18next'

import { Modal, Illustrations, Title, InfoComment } from 'src/components'

import styles from './modal-retouch-info.module.scss'

interface ModalRetouchInfoProps {
  onClose: () => void
}

const ModalRetouchInfo = (props: ModalRetouchInfoProps) => {
  const { onClose } = props

  const { t } = useTranslation()
  return (
    <Modal
      id="retouchInfoModal"
      template="medium"
      showCloseButton
      noHeader
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Illustrations.Retouch />

          <div className={styles.titleWrapper}>
            <Title>{t('order.whatIsIncludedInRetouching')}</Title>
            <div className={styles.edition}>Редакция от 15.03.2023</div>
          </div>
        </div>

        <InfoComment
          className={styles.infoComment}
          icon="Info"
          color="brand"
          align="top"
        >
          <div className={styles.subtitleS}>
            Что делать если нужные параметры отсутствуют?
          </div>

          <div className={styles.infoCommentInner}>
            Напишите комментарий к заказу на ретушь, какие параметры необходимо
            добавить. Далее, мы свяжемся c вами и обсудим параметры ретуши.
          </div>
        </InfoComment>

        <div className={styles.inner}>
          <div className={styles.innerTitle}>Ретушь</div>

          <ul>
            <li>Цветокоррекция</li>
            <li>Чистка от пыли, царапин или грязи</li>
            <li>Коррекция отражений и бликов</li>
            <li>Удаление паразитных элементов</li>
            <li>Ретушь складок на одежде и обуви</li>
          </ul>

          <div className={styles.innerTitle}>Разрешение и формат фото</div>

          <ul>
            <li>Кадрирование 1:1 – 2100х2100 px, 200 dpi</li>
            <li>Кадрирование 3:4 – 2100х2800 px, 200 dpi</li>
            <li>Кадрирование 4:3 – 2800x2100 px, 200 dpi</li>
          </ul>

          <p>Формат фото: JPEG</p>
        </div>
      </div>
    </Modal>
  )
}

export default ModalRetouchInfo
