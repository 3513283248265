import { IOption } from 'src/interfaces'

interface SelectOptionProps {
  className?: string
  data: IOption
  OptionComponent?: (
    props: React.PropsWithChildren<{
      className?: string
      data: IOption
      onChange: () => void
    }>,
  ) => React.ReactElement
  OptionComponentChild?: (props: IOption) => React.ReactElement
  onChange: (value: string) => void
}

const SelectOption = (props: SelectOptionProps) => {
  const { className, data, OptionComponent, OptionComponentChild, onChange } =
    props

  const handleChange = () => {
    onChange(data.value)
  }

  if (OptionComponent) {
    return (
      <OptionComponent
        className={className}
        data={data}
        onChange={handleChange}
      >
        {OptionComponentChild ? <OptionComponentChild {...data} /> : null}
      </OptionComponent>
    )
  }

  return (
    <div className={className} onClick={handleChange}>
      <span>{data.label}</span>
      {OptionComponentChild ? <OptionComponentChild {...data} /> : null}
    </div>
  )
}

export default SelectOption
