import { useTranslation } from 'react-i18next'

import { getAvatarText } from 'src/helpers'

import styles from './chat-user-avatar.module.scss'

interface ChatUserAvatarProps {
  name: (string | undefined)[]
  color?: string
}

const ChatUserAvatar = (props: ChatUserAvatarProps) => {
  const {
    name,
    color = 'linear-gradient(180deg, #006D77 -9.38%, #004B52 115.62%)',
  } = props

  const { t } = useTranslation()

  const avatarText = getAvatarText(t('common.user'), name)

  return (
    <div className={styles.wrapper} style={{ background: color }}>
      {avatarText}
    </div>
  )
}

export default ChatUserAvatar
