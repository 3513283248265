import { useField } from 'formik'

import { RetouchItem } from 'src/components'

import RetouchStepEmpty from './RetouchStepEmpty'

import styles from './retouch-step.module.scss'

import {
  IRetouch,
  IRetouchStatus,
  IPhoto,
  IShoot,
  IInventoryWithPhotos,
} from 'src/interfaces'

interface RetouchStepProps {
  photos: IPhoto[]
  products: IInventoryWithPhotos[]
  currentShoot: IShoot
  freePhotos: IPhoto[]
  setCurrentStep: (step: number) => void
}

const RetouchStep = (props: RetouchStepProps) => {
  const { photos, products, currentShoot, freePhotos, setCurrentStep } = props

  const [{ value: retouches }] = useField('retouches')

  const actualRetouches = retouches.filter(
    (retouch: IRetouch) => !retouch.isInitial,
  )

  if (!actualRetouches.length) {
    return <RetouchStepEmpty onCreate={() => setCurrentStep(0)} />
  }

  console.log('actualRetouches', actualRetouches)

  return (
    <div className={styles.wrapper}>
      {actualRetouches.map(
        (
          _: any,
          index: number, //TODO ts
        ) => (
          <RetouchItem
            key={index}
            index={index}
            photos={photos}
            products={products}
            freePhotos={freePhotos}
            currentPackage={currentShoot.package}
          />
        ),
      )}
    </div>
  )
}

export default RetouchStep
