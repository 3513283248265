import { useTranslation } from 'react-i18next'

import { AuthForm, Input, SubmitButton } from 'src/components'

import styles from './auth-block.module.scss'

import { ReactComponent as Icon } from './assets/icon.svg'

interface AuthBlockProps {
  onAuth: (data: any) => void
  setCurrentBlock: (block: 'Auth' | 'Register' | 'Forgot') => void
}

const AuthBlock = (props: AuthBlockProps) => {
  const { onAuth, setCurrentBlock } = props

  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Icon />
      </div>

      <div className={styles.title}>{t('needAuth.titleLogin')}</div>

      <AuthForm className={styles.form} onAuth={onAuth}>
        <Input
          className={styles.input}
          name="email"
          type="email"
          placeholder={t('placeholder.yourEmail')}
        />

        <Input
          className={styles.input}
          name="password"
          type="password"
          placeholder={t('placeholder.enterPassword')}
        />

        <SubmitButton className={styles.button}>
          {t('common.comeIn')}
        </SubmitButton>

        <div className={styles.optionWithLink}>
          <div
            className={styles.link}
            onClick={() => setCurrentBlock('Forgot')}
          >
            {t('auth.forgotPasswordLink')}
          </div>
        </div>

        <div className={styles.optionWithLink}>
          <div
            className={styles.link}
            onClick={() => setCurrentBlock('Register')}
          >
            {t('auth.newAccountLink')}
          </div>
        </div>
      </AuthForm>
    </div>
  )
}

export default AuthBlock
