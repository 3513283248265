import FooterBottomBlock from './FooterBottomBlock'

import styles from './footer.module.scss'

const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <FooterBottomBlock />
    </div>
  )
}

export default Footer
