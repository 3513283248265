import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { RetouchStatus, DownloadButton } from 'src/components'

import RetouchNotDraftLeftPanelDetail from './RetouchNotDraftLeftPanelDetail'

import {
  FRAMING_OPTIONS,
  ALIGN_OPTIONS,
  BACKGROUND_COLOR_OPTIONS,
} from 'src/config'

import styles from './retouch-not-draft-left-panel.module.scss'

import { IRetouch, IRetouchStatus } from 'src/interfaces'

const RetouchNotDraftLeftPanel = (props: IRetouch) => {
  const {
    id,
    status,
    created_at,
    framing,
    align,
    background_color,
    custom_color,
    link,
    comment,
  } = props

  const { t, i18n } = useTranslation()

  const colorValue =
    background_color !== 'CUSTOM'
      ? BACKGROUND_COLOR_OPTIONS.find(
          (option) => option.value === background_color,
        )?.label ?? ''
      : custom_color || ''

  return (
    <div className={styles.wrapper}>
      {status ? <RetouchStatus status={status} /> : null}

      <div className={styles.title}>{`${t('common.retouchOrder')} #${id}`}</div>

      <div className={styles.date}>
        {dayjs(created_at).locale(i18n.language).format('DD MMMM hh:mm')}
      </div>

      {status === IRetouchStatus.DONE ? (
        <DownloadButton
          className={styles.downloadButton}
          icon="Download"
          size="small"
          template="secondary"
          disabled={!link}
          link={link}
          fileName={`retouch-${id}.zip`}
        />
      ) : null}

      <div className={styles.detailsWrapper}>
        <RetouchNotDraftLeftPanelDetail
          title={t('label.framing')}
          value={
            FRAMING_OPTIONS.find((option) => option.value === framing)?.label ??
            ''
          }
          icon="Framing"
        />

        <RetouchNotDraftLeftPanelDetail
          title={t('label.alignment')}
          value={
            ALIGN_OPTIONS.find((option) => option.value === align)?.label ?? ''
          }
          icon="Alignment"
        />

        <RetouchNotDraftLeftPanelDetail
          title={t('label.backgroundColor')}
          value={colorValue}
          icon="Color"
        />
      </div>

      {comment ? (
        <div className={styles.commentWrapper}>
          <div className={styles.commentTitle}>
            {t('placeholder.orderComment')}
          </div>

          <div className={styles.comment}>{comment}</div>
        </div>
      ) : null}
    </div>
  )
}

export default RetouchNotDraftLeftPanel
