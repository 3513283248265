import SingleOrderRoadMap from './SingleOrderRoadMap'
import SingleOrderTabs from './SingleOrderTabs'

import { ORDER_STATUSES } from 'src/config'

import { IOrder } from 'src/interfaces'

interface SingleOrderMainBlockProps {
  order: IOrder
  orderRefetch: () => Promise<any>
}

const SingleOrderMainBlock = (props: SingleOrderMainBlockProps) => {
  const { order, orderRefetch } = props
  const { status } = order

  const currentShoot = order?.shoots?.[0]

  if (!currentShoot) {
    return null
  }

  const currentStatusIndex = Object.keys(ORDER_STATUSES).findIndex(
    (key) => key === status,
  )

  const currentStepIndex = (() => {
    const currentStatus = Object.values(ORDER_STATUSES)[currentStatusIndex]

    if (currentStatus.inRoadmap) {
      return currentStatusIndex
    } else {
      const actualStepStatusIndex = Object.keys(ORDER_STATUSES).findIndex(
        (key) => key === currentStatus.roadmapFallback,
      )

      return actualStepStatusIndex
    }
  })()

  const steps = Object.entries(ORDER_STATUSES).map(([key, value], i) => ({
    ...value,
    ...(() => {
      if (!status) {
        return {
          template: 'hidden',
        }
      }

      if (!value.inRoadmap) {
        return { template: 'hidden' }
      }

      if (i < currentStepIndex) {
        return { template: 'done' }
      }

      if (i === currentStepIndex) {
        return {
          template: 'active',
          title: Object.values(ORDER_STATUSES)[currentStatusIndex].title,
        }
      }

      return { template: 'next' }
    })(),
  }))

  const currentAction =
    currentStepIndex > -1 ? steps[currentStepIndex]?.action : null

  return (
    <>
      <SingleOrderRoadMap steps={steps} />

      <SingleOrderTabs
        order={order}
        currentShoot={currentShoot}
        currentAction={currentAction}
        orderRefetch={orderRefetch}
      />
    </>
  )
}

export default SingleOrderMainBlock
