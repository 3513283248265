import { useState, useEffect } from 'react'

import { useChatContext } from 'src/pages/ChatPage/chatContext'

import ChatDevModal from './ChatDevModal'

import { IChatMessage } from 'src/interfaces'

const ChatDev = () => {
  const { messages, setMessages } = useChatContext()

  const [keysPressed, setKeysPressed] = useState<{
    [key: string]: boolean | undefined
  }>({})
  const [showDev, setShowDev] = useState<boolean>(false)

  const handleKeyDown = (event: KeyboardEvent) => {
    setKeysPressed((prevState) => ({ ...prevState, [event.key]: true }))
  }

  const handleKeyUp = (event: KeyboardEvent) => {
    setKeysPressed((prevState) => ({ ...prevState, [event.key]: false }))
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  useEffect(() => {
    if (keysPressed['d'] && keysPressed['e'] && keysPressed['v']) {
      setShowDev(true)
      setKeysPressed({})
    }
  }, [keysPressed])

  const handleMessageSend = (newMessage: IChatMessage) => {
    setMessages([...messages, newMessage])
  }

  if (!showDev) {
    return null
  }

  return (
    <ChatDevModal
      onMessageSend={handleMessageSend}
      onClose={() => setShowDev(false)}
    />
  )
}

export default ChatDev
