import { useState } from 'react'
import { useField } from 'formik'
import cx from 'classnames'

import { RetouchGalleryItem, ModalImage } from 'src/components'

import PhotoStepProductBadges from './PhotoStepProductBadges'

import styles from './photo-step-product-gallery.module.scss'

import { IPhoto, IRetouch, IRetouchStatus, IPackage } from 'src/interfaces'

interface PhotoStepProductGalleryProps {
  className?: string
  photos: IPhoto[]
  currentPackage: IPackage | null
  discountIsAvailable: boolean
}

const PhotoStepProductGallery = (props: PhotoStepProductGalleryProps) => {
  const { className, photos, currentPackage, discountIsAvailable } = props

  const [fullSizeModalCurrentSrc, setFullSizeModalCurrentSrc] = useState<
    string | undefined
  >(undefined)

  const [{ value: retouches }, meta, { setValue }] = useField('retouches')

  const handleChangeItem = (photo: IPhoto) => {
    const newValue = retouches.map((retouch: IRetouch) => {
      if (
        retouch.status === IRetouchStatus.DRAFT &&
        retouch.type === 'basic' //TODO refactor
      ) {
        const isPhotoChecked = retouch.images.find(
          (image: { id: number }) => image.id === photo.id,
        )
        return {
          ...retouch,
          images: isPhotoChecked
            ? retouch.images.filter(
                (image: { id: number }) => image.id !== photo.id,
              )
            : [...retouch.images, { id: photo.id }],
        }
      }

      return retouch
    })

    setValue(newValue)
  }

  const handleNavigateModal = (direction: string) => {
    const currentPhotoIndex = photos.findIndex(
      (photo: IPhoto) => photo.raw_watermarked_url === fullSizeModalCurrentSrc,
    )

    if (direction === 'next') {
      const nextPhotoIndex = currentPhotoIndex + 1
      const nextPhoto = photos[nextPhotoIndex]

      setFullSizeModalCurrentSrc((nextPhoto || photos[0]).raw_watermarked_url)
    } else if (direction === 'prev') {
      const prevPhotoIndex = currentPhotoIndex - 1
      const prevPhoto = photos[prevPhotoIndex]

      setFullSizeModalCurrentSrc(
        (prevPhoto || photos[photos.length - 1]).raw_watermarked_url,
      )
    }
  }

  const basicRetouchPhotos =
    retouches.find(
      (retouch: IRetouch) =>
        retouch.type === 'basic' && retouch.status === IRetouchStatus.DRAFT,
    )?.images ?? []

  return (
    <>
      <div className={cx(styles.wrapper, className)}>
        {photos.map((photo: IPhoto) => (
          <RetouchGalleryItem
            key={photo.id}
            selectable
            value={Boolean(
              basicRetouchPhotos.find(
                (image: { id: number }) => image.id === photo.id,
              ),
            )}
            onChange={() => handleChangeItem(photo)}
            onClick={setFullSizeModalCurrentSrc}
            {...photo}
          >
            <PhotoStepProductBadges
              currentPackage={currentPackage}
              photo={photo}
              discountIsAvailable={discountIsAvailable}
            />
          </RetouchGalleryItem>
        ))}
      </div>

      {fullSizeModalCurrentSrc ? (
        <ModalImage
          src={fullSizeModalCurrentSrc}
          onNavigate={handleNavigateModal}
          onClose={() => setFullSizeModalCurrentSrc(undefined)}
        />
      ) : null}
    </>
  )
}

export default PhotoStepProductGallery
