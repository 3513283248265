import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BasicPageHeader } from 'src/components'

import ModalRetouchInfo from './ModalRetouchInfo'

import styles from './header-block.module.scss'
import iconImg from './assets/icon.svg'

const HeaderBlock = () => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <BasicPageHeader
        className={styles.wrapper}
        title={t('order.chooseRetouchParams')}
        subtitle={t('order.creatingOrder')}
      >
        <div className={styles.banner} onClick={() => setShowModal(true)}>
          <img src={iconImg} alt="icon" />

          <div>{t('order.whatIsIncludedInRetouching')}</div>
        </div>
      </BasicPageHeader>

      {showModal ? (
        <ModalRetouchInfo onClose={() => setShowModal(false)} />
      ) : null}
    </>
  )
}

export default HeaderBlock
