import { useRoleQuery } from 'src/hooks'

const useGetPhotos = () => {
  return useRoleQuery({
    queryKeys: [`getPhotos`],
    url: '/api/v1/photos/',
  })
}

export default useGetPhotos
