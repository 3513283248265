import { useMutation } from 'react-query'
import { api } from 'src/api'

interface IUpdateOrderParams {
  id: number
}

interface IUpdateOrderData {}

const useUpdateOrder = (params: IUpdateOrderParams) => {
  const { id } = params

  return useMutation(async (data: IUpdateOrderData) =>
    api.put(`/api/v1/orders/${id}/`, data),
  )
}

export default useUpdateOrder
