import { Link, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { Role, OrderStatus, OrderTitle, Icons } from 'src/components'

import { PATHS } from 'src/config'

import styles from './dashboard-table-item.module.scss'

import { IOrder } from 'src/interfaces'

const DashboardTableItem = (props: IOrder) => {
  const { id, created_at, status, shoots, planned_shooting_date, user } = props

  const { t, i18n } = useTranslation()

  const currentPackage = shoots?.[0]?.package?.title

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Icons.PhotoCamera />
      </div>

      <div className={styles.info}>
        <div className={styles.date}>
          {dayjs(created_at).locale(i18n.language).format('DD MMMM hh:mm')}
        </div>

        <Link
          className={styles.title}
          to={generatePath(PATHS.SINGLE_ORDER, { id: String(id) })}
        >
          <OrderTitle {...props} />
        </Link>

        <div className={styles.package}>{currentPackage || ''}</div>
      </div>

      {/* <div className={styles.products}>
        <div className={styles.columnTitle}></div>
      </div> */}

      <div className={styles.shoot}>
        <div className={styles.columnTitle}>{t('common.shootingDate')}</div>
        <div className={styles.shootDate}>
          {planned_shooting_date
            ? dayjs(planned_shooting_date)
                .locale(i18n.language)
                .format('DD MMMM hh:mm')
            : '-'}
        </div>
      </div>

      <Role.Staff>
        <div>hello!</div>
      </Role.Staff>

      <Role.Staff>
        {user ? ( //TODO refactor
          <div className={styles.user}>
            <div className={styles.name}>{`${user.first_name || 'Неизвестно'} ${
              user.last_name || 'Неизвестно'
            }`}</div>

            <div className={styles.email}>{user.email}</div>
          </div>
        ) : null}
      </Role.Staff>

      <OrderStatus className={styles.status} status={status} />
    </div>
  )
}

export default DashboardTableItem
