import { useTranslation } from 'react-i18next'
import { useField } from 'formik'

import { SubmitButton } from 'src/components'

import { calculateOrderTotal } from 'src/helpers'

import styles from './result-block.module.scss'

import {
  IShoot,
  IRetouch,
  IRetouchStatus,
  IInventoryWithPhotos,
  IPhoto,
} from 'src/interfaces'

interface IResultBlockProps {
  currentShoot: IShoot
  products: IInventoryWithPhotos[]
  freePhotos: IPhoto[]
}

const ResultBlock = (props: IResultBlockProps) => {
  const { currentShoot, products, freePhotos } = props

  const { t } = useTranslation()

  const [{ value: retouches }] = useField('retouches')

  const checkedPhotos =
    retouches.find(
      (retouch: IRetouch) =>
        retouch.type === 'basic' && retouch.status === IRetouchStatus.DRAFT,
    )?.images ?? []

  if (!checkedPhotos.length) {
    return null
  }

  const { basicTotalWithoutDiscount, basicTotalWithDiscount, extraTotal } =
    calculateOrderTotal({
      checkedPhotos,
      currentPackage: currentShoot.package,
      products,
      freePhotos,
    })

  return (
    <div className={styles.wrapper}>
      <SubmitButton>{t('order.orderCheckout')}</SubmitButton>
      <div className={styles.totalWrapper}>
        <div className={styles.count}>
          {t('order.photosPerOrder', { photosCount: checkedPhotos.length })}
        </div>

        {/* <div className={styles.total}>{`${t('order.orderCost')} ${
          basicTotalWithDiscount + extraTotal
        }₽`}</div> */}
      </div>
    </div>
  )
}

export default ResultBlock
