import SingleOrderDataForStaff from './SingleOrderDataForStaff'

import { IOrder, IShoot } from 'src/interfaces'

interface SingleOrderDetailsStepStaffProps {
  order: IOrder
  currentShoot: IShoot
}

const SingleOrderDetailsStepStaff = (
  props: SingleOrderDetailsStepStaffProps,
) => {
  const { order, currentShoot } = props

  return (
    <div>
      <SingleOrderDataForStaff order={order} currentShoot={currentShoot} />
    </div>
  )
}

export default SingleOrderDetailsStepStaff
