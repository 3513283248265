import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { BasicTemplate } from 'src/templates'

import { OrderTitle } from 'src/components'

import SingleOrderHeader from './SingleOrderHeader'
import SingleOrderMainBlock from './SingleOrderMainBlock'

import { useGetOrder } from 'src/hooks'

const SingleOrderPage = () => {
  const { id: orderId = '' } = useParams()

  const { t } = useTranslation()

  const { data, refetch: orderRefetch } = useGetOrder({ orderId })
  const order = data?.data ?? null

  if (!order) {
    return null
  }

  const breadcrumbs = [
    {
      title: t('breadcrumb.home'),
      to: '/',
    },
    { title: <OrderTitle {...order} /> },
  ]

  return (
    <BasicTemplate breadcrumbs={breadcrumbs}>
      <SingleOrderHeader {...order} />

      <SingleOrderMainBlock order={order} orderRefetch={orderRefetch} />
    </BasicTemplate>
  )
}

export default SingleOrderPage
