import { useRoleQuery } from 'src/hooks'

interface UseGetInventoriesParams {
  enabled: boolean
}

const useGetInventories = (
  params: UseGetInventoriesParams = { enabled: true },
) => {
  const { enabled } = params

  return useRoleQuery({
    queryKeys: [`getInventories`],
    url: '/api/v1/inventories/',
    options: {
      enabled,
    },
  })
}

export default useGetInventories
