import { useQuery } from 'react-query'

import { api } from 'src/api'

const useGetPackages = () => {
  return useQuery([`getPackages`], async () => {
    const result = await api.get(`/api/v1/packages/`)
    if (result?.status === 200) {
      return result.data
    }

    return []
  })
}

export default useGetPackages
