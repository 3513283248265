export const PRIVATE_PATHS = {
  DASHBOARD: '/dashboard',
  SINGLE_ORDER: '/orders/:id',
  CREATE_ORDER_RETOUCH: '/orders/:id/retouch',
}

export const PUBLIC_PATHS = {
  AUTH: '/auth',
  REGISTER: '/register',
  FORGOT: '/forgot',
  VERIFY: '/verify-user',
  VERIFY_FORGOT: '/verify-reset-password',
  NEED_AUTH: '/need-auth',
}

export const COMMON_PATHS = {
  CREATE_ORDER: '/orders/create',
  CHAT: '/chat',
}

export const PATHS = {
  ...PRIVATE_PATHS,
  ...PUBLIC_PATHS,
  ...COMMON_PATHS,
}
