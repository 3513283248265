import {
  IStock,
  IInventory,
  IInventoryWithPhotos,
  IInventoryGroup,
  ICompositionTableType,
  IPhoto,
} from 'src/interfaces'

export const getProductCountFromStocks = (stocks: IStock[]) => {
  return stocks.reduce((a, c) => a + c.quantity, 0)
}

export const getProductsUsingProductIds = (
  productIds: number[],
  inventoriesData: IInventory[],
) => {
  return productIds
    .map((productId: number) =>
      inventoriesData?.find((pi: IInventory) => pi.id === productId),
    )
    .filter((pi?: IInventory) => pi) as IInventory[]
}

// export const getProductsWithPhotos = (
//   inventoriesData: IInventory[],
//   photos: IPhoto[],
// ) => {
//   return getProductsUsingProductIds(productIds, inventoriesData).map(
//     (product) => ({
//       ...product,
//       photos: photos.filter((photo) => photo.inventory === product.id),
//     }),
//   )
// }

export const getProductsWithPhotos = (
  inventoriesData: IInventory[],
  photos: IPhoto[],
) =>
  photos.reduce((a, c) => {
    const currentPhotoInvetoryId = c.inventory

    if (currentPhotoInvetoryId !== null) {
      const currentInventory = (inventoriesData || []).find(
        (i) => i.id === currentPhotoInvetoryId,
      )

      if (currentInventory) {
        const currentInventoryIndex = a.findIndex(
          (i) => i.id === currentPhotoInvetoryId,
        )

        if (currentInventoryIndex === -1) {
          a.push({
            ...currentInventory,
            photos: [c],
          })
        } else {
          a[currentInventoryIndex].photos.push(c)
        }
      }
    }

    return a
  }, [] as IInventoryWithPhotos[])

export const getCompositionsUsingCompositionIds = (
  compositionIds: number[],
  inventoryGroupsData: IInventoryGroup[] = [],
  inventoriesData: IInventory[],
) => {
  return compositionIds
    .map((compositionId: number) => {
      const currentComposition = inventoryGroupsData.find(
        (c: IInventoryGroup) => c.id === compositionId,
      )
      if (currentComposition) {
        return {
          id: currentComposition.id,
          name: currentComposition.name,
          inventoriesIdsArray: currentComposition.inventories,
          inventories: getProductsUsingProductIds(
            [...new Set(currentComposition?.inventories)] as number[],
            inventoriesData,
          ),
        }
      }
    })
    .filter((c: ICompositionTableType) => c)
}
