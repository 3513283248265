import cx from 'classnames'

import LangSelectOption from './LangSelectOption'

import { LANGS } from 'src/config'

import styles from './lang-select.module.scss'

interface LangSelectProps {
  className?: string
}

const LangSelect = (props: LangSelectProps) => {
  const { className } = props

  return (
    <div className={cx(styles.wrapper, className)}>
      {LANGS.map((lang) => (
        <LangSelectOption key={lang.id} {...lang} />
      ))}
    </div>
  )
}

export default LangSelect
