import { useField } from 'formik'
import cx from 'classnames'

import RetouchColorFilterPicker from './RetouchColorFilterPicker'

import styles from './retouch-color-filter-preview.module.scss'

import exampleImg from './assets/example.png'

import { IOption } from 'src/interfaces'

const RetouchColorFilterPreview = (props: IOption) => {
  const { meta, value } = props

  const [{ value: customColor }, , { setValue: setCustomColor }] = useField(
    `${meta?.fieldName}.custom_color`,
  )
  const [{ value: backgroundColor }, , { setValue: setBackgroundColor }] =
    useField(`${meta?.fieldName}.background_color`)

  const handleChangeCustomColor = (color: string) => {
    if (backgroundColor !== 'CUSTOM') {
      setBackgroundColor('CUSTOM')
    }

    setCustomColor(color)
  }

  const isCustom = value === 'CUSTOM'

  return (
    <>
      <div className={styles.hex}>{isCustom ? customColor : meta?.color}</div>

      {isCustom ? (
        <RetouchColorFilterPicker
          color={customColor}
          setColor={handleChangeCustomColor}
        />
      ) : null}

      <div className={cx(styles.wrapper, isCustom && styles.withCustom)}>
        <div
          className={styles.inner}
          style={{ backgroundColor: isCustom ? customColor : meta?.color }}
        >
          <img src={exampleImg} alt="" />
        </div>
      </div>
    </>
  )
}

export default RetouchColorFilterPreview
