import { useMutation } from 'react-query'
import { api } from 'src/api'

interface IRegisterData {
  username: string
  password?: string
  password_confirm?: string
  email: string
}

const useRegister = () => {
  return useMutation(
    async (data: IRegisterData) => api.post('/accounts/register/', data),
    {
      onSuccess: (result) => {
        if (result) {
        }
      },
    },
  )
}

export default useRegister
