import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import ProductItem from './ProductItem'

import styles from './result-table.module.scss'

import { IInventory, IProductItem } from 'src/interfaces'

interface ResultBlockProps {
  productsList: IInventory[]
  value: IProductItem[]
  setValue: (value: IProductItem[]) => void
}

const ResultTable = (props: ResultBlockProps) => {
  const { productsList, value = [], setValue } = props

  const { t } = useTranslation()

  const handleChangeProduct = (count: number, index: number) => {
    const newValue = value.slice()
    newValue[index].count = count

    setValue([...newValue])
  }

  const handleRemoveProduct = (index: number) => {
    const newValue = value.slice()
    newValue.splice(index, 1)

    setValue([...newValue])
  }

  return (
    <div>
      <div className={styles.uppercaseXSRegular}>Состав посылки</div>

      {value.length ? (
        <div>
          {value.map((s: IProductItem, i: number) => (
            <ProductItem
              key={s.id}
              data={productsList.find((pl) => pl.id === s.id)}
              value={s}
              onChange={(value: number) => handleChangeProduct(value, i)}
              onRemove={() => handleRemoveProduct(i)}
            />
          ))}
        </div>
      ) : (
        <div className={styles.emptyResult}>{t('supply.emptyItemsModal')}</div>
      )}
    </div>
  )
}

export default ResultTable
